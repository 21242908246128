<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/feedmills.png"
          alt="FeedMills"
          width="100%"
          height="100%"
        />
      </div>
      <div class="q-pt-md">
        <q-toggle label="Display Archived" v-model="displayArchived"></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Name</div>
      <div class="col q-pl-sm">Order</div>
      <div
        class="col-auto"
        :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
      ></div>
    </div>

    <q-separator color="black" />

    <div v-for="mill in FeedMills" :key="mill.id">
      <div class="row items-center">
        <div class="col q-pl-sm">{{ mill.name }}</div>
        <div class="col q-pl-sm">
          {{ mill.order }}
        </div>
        <div class="col-auto q-pa-sm">
          <q-btn
            unelevated
            icon="edit"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            color="grey-3"
            text-color="primary"
            class="q-mr-sm"
            @click="editFeedMill(mill)"
          >
          </q-btn>
          <q-btn
            v-if="!mill.archived && mill.transactions > 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="archive"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveFeedMill(mill, true)"
          />
          <q-btn
            v-if="mill.archived"
            unelevated
            icon="unarchive"
            size="md"
            color="grey-3"
            text-color="secondary"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveFeedMill(mill, false)"
          />
          <q-btn
            v-if="!mill.archived && mill.transactions === 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="delete"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="deleteFeedMill(mill)"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <!-- Dialog Enter Feed Mill -->
    <q-dialog v-model="dialogFeedMillEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Feed Mill</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="feedMillObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />
          <q-input
            outlined
            v-model="feedMillObj.order"
            label="Order"
            type="number"
            min="0"
            step="1"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Order cannot be blank']"
          />

          <div class="row justify-end">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newFeedMill = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    grain_id: null,
    name: '',
    order: 0,
    archived: false,
    transactions: 0,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-FeedMills',
  data() {
    return {
      dialogFeedMillEntry: false,
      displayArchived: false,
      feedMillObj: newFeedMill()
    };
  },
  methods: {
    add() {
      this.feedMillObj = newFeedMill();

      this.dialogFeedMillEntry = true;
    },
    archiveFeedMill(feedMill, value) {
      const feedMillCopy = cloneObj(feedMill);
      feedMillCopy.archived = value;

      feedMillCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive Feed Mill',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this feed mill?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archiveFeedMillConfirmed(feedMillCopy);
        });
    },
    archiveFeedMillConfirmed(feedMill) {
      store
        .dispatch('updateFeedMill', {
          mill: feedMill
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    deleteFeedMill(feedMill) {
      feedMill.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete Feed Mill',
          message: `Are you sure you want to delete this feed mill?`,
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteFeedMillConfirmed(feedMill);
        });
    },
    deleteFeedMillConfirmed(feedMill) {
      store
        .dispatch('deleteFeedMill', {
          mill: feedMill
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editFeedMill(feedMill) {
      this.feedMillObj = cloneObj(feedMill);
      this.feedMillObj.name = decoder(this.feedMillObj.name);
      this.dialogFeedMillEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.feedMillObj.id === 0) {
        dispatch = 'createFeedMill';
      } else {
        dispatch = 'updateFeedMill';
      }

      this.feedMillObj.name = upperCaseFirst(this.feedMillObj.name);

      this.feedMillObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          mill: this.feedMillObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogFeedMillEntry = false;
    }
  },
  computed: {
    FeedMills() {
      let feedMills = cloneObj(this.$store.state.farm.mills);
      if (!this.displayArchived) {
        feedMills = feedMills.filter((element) => !element.archived);
      }

      return feedMills.sort(function (a, b) {
        return a.order - b.order;
      });
    }
  }
};
</script>

<style scoped></style>
