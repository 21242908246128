import date from 'quasar/src/utils/date.js';;
import uid from 'quasar/src/utils/uid.js';;
import store from '@/store';

const decoder = (str) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = str;
  return textArea.value;
};

const parseDateTime = (dateTime) => {
  if (!dateTime) {
    return '- - -';
  }
  return new Date(dateTime * 1000).toLocaleString('en-US');
};

const userName = (id) => {
  if (id === null) {
    return '---';
  }
  const user = store.state.farm.users.find((x) => x.user_id === id);
  return user ? user.display_name : '---';
};

const numberWithCommas = (x) => {
  if (x === 0) {
    return '0';
  }

  if (!x) {
    return '';
  }

  let s = x.toString();
  let parts = s.split('.');

  if (parts.length > 1 && parts[1].length > 2) {
    s = x.toFixed(2);
    parts = s.split('.');
  }

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

const cloneObj = (obj) => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  return JSON.parse(JSON.stringify(obj));
};

const smartCompare = (a, b) => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
};

const formatDollars = (val) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(val);
};

const isNumValid = (val) => {
  if (!val) {
    return false;
  }

  return +val !== NaN;
};

const getPercentage = (val, total) => {
  const percent = (val / total) * 100;
  return percent > 0 ? percent : 0;
};

const getScrollbarWidth = () => {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);
  return scrollbarWidth;
};

const upperCaseFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export {
  decoder,
  parseDateTime,
  userName,
  numberWithCommas,
  cloneObj,
  smartCompare,
  formatDollars,
  isNumValid,
  getPercentage,
  getScrollbarWidth,
  upperCaseFirst
};
