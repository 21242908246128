import UserRoles from '@/lib/UserRoles';
import FarmRoles from '@/lib/FarmRoles';

export default class AuthUtils {
  /**
   *
   * @param {string} target
   * @param {string} user
   * @returns {boolean}
   */
  static validateAuthLevel(target, user) {
    const authLevels = [
      'farm-visitor',
      'farm-worker',
      'acct-manager',
      'acct-owner',
      'installer',
      'super-admin'
    ];
    let userLevel = 0;
    let targetLevel = 0;
    for (let i = 0; i < authLevels.length; i++) {
      if (authLevels[i] === target) {
        targetLevel = i;
      }
      if (authLevels[i] === user) {
        userLevel = i;
      }
    }
    return userLevel >= targetLevel;
  }

  static userAccess(feedx_level, targetLevel) {
    const mapLevel = function (level) {
      return UserRoles.MAP.findIndex(function (element) {
        return level === element;
      });
    };
    return mapLevel(feedx_level) >= mapLevel(targetLevel);
  }

  static farmAccess(farmRole, targetLevel) {
    const mapLevel = function (level) {
      return FarmRoles.MAP.findIndex(function (element) {
        return level === element;
      });
    };
    return mapLevel(farmRole) >= mapLevel(targetLevel);
  }

  static roleDecoder(role) {
    switch (role) {
      case 'farm-visitor':
        return 'Farm Visitor';
      case 'farm-worker':
        return 'Farm Worker';
      case 'acct-manager':
        return 'Account manager';
      case 'acct-owner':
        return 'Account owner';
      case 'installer':
        return 'Installer';
      case 'super-admin':
        return 'Super admin';
      default:
        return 'Unknown';
    }
  }
}
