<template>
  <div>
    <div class="row justify-end q-pa-sm">
      <q-btn
        v-if="DisplayAdd"
        color="accent"
        icon="add"
        class="q-mr-sm"
        @click="add"
      />
      <q-btn
        v-if="DisplayEdit"
        color="accent"
        icon="edit"
        class="q-mr-sm"
        @click="edit"
      />

      <q-btn color="primary" icon="more_vert">
        <q-menu :offset="[0, 8]" auto-close>
          <q-list separator style="width: 160px">
            <q-item clickable dense v-ripple @click="currentDisplay = 'bins'">
              <q-item-section>Bins</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'feeders'"
            >
              <q-item-section>Feeders</q-item-section>
            </q-item>
            <q-item clickable dense v-ripple @click="currentDisplay = 'ponds'">
              <q-item-section>Ponds</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'flavors'"
            >
              <q-item-section>Flavors</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'treatments'"
            >
              <q-item-section>Treatments</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'feedMills'"
            >
              <q-item-section>Feed Mills</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'feedTypes'"
            >
              <q-item-section>Feed Types</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'processors'"
            >
              <q-item-section>Processors</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'hatcheries'"
            >
              <q-item-section>Hatcheries</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'conversionRates'"
            >
              <q-item-section>Conversion Rates</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'targetFeed'"
            >
              <q-item-section>Target Feed</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'feedDays'"
            >
              <q-item-section>Feeding Days</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'harvestSize'"
            >
              <q-item-section>Harvest Size</q-item-section>
            </q-item>
            <q-item clickable dense v-ripple @click="currentDisplay = 'users'">
              <q-item-section>Users</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'purchasers'"
            >
              <q-item-section>Purchasers</q-item-section>
            </q-item>
            <q-item
              clickable
              dense
              v-ripple
              @click="currentDisplay = 'locations'"
            >
              <q-item-section>Locations</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>
    <q-separator />

    <div :style="`height: ${store.state.pageHeight - 103}px`" class="scroll">
      <Bins v-if="currentDisplay === 'bins'" ref="bins" />
      <Feeders v-if="currentDisplay === 'feeders'" ref="feeders" />
      <Ponds v-if="currentDisplay === 'ponds'" ref="ponds" />
      <Flavors v-if="currentDisplay === 'flavors'" ref="flavors" />
      <Treatments v-if="currentDisplay === 'treatments'" ref="treatments" />
      <FeedMills v-if="currentDisplay === 'feedMills'" ref="feedMills" />
      <FeedTypes v-if="currentDisplay === 'feedTypes'" ref="feedTypes" />
      <Processors v-if="currentDisplay === 'processors'" ref="processors" />
      <Hatcheries v-if="currentDisplay === 'hatcheries'" ref="hatcheries" />
      <ConversionRates
        v-if="currentDisplay === 'conversionRates'"
        ref="conversionRates"
      />
      <TargetFeed v-if="currentDisplay === 'targetFeed'" ref="targetFeed" />
      <FeedDays v-if="currentDisplay === 'feedDays'" ref="feedDays" />
      <HarvestSize v-if="currentDisplay === 'harvestSize'" ref="harvestSize" />
      <Users v-if="currentDisplay === 'users'" ref="users" />
      <Purchasers v-if="currentDisplay === 'purchasers'" ref="purchasers" />
      <Locations v-if="currentDisplay === 'locations'" ref="locations" />
    </div>
  </div>
</template>

<script>
import Bins from '@/views/settings/bins.vue';
import Feeders from '@/views/settings/feeders.vue';
import Ponds from '@/views/settings/ponds.vue';
import Flavors from '@/views/settings/flavors.vue';
import Treatments from '@/views/settings/treatments.vue';
import FeedMills from '@/views/settings/feed-mills.vue';
import FeedTypes from '@/views/settings/feed-types.vue';
import Processors from '@/views/settings/processors.vue';
import Hatcheries from '@/views/settings/hatcheries.vue';
import ConversionRates from '@/views/settings/conversion-rates.vue';
import TargetFeed from '@/views/settings/target-feed.vue';
import FeedDays from '@/views/settings/feeding-days.vue';
import HarvestSize from '@/views/settings/harvest-size.vue';
import Users from '@/views/settings/users.vue';
import Purchasers from '@/views/settings/purchasers.vue';
import Locations from '@/views/settings/locations.vue';

import FarmRoles from '@/lib/FarmRoles';
import AuthUtils from '@/lib/auth-utils';
import store from '@/store';

export default {
  name: 'settings-root',
  components: {
    Bins,
    Feeders,
    Ponds,
    FeedMills,
    FeedTypes,
    Processors,
    Hatcheries,
    ConversionRates,
    TargetFeed,
    FeedDays,
    HarvestSize,
    Users,
    Purchasers,
    Locations,
    Treatments,
    Flavors,
    Treatments
  },
  data() {
    return {
      currentDisplay: 'bins',
      store
    };
  },
  props: {},
  mounted() {
    this.validateAccess();
  },
  methods: {
    add() {
      this.$refs[this.currentDisplay].add();
    },
    edit() {
      this.$refs[this.currentDisplay].edit();
    },
    validateAccess() {
      if (this.$store.getters.userFarmRole === 'none') {
        return;
      }
      if (
        !AuthUtils.farmAccess(
          this.$store.getters.userFarmRole,
          FarmRoles.ACCT_MANAGER
        )
      ) {
        this.$router.push({ name: 'Feed' });
      }
    }
  },
  computed: {
    DisplayAdd() {
      return (
        this.currentDisplay !== 'feedDays' &&
        this.currentDisplay !== 'harvestSize' &&
        this.currentDisplay !== 'users' &&
        this.currentDisplay !== 'locations'
      );
    },
    DisplayEdit() {
      return (
        !this.DisplayAdd &&
        this.currentDisplay !== 'users' &&
        this.currentDisplay !== 'locations'
      );
    }
  },
  watch: {
    '$store.getters.userFarmRole': function () {
      this.validateAccess();
    }
  }
};
</script>

<style scoped></style>
