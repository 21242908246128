<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/hatcheries.png"
          alt="hatcheries"
          width="100%"
          height="100%"
        />
      </div>
      <div class="q-pt-md">
        <q-toggle label="Display Archived" v-model="displayArchived"></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Name</div>
      <div
        class="col-auto"
        :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
      ></div>
    </div>

    <q-separator color="black" />

    <div v-for="hatchery in Hatcheries" :key="hatchery.id">
      <div class="row items-center">
        <div class="col q-pl-sm">{{ decoder(hatchery.name) }}</div>
        <div class="col-auto q-pa-sm">
          <q-btn
            unelevated
            icon="edit"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            color="grey-3"
            text-color="primary"
            class="q-mr-sm"
            @click="editHatchery(hatchery)"
          >
          </q-btn>
          <q-btn
            v-if="!hatchery.archived && hatchery.transactions > 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="archive"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveHatchery(hatchery, true)"
          />
          <q-btn
            v-if="hatchery.archived"
            unelevated
            icon="unarchive"
            size="md"
            color="grey-3"
            text-color="secondary"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveHatchery(hatchery, false)"
          />
          <q-btn
            v-if="!hatchery.archived && hatchery.transactions === 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="delete"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="deleteHatchery(hatchery)"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <!-- Dialog Enter hatchery -->
    <q-dialog v-model="dialogHatcheryEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Hatchery</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="hatcheryObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />

          <div class="row justify-end">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newHatchery = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    fish_id: null,
    name: '',
    archived: false,
    transactions: 0,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-Hatcheries',
  data() {
    return {
      decoder,
      dialogHatcheryEntry: false,
      displayArchived: false,
      hatcheryObj: newHatchery()
    };
  },
  methods: {
    add() {
      this.hatcheryObj = newHatchery();

      this.dialogHatcheryEntry = true;
    },
    archiveHatchery(hatchery, value) {
      const hatcheryCopy = cloneObj(hatchery);
      hatcheryCopy.archived = value;

      hatcheryCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive hatchery',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this hatchery?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archiveHatcheryConfirmed(hatcheryCopy);
        });
    },
    archiveHatcheryConfirmed(hatchery) {
      store
        .dispatch('updateHatchery', {
          hatchery
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    deleteHatchery(hatchery) {
      hatchery.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete hatchery',
          message: `Are you sure you want to delete this hatchery?`,
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteHatcheryConfirmed(hatchery);
        });
    },
    deleteHatcheryConfirmed(hatchery) {
      store
        .dispatch('deleteHatchery', {
          hatchery
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editHatchery(hatchery) {
      this.hatcheryObj = cloneObj(hatchery);
      this.hatcheryObj.name = decoder(this.hatcheryObj.name);
      this.dialogHatcheryEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.hatcheryObj.id === 0) {
        dispatch = 'createHatchery';
      } else {
        dispatch = 'updateHatchery';
      }

      this.hatcheryObj.name = upperCaseFirst(this.hatcheryObj.name);

      this.hatcheryObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          hatchery: this.hatcheryObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogHatcheryEntry = false;
    }
  },
  computed: {
    Hatcheries() {
      let hatcheries = cloneObj(this.$store.state.farm.hatcheries);
      if (!this.displayArchived) {
        hatcheries = hatcheries.filter((element) => !element.archived);
      }

      return hatcheries.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>

<style scoped></style>
