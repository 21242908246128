<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/processors.png"
          alt="Processors"
          width="100%"
          height="100%"
        />
      </div>
      <div class="q-pt-md">
        <q-toggle label="Display Archived" v-model="displayArchived"></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Name</div>
      <div
        class="col-auto"
        :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
      ></div>
    </div>

    <q-separator color="black" />

    <div v-for="processor in Processors" :key="processor.id">
      <div class="row items-center">
        <div class="col q-pl-sm">{{ decoder(processor.name) }}</div>
        <div class="col-auto q-pa-sm">
          <q-btn
            unelevated
            icon="edit"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            color="grey-3"
            text-color="primary"
            class="q-mr-sm"
            @click="editProcessor(processor)"
          >
          </q-btn>
          <q-btn
            v-if="!processor.archived && processor.transactions > 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="archive"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveProcessor(processor, true)"
          />
          <q-btn
            v-if="processor.archived"
            unelevated
            icon="unarchive"
            size="md"
            color="grey-3"
            text-color="secondary"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveProcessor(processor, false)"
          />
          <q-btn
            v-if="!processor.archived && processor.transactions === 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="delete"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="deleteProcessor(processor)"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <!-- Dialog Enter Processor -->
    <q-dialog v-model="dialogProcessorEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Processor</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="processorObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />

          <div class="row justify-end">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newProcessor = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    grain_id: null,
    fish_id: null,
    name: '',
    archived: false,
    transactions: 0,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-Processors',
  data() {
    return {
      decoder,
      dialogProcessorEntry: false,
      displayArchived: false,
      processorObj: newProcessor()
    };
  },
  methods: {
    add() {
      this.processorObj = newProcessor();

      this.dialogProcessorEntry = true;
    },
    archiveProcessor(processor, value) {
      const processorCopy = cloneObj(processor);
      processorCopy.archived = value;

      processorCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive processor',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this processor?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archiveProcessorConfirmed(processorCopy);
        });
    },
    archiveProcessorConfirmed(processor) {
      store
        .dispatch('updateProcessor', {
          processor
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    deleteProcessor(processor) {
      processor.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete processor',
          message: `Are you sure you want to delete this processor?`,
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteProcessorConfirmed(processor);
        });
    },
    deleteProcessorConfirmed(processor) {
      store
        .dispatch('deleteProcessor', {
          processor
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editProcessor(processor) {
      this.processorObj = cloneObj(processor);
      this.processorObj.name = decoder(this.processorObj.name);
      this.dialogProcessorEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.processorObj.id === 0) {
        dispatch = 'createProcessor';
      } else {
        dispatch = 'updateProcessor';
      }

      this.processorObj.name = upperCaseFirst(this.processorObj.name);

      this.processorObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          processor: this.processorObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogProcessorEntry = false;
    }
  },
  computed: {
    Processors() {
      let processors = cloneObj(this.$store.state.farm.processors);
      if (!this.displayArchived) {
        processors = processors.filter((element) => !element.archived);
      }

      return processors.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>

<style scoped></style>
