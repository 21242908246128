import date from 'quasar/src/utils/date.js';;
import store from '@/store';
import { cloneObj } from '@/lib/helpers';

const getCurrentByFeeding = (feedings) => {
  const feedDividerTime = store.state?.farm?.settings?.feed_divider_time ?? 12;

  const divider =
    +date.adjustDate(new Date(), {
      hours: 12,
      minutes: 0,
      seconds: 0
    }) / 1000;
  const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');
  const isBeforeDivider = +new Date() / 1000 < divider;

  let hasFeeding = false;
  let pounds = 0;
  let overfed = 1;
  let overfedFeeding = {};
  let overfedFeedingSet = false;
  for (let i = 0; i < feedings.length; i++) {
    const isCurrDate =
      date.formatDate(new Date(feedings[i].date * 1000), 'YYYY-MM-DD') ===
      currDate;

    if (!isCurrDate) {
      break;
    }

    hasFeeding = true;
    const feedingBeforeDivider = feedings[i].date < divider;

    if (isBeforeDivider === feedingBeforeDivider) {
      pounds += feedings[i].pounds;

      if (!overfedFeedingSet) {
        overfed = feedings[i].over_fed;
        overfedFeeding = feedings[i];
        overfedFeedingSet = true;
      }
    }
  }

  if (!hasFeeding) {
    pounds = null;
  }

  return { pounds, overfed, overfedFeeding };
};

const getCurrentByDay = (feedings) => {
  const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

  let hasFeeding = false;
  let pounds = 0;
  let overfed = 1;
  let overfedFeeding = {};
  let overfedFeedingSet = false;
  for (let i = 0; i < feedings.length; i++) {
    const feedingDate = date.formatDate(
      new Date(feedings[i].date * 1000),
      'YYYY-MM-DD'
    );

    if (feedingDate !== currDate) {
      break;
    }

    hasFeeding = true;
    pounds += feedings[i].pounds;

    if (!overfedFeedingSet) {
      overfed = feedings[i].over_fed;
      overfedFeeding = feedings[i];
      overfedFeedingSet = true;
    }
  }

  if (!hasFeeding) {
    pounds = null;
  }
  return { pounds, overfed, overfedFeeding };
};

const getLastByDay = (feedings) => {
  const currDate = date.formatDate(new Date(), 'YYYY-MM-DD');

  let pounds = 0;
  let lastDate = null;
  let overfed = 1;
  let overfedFeeding = {};
  let overfedFeedingSet = false;
  for (let i = 0; i < feedings.length; i++) {
    const feedingDate = date.formatDate(
      new Date(feedings[i].date * 1000),
      'YYYY-MM-DD'
    );

    if (feedingDate === currDate) {
      continue;
    }

    if (!lastDate) {
      lastDate = date.formatDate(
        new Date(feedings[i].date * 1000),
        'YYYY-MM-DD'
      );
    }

    if (feedingDate !== lastDate) {
      break;
    }

    pounds += feedings[i].pounds;

    if (!overfedFeedingSet) {
      overfed = feedings[i].over_fed;
      overfedFeeding = feedings[i];
      overfedFeedingSet = true;
    }
  }

  return { pounds, overfed, overfedFeeding };
};

const getLastByFeeding = (feedings) => {
  const feedDividerTime = store.state?.farm?.settings?.feed_divider_time ?? 12;
  const divider = +date.adjustDate(new Date(), {
    hours: feedDividerTime,
    minutes: 0,
    seconds: 0
  });

  const isAfterDivider = +new Date() > divider;

  const currFeedingStart = isAfterDivider
    ? divider / 1000
    : +date.adjustDate(new Date(), {
        hours: 0,
        minutes: 0,
        seconds: 0
      }) / 1000;

  let lastFeedingStart = null;
  let pounds = 0;
  let overfed = 1;
  let overfedFeeding = {};
  let overfedFeedingSet = false;
  for (let i = 0; i < feedings.length; i++) {
    // is current feeding
    if (feedings[i].date > currFeedingStart) {
      continue;
    }

    if (!lastFeedingStart) {
      const divider = +date.adjustDate(feedings[i].date * 1000, {
        hours: feedDividerTime,
        minutes: 0,
        seconds: 0
      });

      const isAfterDivider = feedings[i].date * 1000 > divider;

      lastFeedingStart = isAfterDivider
        ? divider / 1000
        : +date.adjustDate(feedings[i].date * 1000, {
            hours: 0,
            minutes: 0,
            seconds: 0
          }) / 1000;
    }

    if (feedings[i].date < lastFeedingStart) {
      break;
    }

    pounds += feedings[i].pounds;

    if (!overfedFeedingSet) {
      overfed = feedings[i].over_fed;
      overfedFeeding = feedings[i];
      overfedFeedingSet = true;
    }
  }

  return { pounds, overfed, overfedFeeding };
};

const getLastTenByDay = (feedings) => {
  const tenFeedings = [];
  const feedingDates = [];
  let currentFeeding = 0;
  let feedingDate = null;
  let currDate = null;
  for (const feeding of feedings) {
    // This is a fish loss entry - skip it
    if (!feeding.feeder_name) {
      continue;
    }

    if (tenFeedings.length === 10) {
      break;
    }

    if (!feedingDate) {
      feedingDate = date.formatDate(feeding.date * 1000, 'MM/DD/YY');
    }

    // we need the year for sorting totals - but we shorten the labels to MM/DD later
    currDate = date.formatDate(feeding.date * 1000, 'MM/DD/YY');

    if (feedingDate !== currDate) {
      tenFeedings.push(currentFeeding);
      feedingDates.push(feedingDate);
      currentFeeding = 0;
      feedingDate = currDate;
    }

    currentFeeding += feeding.pounds;
  }

  return {
    tenFeedings: tenFeedings.reverse(),
    feedingDates: feedingDates.reverse()
  };
};

const getMorningFeedings = (feedings, lastTenDates) => {
  const feedDividerTime = store.state?.farm?.settings?.feed_divider_time ?? 12;
  const dates = cloneObj(lastTenDates);

  const tenFeedings = [];
  let currentFeeding = 0;
  let feedingDate = dates.pop();
  let currDate = null;
  for (const feeding of feedings) {
    currDate = date.formatDate(feeding.date * 1000, 'MM/DD/YY');

    if (feedingDate !== currDate) {
      tenFeedings.push(currentFeeding);
      currentFeeding = 0;

      if (dates.length === 0) {
        break;
      }

      feedingDate = dates.pop();
    }

    const divider = +date.adjustDate(feeding.date * 1000, {
      hours: feedDividerTime,
      minutes: 0,
      seconds: 0
    });

    if (feeding.date * 1000 < divider) {
      currentFeeding += feeding.pounds;
    }
  }

  return tenFeedings.reverse();
};

const getEveningFeedings = (feedings, lastTenDates) => {
  const feedDividerTime = store.state?.farm?.settings?.feed_divider_time ?? 12;
  const tenFeedings = [];
  const dates = cloneObj(lastTenDates);

  let currentFeeding = 0;
  let feedingDate = dates.pop();
  let currDate = null;
  for (const feeding of feedings) {
    currDate = date.formatDate(feeding.date * 1000, 'MM/DD/YY');

    if (feedingDate !== currDate) {
      tenFeedings.push(currentFeeding);
      currentFeeding = 0;

      if (dates.length === 0) {
        break;
      }

      feedingDate = dates.pop();
    }

    const divider = +date.adjustDate(feeding.date * 1000, {
      hours: feedDividerTime,
      minutes: 0,
      seconds: 0
    });

    if (feeding.date * 1000 > divider) {
      currentFeeding += feeding.pounds;
    }
  }

  return tenFeedings.reverse();
};

const getChartData = (
  pond,
  displayAllFeedings,
  displayMornings,
  displayEvenings
) => {
  const lastTenByDay = getLastTenByDay(pond.feedings);

  const morningFeedings = getMorningFeedings(
    pond.feedings,
    lastTenByDay.feedingDates
  );

  const eveningFeedings = getEveningFeedings(
    pond.feedings,
    lastTenByDay.feedingDates
  );

  // here we shorten the labels to MM/DD - but we need the year for sorting totals
  // so that is why the year is getting fed into this function
  const labels = [];
  for (const feedingDate of lastTenByDay.feedingDates) {
    labels.push(date.formatDate(feedingDate, 'MM/DD'));
  }

  const chartData = {
    labelsWithYear: lastTenByDay.feedingDates,
    labels,
    datasets: []
  };

  if (displayAllFeedings) {
    chartData.datasets.push({
      label: 'All Feedings',
      backgroundColor: '#245AD3',
      fill: false,
      borderColor: '#245AD3',
      borderWidth: 1,
      tension: 0.2,
      data: lastTenByDay.tenFeedings
    });
  }

  if (displayMornings) {
    chartData.datasets.push({
      label: 'Morning',
      backgroundColor: '#5ad324',
      fill: false,
      borderColor: '#5ad324',
      borderWidth: 1,
      tension: 0.2,
      data: morningFeedings
    });
  }

  if (displayEvenings) {
    chartData.datasets.push({
      label: 'Evening',
      backgroundColor: '#d3245a',
      fill: false,
      borderColor: '#d3245a',
      borderWidth: 1,
      tension: 0.2,
      data: eveningFeedings
    });
  }

  return chartData;
};

const getChartTotals = (
  ponds,
  displayAllFeedings,
  displayMornings,
  displayEvenings
) => {
  const chartData = {
    labels: [],
    datasets: []
  };

  for (const pond of ponds) {
    chartData.labels = [
      ...new Set([...chartData.labels, ...pond.chartData.labelsWithYear])
    ];
  }

  // sort, then format to MM/DD
  chartData.labels.sort((a, b) => +new Date(a) - +new Date(b));
  chartData.labels = chartData.labels.map((label) =>
    date.formatDate(label, 'MM/DD')
  );

  const labelsMap = new Map();
  for (const [index, value] of chartData.labels.entries()) {
    labelsMap.set(value, index);
  }

  if (displayAllFeedings) {
    chartData.datasets.push({
      label: 'All Feedings',
      backgroundColor: '#245AD3',
      fill: false,
      borderColor: '#245AD3',
      borderWidth: 1,
      tension: 0.2,
      data: chartData.labels.map((label) => 0)
    });
  }

  if (displayMornings) {
    chartData.datasets.push({
      label: 'Morning',
      backgroundColor: '#5ad324',
      fill: false,
      borderColor: '#5ad324',
      borderWidth: 1,
      tension: 0.2,
      data: chartData.labels.map((label) => 0)
    });
  }

  if (displayEvenings) {
    chartData.datasets.push({
      label: 'Evening',
      backgroundColor: '#d3245a',
      fill: false,
      borderColor: '#d3245a',
      borderWidth: 1,
      tension: 0.2,
      data: chartData.labels.map((label) => 0)
    });
  }

  const allFeedingsIndex = chartData.datasets.findIndex(
    (x) => x.label === 'All Feedings'
  );
  const morningFeedingsIndex = chartData.datasets.findIndex(
    (x) => x.label === 'Morning'
  );
  const eveningFeedingsIndex = chartData.datasets.findIndex(
    (x) => x.label === 'Evening'
  );
  for (const pond of ponds) {
    const labels = pond.chartData.labels;

    if (displayAllFeedings && allFeedingsIndex > -1) {
      const dataset = pond.chartData.datasets[allFeedingsIndex];
      const totalsDataset = chartData.datasets[allFeedingsIndex];

      for (let i = 0; i < dataset.data.length; i++) {
        totalsDataset.data[labelsMap.get(labels[i])] += dataset.data[i];
      }
    }

    if (displayMornings && morningFeedingsIndex > -1) {
      const dataset = pond.chartData.datasets[morningFeedingsIndex];
      const totalsDataset = chartData.datasets[morningFeedingsIndex];

      for (let i = 0; i < dataset.data.length; i++) {
        totalsDataset.data[labelsMap.get(labels[i])] += dataset.data[i];
      }
    }

    if (displayEvenings && eveningFeedingsIndex > -1) {
      const dataset = pond.chartData.datasets[eveningFeedingsIndex];
      const totalsDataset = chartData.datasets[eveningFeedingsIndex];

      for (let i = 0; i < dataset.data.length; i++) {
        totalsDataset.data[labelsMap.get(labels[i])] += dataset.data[i];
      }
    }
  }

  return chartData;
};

export {
  getCurrentByFeeding,
  getCurrentByDay,
  getLastByFeeding,
  getLastByDay,
  getLastTenByDay,
  getMorningFeedings,
  getEveningFeedings,
  getChartData,
  getChartTotals
};
