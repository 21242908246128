<template>
  <div>
    <q-resize-observer @resize="onResize" />

    <div class="row justify-end q-pa-sm">
      <q-btn
        unelevated
        icon="add"
        color="grey-3"
        text-color="primary"
        label="Create Folder"
        size="md"
        @click="createFolder"
      />
    </div>
    <q-separator />

    <div
      style="height: calc(100vh - 60px); scrollbar-gutter: stable"
      :style="`height: ${usableHeight}px`"
      class="scroll q-pt-lg q-px-sm"
    >
      <div class="row justify-center q-px-md">
        <div class="col-12" style="max-width: 600px">
          <div v-if="foldersLoading" class="q-py-sm">
            <q-linear-progress
              indeterminate
              rounded
              color="primary"
              size="md"
            />
          </div>

          <div class="q-pt-sm">
            <div v-for="folder in folders" :key="folder.id" class="q-py-sm">
              <div class="row items-center q-py-xs">
                <div class="col row items-center">
                  <q-icon name="folder" size="40px" color="primary" />
                  <div
                    class="text-h6 ellipsis"
                    :class="$q.screen.xs ? 'q-pl-sm' : 'q-pl-md'"
                  >
                    {{ folder.folder_name }}
                  </div>
                </div>

                <q-space />
                <div class="q-pl-sm">
                  <q-btn
                    unelevated
                    label="Edit"
                    color="grey-3"
                    text-color="accent"
                    padding="2px 10px"
                    class="q-mr-sm"
                    @click="editFolder(folder)"
                  />
                  <q-btn
                    unelevated
                    label="Open"
                    color="grey-3"
                    text-color="primary"
                    padding="2px 10px"
                    @click="openFolder(folder, 'userFolder')"
                  />
                </div>
              </div>
              <q-separator class="q-mb-sm" />

              <div class="row">
                <div
                  v-for="(thumbnail, index) in folder.thumbnails"
                  :key="thumbnail.compressed_key"
                >
                  <div class="q-pr-sm q-pb-sm">
                    <div
                      class="cursor-pointer"
                      @click="viewAttachment(folder, index, 'userFolder')"
                    >
                      <q-img
                        v-if="thumbnail.is_pdf === 0"
                        :src="thumbnail.compressed_url"
                        :native-context-menu="true"
                        spinner-color="primary"
                        style="
                          height: 100px;
                          width: 100px;
                          border: solid 1px #eeeeee;
                        "
                        class="rounded-borders"
                      />
                      <PDF
                        v-if="thumbnail.is_pdf !== 0"
                        :src="thumbnail.img_url"
                        :page="1"
                        style="
                          height: 100px;
                          width: 100px;
                          border: solid 1px #eeeeee;
                          overflow: hidden;
                        "
                        class="rounded-borders"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="q-py-sm"></div>
            </div>

            <div>
              <div class="text-center text-h6 q-pt-lg">Daily Journals</div>
              <div
                style="height: 4px; border-radius: 8px"
                class="bg-primary"
              ></div>
            </div>

            <div class="row items-center q-py-xs">
              <div class="col row items-center">
                <q-icon name="folder" size="40px" color="primary" />
                <div
                  class="text-h6 ellipsis"
                  :class="$q.screen.xs ? 'q-pl-sm' : 'q-pl-md'"
                >
                  Journal Images
                </div>
              </div>

              <q-space />
              <div class="q-pl-sm">
                <q-btn
                  unelevated
                  label="Open"
                  color="grey-3"
                  text-color="primary"
                  padding="2px 10px"
                  @click="openFolder(DailyJournalFolder, 'dailyJournalFolder')"
                />
              </div>
            </div>
            <q-separator class="q-mb-sm" />

            <div class="row">
              <div
                v-for="(thumbnail, index) in DailyJournalFolder.thumbnails"
                :key="thumbnail.compressed_key"
              >
                <div class="q-pr-sm q-pb-sm">
                  <div
                    class="cursor-pointer"
                    @click="
                      viewAttachment(
                        DailyJournalFolder,
                        index,
                        'dailyJournalFolder'
                      )
                    "
                  >
                    <q-img
                      v-if="thumbnail.is_pdf === 0"
                      :src="thumbnail.compressed_url"
                      :native-context-menu="true"
                      spinner-color="primary"
                      style="
                        height: 100px;
                        width: 100px;
                        border: solid 1px #eeeeee;
                      "
                      class="rounded-borders"
                    />
                    <PDF
                      v-if="thumbnail.is_pdf !== 0"
                      :src="thumbnail.img_url"
                      :page="1"
                      style="
                        height: 100px;
                        width: 100px;
                        border: solid 1px #eeeeee;
                        overflow: hidden;
                      "
                      class="rounded-borders"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="q-py-sm"></div>

            <div>
              <div class="text-center text-h6 q-pt-lg">Bins</div>
              <div
                style="height: 4px; border-radius: 8px"
                class="bg-primary"
              ></div>
            </div>

            <div class="row items-center q-py-xs">
              <div class="col row items-center">
                <q-icon name="folder" size="40px" color="primary" />
                <div
                  class="text-h6 ellipsis"
                  :class="$q.screen.xs ? 'q-pl-sm' : 'q-pl-md'"
                >
                  Deliveries
                </div>
              </div>

              <q-space />
              <div class="q-pl-sm">
                <q-btn
                  unelevated
                  label="Open"
                  color="grey-3"
                  text-color="primary"
                  padding="2px 10px"
                  @click="openFolder(BinFolder, 'binFolder')"
                />
              </div>
            </div>
            <q-separator class="q-mb-sm" />

            <div class="row">
              <div
                v-for="(thumbnail, index) in BinFolder.thumbnails"
                :key="thumbnail.compressed_key"
              >
                <div class="q-pr-sm q-pb-sm">
                  <div
                    class="cursor-pointer"
                    @click="viewAttachment(BinFolder, index, 'binFolder')"
                  >
                    <q-img
                      v-if="thumbnail.is_pdf === 0"
                      :src="thumbnail.compressed_url"
                      :native-context-menu="true"
                      spinner-color="primary"
                      style="
                        height: 100px;
                        width: 100px;
                        border: solid 1px #eeeeee;
                      "
                      class="rounded-borders"
                    />
                    <PDF
                      v-if="thumbnail.is_pdf !== 0"
                      :src="thumbnail.img_url"
                      :page="1"
                      style="
                        height: 100px;
                        width: 100px;
                        border: solid 1px #eeeeee;
                        overflow: hidden;
                      "
                      class="rounded-borders"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="q-py-sm"></div>

            <div v-if="Object.keys(FishMoveFolders).length > 0">
              <div class="text-center text-h6 q-pt-lg">Fish Move</div>
              <div
                style="height: 4px; border-radius: 8px"
                class="bg-primary"
              ></div>
            </div>

            <div
              v-for="(val, key) in FishMoveFolders"
              :key="key"
              class="q-py-sm"
            >
              <div class="row items-center q-py-xs">
                <div class="col row items-center">
                  <q-icon name="folder" size="40px" color="primary" />
                  <div
                    class="text-h6 ellipsis"
                    :class="$q.screen.xs ? 'q-pl-sm' : 'q-pl-md'"
                  >
                    {{ val.folder_name }}
                  </div>
                </div>

                <q-space />
                <div class="q-pl-sm">
                  <q-btn
                    unelevated
                    label="Open"
                    color="grey-3"
                    text-color="primary"
                    padding="2px 10px"
                    @click="openFolder(val, 'fishMoveFolder')"
                  />
                </div>
              </div>
              <q-separator class="q-mb-sm" />

              <div class="row">
                <div
                  v-for="(thumbnail, index) in val.thumbnails"
                  :key="thumbnail.compressed_key"
                >
                  <div class="q-pr-sm q-pb-sm">
                    <div
                      class="cursor-pointer"
                      @click="viewAttachment(val, index, 'fishMoveFolder')"
                    >
                      <q-img
                        v-if="thumbnail.is_pdf === 0"
                        :src="thumbnail.compressed_url"
                        :native-context-menu="true"
                        spinner-color="primary"
                        style="
                          height: 100px;
                          width: 100px;
                          border: solid 1px #eeeeee;
                        "
                        class="rounded-borders"
                      />
                      <PDF
                        v-if="thumbnail.is_pdf !== 0"
                        :src="thumbnail.img_url"
                        :page="1"
                        style="
                          height: 100px;
                          width: 100px;
                          border: solid 1px #eeeeee;
                          overflow: hidden;
                        "
                        class="rounded-borders"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="q-py-sm"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- attachment viewer dialog -->
    <q-dialog
      v-model="dialogAttachmentViewer"
      persistent
      :maximized="true"
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card>
        <div class="row justify-between items-center q-py-sm">
          <div class="col-auto q-pl-sm" style="width: 108px">
            <q-btn
              unelevated
              label="Close"
              color="primary"
              icon="arrow_back"
              padding="xs sm"
              v-close-popup
            />
          </div>
          <div :class="$q.screen.lt.sm && 'q-pr-sm'">
            <q-btn
              flat
              round
              dense
              icon="chevron_left"
              padding="1px 2px"
              size="16px"
              class="q-mr-sm"
              :disabled="thumbnailIndex === 0"
              @click="
                viewAttachment(thumbnailFolder, thumbnailIndex - 1, folderType)
              "
            />

            {{ thumbnailIndex + 1 }} / {{ thumbnailFolder.thumbnails.length }}

            <q-btn
              flat
              round
              dense
              icon="chevron_right"
              padding="1px 2px"
              size="16px"
              :disabled="
                thumbnailIndex === thumbnailFolder.thumbnails.length - 1
              "
              class="q-ml-sm"
              @click="
                viewAttachment(thumbnailFolder, thumbnailIndex + 1, folderType)
              "
            />
          </div>
          <div class="gt-xs col-auto q-pr-sm" style="width: 108px"></div>
        </div>
        <q-separator />

        <q-card-section
          style="
            scrollbar-gutter: stable both-edges;
            height: calc(100vh - 60px);
          "
          class="scroll q-pt-lg"
        >
          <AttachmentViewer
            :attachment="attachment"
            :index="thumbnailIndex"
            :folderType="folderType"
            @openFolder="openFolder(thumbnailFolder, folderType)"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- attachment display -->
    <Attachments
      v-if="displayAttachments"
      :folderId="attachmentsFolder.id"
      :parentObj="attachmentsFolder"
      :parentType="'farmFolders'"
      :folderType="folderType"
      @closeAttachments="displayAttachments = false"
    />

    <!-- Folder Dialog -->
    <q-dialog persistent position="top" v-model="dialogEditFolder">
      <q-card style="width: 450px">
        <div class="text-center text-h6 text-primary q-py-md">
          {{ Action }} Folder
        </div>
        <q-separator />

        <q-form @submit="submitFolder">
          <div class="q-pa-md">
            <q-input
              filled
              dense
              autogrow
              v-model="folder.folder_name"
              label="Folder Name"
            />
          </div>

          <div class="row justify-end q-pr-md q-pb-md">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              v-if="action === 'update' && folder.attachments.length === 0"
              unelevated
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteFolder"
            />
            <q-btn
              unelevated
              :label="
                action === 'edit' && !$q.screen.xs ? 'Submit Edit' : 'Submit'
              "
              color="primary"
              size="md"
              type="submit"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import PDF from 'vue-pdf';
import Attachments from '@/components/general/Attachments.vue';
import AttachmentViewer from '@/components/general/AttachmentViewer.vue';

import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth,
  smartCompare
} from '@/lib/helpers';
import { date } from 'quasar';
import AuthUtils from '@/lib/auth-utils';
import { upperCaseFirst } from '@/lib/helpers';

export default {
  name: 'FarmAttachments',
  components: {
    Attachments,
    AttachmentViewer,
    PDF
  },
  data() {
    return {
      action: 'create',
      attachment: {},
      attachmentsFolder: {
        id: '',
        folder_name: '',
        attachments: [],
        thumbnails: []
      },
      dialogAttachmentViewer: false,
      dialogEditFolder: false,
      displayAttachments: false,
      folder: {
        id: 0,
        folder_name: '',
        attachments: []
      },
      folders: [],
      foldersLoading: false,
      folderType: 'userFolder',
      pdf: {
        currentPage: 1,
        pageCount: 1
      },
      thumbnailFolder: {
        id: '',
        folder_name: '',
        attachments: [],
        thumbnails: []
      },
      thumbnailIndex: 0,
      usableHeight: 500
    };
  },
  mounted() {
    this.onResize();
    const item = {
      farmId: store.state.selectedFarm.farm_id,
      storeInfo: {
        action: 'getFarmFolders',
        parentType: 'farmFolders',
        userId: store.state.user.user_id,
        updateOtherUsers: false,
        farmId: store.state.selectedFarm.farm_id
      }
    };

    store.dispatch('publishAttachment', item);
    this.foldersLoading = true;
  },
  methods: {
    createFolder() {
      this.action = 'create';
      this.folder = {
        id: 'new',
        name: ''
      };
      this.dialogEditFolder = true;
    },
    deleteFolder() {
      this.$q
        .dialog({
          title: 'Delete Folder',
          message: `Are you sure you want to delete folder "${this.folder.folder_name}"?`,
          ok: {
            label: 'Delete',
            icon: 'delete',
            color: 'negative'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitFolder();
        });
    },
    editFolder(folder) {
      this.action = 'update';
      this.dialogEditFolder = true;

      this.folder = cloneObj(folder);
    },
    openFolder(folder, folderType) {
      this.attachmentsFolder = cloneObj(folder);
      this.folderType = folderType;
      this.displayAttachments = true;
    },
    submitFolder() {
      this.dialogEditFolder = false;

      const item = {
        id: this.folder.id,
        farmId: store.state.selectedFarm.farm_id,
        folderName: upperCaseFirst(this.folder.folder_name),
        storeInfo: {
          action: this.action + 'FarmFolder',
          parentType: 'farmFolders',
          userId: store.state.user.user_id,
          farmId: store.state.selectedFarm.farm_id,
          updateOtherUsers: false
        }
      };

      store.dispatch('publishAttachment', item);
    },
    onResize() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 103;
    },
    viewAttachment(folder, index, folderType) {
      this.folderType = folderType;
      this.thumbnailFolder = cloneObj(folder);
      this.thumbnailIndex = index;
      this.attachment = this.thumbnailFolder.thumbnails[index];
      this.dialogAttachmentViewer = true;
    }
  },
  computed: {
    Action() {
      const str = this.action;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    BinFolder() {
      const binFolder = {};

      const attachments = [];
      for (const bin of store.state.farm.bins) {
        for (const delivery of bin.deliveries) {
          for (const attachment of delivery.attachments) {
            const attachmentObj = cloneObj(attachment);
            attachments.push({
              ...cloneObj(attachmentObj),
              binGrainId: bin.grain_id,
              transactionId: delivery.transaction_id,
              binName: bin.name,
              type: 'delivery'
            });
          }
        }
      }

      attachments.sort((a, b) => b.upload_date - a.upload_date);

      binFolder.folder_name = 'Bins';
      binFolder.attachments = cloneObj(attachments);
      binFolder.thumbnails = cloneObj(attachments);

      return binFolder;
    },
    DailyJournalFolder() {
      const dailyJournalFolder = {};

      const attachments = [];
      for (const journal of store.state.dailyJournals) {
        for (const attachment of journal.attachments) {
          const attachmentObj = cloneObj(attachment);
          attachments.push({
            ...cloneObj(attachmentObj),
            dailyJournalId: journal.id,
            type: 'dailyJournal'
          });
        }
      }

      attachments.sort((a, b) => b.upload_date - a.upload_date);

      dailyJournalFolder.folder_name = 'Daily Journals';
      dailyJournalFolder.attachments = cloneObj(attachments);
      dailyJournalFolder.thumbnails = cloneObj(attachments);

      return dailyJournalFolder;
    },
    FishMoveFolders() {
      const fishMoveFolders = {};

      const attachments = [];
      for (const pond of store.state.farm.ponds) {
        for (const history of pond.growth.ManageHistory) {
          for (const attachment of history.attachments) {
            const attachmentObj = cloneObj(attachment);
            attachments.push({
              ...cloneObj(attachmentObj),
              fishMoveType: history.transfer_type,
              pondId: pond.id,
              pondName: pond.name
            });
          }
        }
      }

      for (const attachment of attachments) {
        if (fishMoveFolders[attachment.fishMoveType]) {
          fishMoveFolders[attachment.fishMoveType].attachments.push(attachment);
          if (
            fishMoveFolders[attachment.fishMoveType].thumbnails.length <= 24
          ) {
            fishMoveFolders[attachment.fishMoveType].thumbnails.push(
              attachment
            );
          }
        } else {
          fishMoveFolders[attachment.fishMoveType] = {
            folder_name: attachment.fishMoveType,
            attachments: [attachment],
            thumbnails: [attachment]
          };
        }
      }

      for (const folder of Object.values(fishMoveFolders)) {
        folder.attachments.sort((a, b) => b.upload_date - a.upload_date);
        folder.thumbnails.sort((a, b) => b.upload_date - a.upload_date);
      }

      return fishMoveFolders;
    },
    IsAccountOwner() {
      const userRole = AuthUtils.roleDecoder(this.$store.getters.userFarmRole);
      return userRole === 'Account owner';
    },
    IsAdmin() {
      return this.$store.state.user.feedx_level === 'super-admin';
    }
  },
  watch: {
    '$store.state.farm.foldersWatcher': {
      handler() {
        this.foldersLoading = false;
        this.folders = cloneObj(store.state.farm.folders);

        for (const folder of this.folders) {
          folder.attachments = [];
          folder.thumbnails = [];
          for (const attachment of store.state.farm.attachments) {
            if (attachment.folder_id === folder.id) {
              folder.attachments.push(attachment);

              if (folder.thumbnails.length <= 24) {
                folder.thumbnails.push(attachment);
              }
            }
          }

          if (folder.id === this.attachmentsFolder.id) {
            this.attachmentsFolder = cloneObj(folder);
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style></style>
