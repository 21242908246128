import Vue from 'vue';

import './styles/quasar.sass';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;

Vue.use(Quasar, {
  config: {
    brand: {
      // this commented blue is triadic to the accent color
      // primary: '#1e39a4',
      primary: '#245AD3',
      secondary: '#5ad324',
      // secondary: '#30736D',
      // secondary: '#d39d24',
      accent: '#d3245a',
      // accent: '#a41e39',
      dark: '#1d1d1d',
      positive: '#30736D',
      negative: '#a41e39',
      info: '#4524D3',
      warning: '#F2C037'
    }
  },
  plugins: {
    Dialog,
    Loading,
    Notify
  }
});
