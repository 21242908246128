const fishGroupColors = [
  'green-5',
  'grey-5',
  'light-blue-5',
  'purple-5',
  'amber-5',
  'pink-5',
  'blue-grey-5'
];

export { fishGroupColors };
