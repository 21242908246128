<template>
  <div>
    <div id="adjustable" class="bg-grey-1 shadow-2">
      <q-resize-observer @resize="onResize" />

      <div class="row justify-between items-center no-wrap">
        <div class="q-pa-sm" :style="$q.screen.gt.xs && 'width: 162px'">
          <q-btn
            icon="arrow_back"
            :label="$q.screen.gt.xs ? 'Overview' : ''"
            color="primary"
            size="md"
            @click="$emit('back')"
          />
        </div>

        <div class="q-px-md">
          <div
            class="q-px-md text-center"
            :class="$q.screen.xs ? 'text-subtitle1' : 'text-h6'"
          >
            {{ pond.name }}
          </div>

          <div class="row justify-center q-pb-sm">
            <q-btn
              v-if="goToPond.previous || goToPond.next"
              color="primary"
              icon="chevron_left"
              padding="1px 22px"
              size="md"
              class="q-mr-xs"
              :disabled="!goToPond.previous"
              @click="$emit('goToPrevious')"
            />

            <q-btn
              v-if="goToPond.previous || goToPond.next"
              color="primary"
              icon="chevron_right"
              padding="1px 22px"
              size="md"
              class="q-ml-xs"
              :disabled="!goToPond.next"
              @click="$emit('goToNext')"
            />
          </div>
        </div>

        <div
          class="row justify-end q-pa-sm"
          :style="$q.screen.gt.xs && 'width: 162px'"
        >
          <q-btn
            :label="$q.screen.gt.xs ? 'Oxygen' : ''"
            color="primary"
            icon="add"
            size="md"
            @click="createNewOxygen"
          />
        </div>
      </div>

      <q-separator />
      <div class="row text-bold q-py-xs" :class="$q.screen.gt.xs && 'q-px-sm'">
        <div class="col-auto" style="width: 70px; padding-left: 20px">Date</div>
        <div class="col col-sm-3 text-center">Monitor</div>
        <div class="col col-sm-3 text-center">Handheld</div>
        <div class="lt-sm col-auto text-center" style="width: 60px">Note</div>
        <div class="gt-xs col text-center">Note</div>
        <div style="width: 52px"></div>
      </div>
    </div>

    <div
      v-if="History.length < 1"
      class="text-center text-h5 text-grey-6 q-px-md q-py-lg"
    >
      No Entries For This Pond
    </div>

    <q-virtual-scroll
      v-if="History.length"
      :style="`height: ${usableHeight}px`"
      :items="History"
      separator
    >
      <template v-slot="{ item }">
        <div
          class="row items-center q-py-sm"
          :class="$q.screen.gt.xs && 'q-px-sm'"
        >
          <div class="col-auto q-pl-sm" style="width: 70px">
            {{ parseTimeStamp(item.date, 'short') }}
          </div>

          <div class="col col-sm-3 text-center" style="word-wrap: break-word">
            {{ item.monitor_reading }}
          </div>

          <div class="col col-sm-3 text-center" style="word-wrap: break-word">
            {{ item.handheld_reading }}
          </div>

          <div class="gt-xs col text-center">
            {{ item.memo }}
          </div>

          <div class="lt-sm q-px-sm" style="width: 60px">
            <q-btn
              v-if="item.memo !== ''"
              unelevated
              icon="sticky_note_2"
              color="grey-3"
              text-color="black"
              padding="2px 10px"
              @click="displayNote(item)"
            />

            <div v-else style="width: 44px"></div>
          </div>

          <div class="q-pr-sm">
            <q-btn
              unelevated
              icon="edit"
              color="grey-3"
              text-color="accent"
              padding="2px 10px"
              @click="editOxygen(item)"
            />
          </div>
        </div>
        <q-separator />
      </template>
    </q-virtual-scroll>

    <!-- Oxygen Entry Dialog -->
    <q-dialog position="top" v-model="dialogOxygen">
      <q-card class="q-pa-md" style="width: min(400px, 90vw)">
        <q-form @submit="submitOxygen">
          <div class="q-pb-sm">
            <q-input
              filled
              dense
              label="Date"
              v-model="oxygen.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="oxygen.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              v-model.number="oxygen.monitor_reading"
              label="Monitor Reading"
              step="any"
              min="0"
              mask="#####"
              type="number"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => isNumValid(val) || val === 0 || '']"
            />
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              v-model.number="oxygen.handheld_reading"
              label="Handheld Reading"
              step="any"
              min="0"
              mask="#####"
              type="number"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => isNumValid(val) || val === 0 || '']"
            />
          </div>

          <div class="q-pb-sm">
            <q-input filled dense autogrow v-model="oxygen.memo" label="Note" />
          </div>

          <div class="row justify-end q-pt-xs">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              unelevated
              v-if="action === 'update'"
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteOxygen"
            />
            <q-btn
              unelevated
              :label="
                action === 'edit' && !$q.screen.xs ? 'Submit Edit' : 'Submit'
              "
              color="primary"
              size="md"
              type="submit"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- Notes Dialog -->
    <q-dialog position="right" full-hight v-model="dialogNote">
      <q-card style="width: 300px">
        <div class="row items-center q-pa-sm">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ parseTimeStamp(noteDate || '', 'short') }}
            </div>
          </div>
          <q-btn
            unelevated
            icon="close"
            color="grey-3"
            text-color="black"
            size="md"
            padding="2px 10px"
            v-close-popup
          />
        </div>
        <q-separator style="padding-top 1px" />

        <div class="q-pa-sm">
          <span class="text-subtitle2 q-pr-sm"> Note: </span>
          {{ decoder(note) }}
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { parseTimeStamp } from '@/lib/date-utils.js';
import { getUID, guidMatchHelper } from '@/store/transactionHelpers';
import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date, uid } from 'quasar';

function defaultOxygen() {
  return {
    id: null,
    user_id: store.state.user.id,
    monitor_reading: null,
    handheld_reading: null,
    date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'PondEntries',
  props: {
    pond: {
      type: Object,
      required: true
    },
    goToPond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      decoder,
      dialogOxygen: false,
      dialogNote: false,
      isDateValid,
      isNumValid,
      note: '',
      noteDate: '',
      parseTimeStamp,
      oxygen: defaultOxygen(),
      usableHeight: 0
    };
  },
  // mounted() {},
  methods: {
    createNewOxygen() {
      this.action = 'create';
      this.oxygen = defaultOxygen();
      this.dialogOxygen = true;
    },
    deleteOxygen() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: 'Are you sure you want to delete this oxygen record?',
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitOxygen();
        });
    },
    displayNote(oxygen) {
      this.note = oxygen.memo;
      this.noteDate = oxygen.date;
      this.dialogNote = true;
    },
    editOxygen(oxygen) {
      this.action = 'update';

      this.oxygen = {
        ...defaultOxygen(),
        ...oxygen
      };

      this.dateCopy = this.oxygen.date;
      this.oxygen.date = date.formatDate(
        new Date(oxygen.date * 1000),
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.oxygen.date;

      this.dialogOxygen = true;
    },
    onResize() {
      const adjustableHeight =
        document.getElementById('adjustable').clientHeight;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 50 - adjustableHeight;
    },
    async submitOxygen() {
      const oxygen = cloneObj(this.oxygen);

      const dateStr = this.oxygen.date;
      oxygen.date = setTimeOfDay(dateStr);

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        oxygen.date = this.dateCopy;
      }

      if (this.action === 'create') {
        oxygen.id = 0;
      }

      oxygen.guid = getUID();
      oxygen.user_id = store.state.user.user_id;
      oxygen.storeInfo.action = this.action + 'OxygenPond';
      oxygen.storeInfo.category = 'oxygenPond';
      oxygen.storeInfo.pondId = this.pond.id;

      guidMatchHelper(oxygen, this.action);

      store.dispatch('publish', oxygen);

      this.dialogOxygen = false;

      this.$q.notify({
        color: this.action === 'delete' ? 'negative' : 'primary',
        message:
          this.action === 'delete'
            ? 'Oxygen Record Deleted'
            : 'Oxygen Record Saved'
      });
    }
  },
  computed: {
    History() {
      return this.pond.oxygenRecords;
    }
  }
};
</script>

<style></style>
