import date from 'quasar/src/utils/date.js';import uid from 'quasar/src/utils/uid.js';;
import store from '@/store';

const createTransactionObj = () => {
  return {
    id: null,
    from_account: null,
    to_account: null,
    pounds: null,
    price_per_pound: null,
    feed_type: null,
    date: date.formatDate(new Date(), 'X'),
    user_id: store.state.user.user_id,
    memo: null,
    over_fed: 1,
    guid: null,
    translock_id: null,
    purchaser: null,
    harvest_link_id: null,
    has_children: null,
    feed_contract_id: null,
    attachments: [],
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
};

const getUID = () => {
  return uid() + ':' + +new Date();
};

const guidMatchHelper = (item, action) => {
  item.storeInfo.guidMatch = false;
  item.storeInfo.guidOld = item.guid;
  item.guid = getUID();

  if ((item.id + '').startsWith('new')) {
    item.storeInfo.guidMatch = true;
    item.storeInfo.tmpId = item.id;

    // This is necessary for the mqtt sub to work properly
    item.id = 0;
  }

  if (action === 'create') {
    item.storeInfo.guidMatch = true;
    item.storeInfo.tmpId = 'new' + item.guid;
  }
};

const deleteHelper = (Vue, arr, id) => {
  const index = arr.findIndex((x) => x.id === id);

  if (index > -1) {
    Vue.delete(arr, index);
  }
};

// Helper to correctly find the index whether entry was created offline or not
const getEntryIndex = (arr, item, storeInfo) => {
  if (!storeInfo.guidMatch) {
    return arr.findIndex((x) => x.id === item.id);
  }

  const index = arr.findIndex((x) => x.id === storeInfo.tmpId);

  return index > -1 ? index : arr.findIndex((x) => x.id === item.id);
};

// Set Transaction Helpers
const binEntries = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }

  const binId = storeInfo.binId;
  const bin = state.farm.bins.find((x) => x.id === binId);
  const deleteAction = storeInfo.action === 'deleteBinEntry';

  // A new bin has binBal of null
  bin.balance = storeInfo.binBal
    ? storeInfo.binBal.toString()
    : item.pounds.toString();

  const index = getEntryIndex(bin.deliveries, item, storeInfo);

  if (index !== -1) {
    if (deleteAction) {
      Vue.delete(bin.deliveries, index);
    } else {
      Vue.set(bin.deliveries, index, item);
    }
  }

  if (index === -1 && !deleteAction) {
    bin.deliveries.push(item);
  }

  bin.deliveries.sort((a, b) => b.date - a.date);
};

const feedContracts = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }

  const deleteAction =
    storeInfo.action === 'deleteContract' ||
    (storeInfo.action === 'updateContract' && item.date_closed !== null);

  const queriesDeleteAction = storeInfo.action === 'deleteContract';

  const index = getEntryIndex(state.farm.feedContracts, item, storeInfo);
  const queryIndex = getEntryIndex(
    state.dataQueries.feedContractsRecent,
    item,
    storeInfo
  );

  if (index !== -1) {
    if (deleteAction) {
      Vue.delete(state.farm.feedContracts, index);
    } else {
      Vue.set(state.farm.feedContracts, index, item);
    }
  }

  if (index === -1 && !deleteAction) {
    state.farm.feedContracts.push(item);
  }

  if (queryIndex !== -1) {
    if (queriesDeleteAction) {
      Vue.delete(state.dataQueries.feedContractsRecent, queryIndex);
    } else {
      Vue.set(state.dataQueries.feedContractsRecent, queryIndex, item);
    }
  }

  if (queryIndex === -1 && !deleteAction) {
    state.dataQueries.feedContractsRecent.push(item);
  }

  state.farm.feedContracts.sort((a, b) => b.date - a.date);
  state.dataQueries.feedContractsRecent.sort((a, b) => b.date - a.date);
};

const feederEntries = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }

  const feederId = storeInfo.feederId;
  const feeder = state.farm.feeders.find((x) => x.id === feederId);
  const deleteAction = storeInfo.action === 'deleteFeederEntry';

  // A new feeder has feederBal of null
  feeder.balance = storeInfo.feederBal
    ? storeInfo.feederBal.toString()
    : item.pounds.toString();

  const index = getEntryIndex(feeder.fillUps, item, storeInfo);

  if (index !== -1) {
    if (deleteAction) {
      Vue.delete(feeder.fillUps, index);
    } else {
      Vue.set(feeder.fillUps, index, item);
    }
  }

  if (index === -1 && !deleteAction) {
    feeder.fillUps.push(item);
  }

  feeder.fillUps.sort((a, b) => b.date - a.date);

  // Also update bin
  if (!storeInfo.reconcile) {
    const bin = state.farm.bins.find((x) => x.id === storeInfo.binId);
    const binEntryIndex = getEntryIndex(bin.withdrawals, item, storeInfo);

    bin.balance = storeInfo.binBal.toString();

    if (binEntryIndex !== -1) {
      if (deleteAction) {
        Vue.delete(bin.withdrawals, binEntryIndex);
      } else {
        Vue.set(bin.withdrawals, binEntryIndex, item);
      }
    }

    if (binEntryIndex === -1 && !deleteAction) {
      bin.withdrawals.push(item);
    }

    bin.withdrawals.sort((a, b) => b.date - a.date);
  }
};

const pondEntries = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }

  const pondId = storeInfo.pondId;
  const pond = state.farm.ponds.find((x) => x.id === pondId);
  const deleteAction = storeInfo.action === 'deletePondEntry';

  pond.growth = storeInfo.growth;

  const index = getEntryIndex(pond.feedings, item, storeInfo);

  if (index !== -1) {
    if (deleteAction) {
      Vue.delete(pond.feedings, index);
    } else {
      Vue.set(pond.feedings, index, item);
    }
  }

  if (index === -1 && !deleteAction) {
    pond.feedings.push(item);
  }

  pond.feedings.sort((a, b) => b.date - a.date);

  // Also update feeder
  const feeder = state.farm.feeders.find((x) => x.id === storeInfo.feederId);
  const feederEntryIndex = getEntryIndex(feeder.feedings, item, storeInfo);

  feeder.balance = storeInfo.feederBal ? storeInfo.feederBal.toString() : '0';

  if (feederEntryIndex !== -1) {
    if (deleteAction) {
      Vue.delete(feeder.feedings, feederEntryIndex);
    } else {
      Vue.set(feeder.feedings, feederEntryIndex, item);
    }
  }

  if (feederEntryIndex === -1 && !deleteAction) {
    feeder.feedings.push(item);
  }

  feeder.feedings.sort((a, b) => b.date - a.date);
};

const treatPond = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }

  const pondId = storeInfo.pondId;
  const pond = state.farm.ponds.find((x) => x.id === pondId);
  const deleteAction = storeInfo.action === 'deleteTreatPond';

  const index = getEntryIndex(pond.treatHistory, item, storeInfo);

  if (index !== -1) {
    if (deleteAction) {
      Vue.delete(pond.treatHistory, index);
    } else {
      Vue.set(pond.treatHistory, index, item);
    }
  }

  if (index === -1 && !deleteAction) {
    pond.treatHistory.push(item);
  }

  pond.treatHistory.sort((a, b) => b.date - a.date);
};

const oxygenPond = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }

  const pondId = storeInfo.pondId;
  const pond = state.farm.ponds.find((x) => x.id === pondId);
  const deleteAction = storeInfo.action === 'deleteOxygenPond';

  const index = getEntryIndex(pond.oxygenRecords, item, storeInfo);

  if (index !== -1) {
    if (deleteAction) {
      Vue.delete(pond.oxygenRecords, index);
    } else {
      Vue.set(pond.oxygenRecords, index, item);
    }
  }

  if (index === -1 && !deleteAction) {
    pond.oxygenRecords.push(item);
  }

  pond.oxygenRecords.sort((a, b) => b.date - a.date);
};

const flavorPond = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }

  const pondId = storeInfo.pondId;
  const pond = state.farm.ponds.find((x) => x.id === pondId);
  const deleteAction = storeInfo.action === 'deleteFlavorPond';

  const index = getEntryIndex(pond.flavorHistory, item, storeInfo);

  if (index !== -1) {
    if (deleteAction) {
      Vue.delete(pond.flavorHistory, index);
    } else {
      Vue.set(pond.flavorHistory, index, item);
    }
  }

  if (index === -1 && !deleteAction) {
    pond.flavorHistory.push(item);
  }

  pond.flavorHistory.sort((a, b) => b.date - a.date);
};

const dailyJournals = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }
};

const equipmentList = (item, state, Vue) => {
  const storeInfo = item.storeInfo;
  delete item.storeInfo;

  if (item.id === 0) {
    item.id = storeInfo.tmpId;
  }
};

const setStoreItem = {
  feedContracts,
  binEntries,
  feederEntries,
  pondEntries,
  treatPond,
  oxygenPond,
  flavorPond,
  dailyJournals,
  equipmentList
};

export {
  guidMatchHelper,
  deleteHelper,
  getEntryIndex,
  createTransactionObj,
  getUID,
  setStoreItem
};
