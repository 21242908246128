<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img src="/images/ponds.png" alt="ponds" width="100%" height="100%" />
      </div>
      <div class="q-pt-md">
        <q-toggle label="Display Archived" v-model="displayArchived"></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div style="overflow: auto">
      <div class="row no-wrap items-center q-py-xs">
        <div class="col q-pl-sm" style="min-width: 100px">Name</div>
        <div class="col q-pl-sm" style="min-width: 60px">Acres</div>
        <div class="col q-pl-sm" style="min-width: 60px">Average Depth</div>
        <div
          v-if="DisplayLocations"
          class="col q-pl-sm"
          style="min-width: 100px"
        >
          Location
        </div>
        <div class="col q-pl-sm" style="min-width: 80px">FCR</div>
        <div class="col q-pl-sm" style="min-width: 80px">TFA</div>
        <div
          v-if="DisplayPurchasers"
          class="col q-pl-sm"
          style="min-width: 140px"
        >
          Purchaser
        </div>
        <div
          class="col-auto"
          :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
        ></div>
      </div>

      <q-separator color="black" style="min-width: 632px" />

      <div v-for="pond in Ponds" :key="pond.id">
        <div class="row no-wrap items-center">
          <div
            class="col q-pl-sm"
            style="min-width: 100px; word-break: break-all"
          >
            {{ pond.name }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ parseFloat(pond.acres).toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ pond.avgdepth.toFixed(1) }}
          </div>
          <div
            v-if="DisplayLocations"
            class="col q-pl-sm"
            style="min-width: 100px; word-break: break-all"
          >
            {{ decodeLocation(pond.location_id) }}
          </div>
          <div
            class="col q-pl-sm"
            style="min-width: 80px; word-break: break-all"
          >
            {{ decodeFCR(pond.fcr_id) }}
          </div>
          <div
            class="col q-pl-sm"
            style="min-width: 80px; word-break: break-all"
          >
            {{ decodeTFA(pond.tfa_id) }}
          </div>
          <div
            v-if="DisplayPurchasers"
            class="col q-pl-sm"
            style="min-width: 140px; word-break: break-all"
          >
            {{ decoder(decodePurchaser(pond.purchaser)) }}
          </div>

          <div class="col-auto q-pa-sm">
            <q-btn
              unelevated
              icon="edit"
              size="md"
              padding="2px 10px"
              color="grey-3"
              text-color="primary"
              class="q-mr-sm"
              @click="editPond(pond)"
            >
            </q-btn>
            <q-btn
              v-if="!pond.archived && pond.feedings.length > 0"
              unelevated
              color="grey-3"
              text-color="accent"
              icon="archive"
              size="md"
              padding="2px 10px"
              @click="archivePond(pond, true)"
            />
            <q-btn
              v-if="pond.archived"
              unelevated
              icon="unarchive"
              size="md"
              color="grey-3"
              text-color="secondary"
              padding="2px 10px"
              @click="archivePond(pond, false)"
            />
            <q-btn
              v-if="!pond.archived && pond.feedings.length === 0"
              unelevated
              color="grey-3"
              text-color="accent"
              icon="delete"
              size="md"
              padding="2px 10px"
              @click="deletePond(pond)"
            />
          </div>
        </div>
        <q-separator style="min-width: 632px" />
      </div>
    </div>

    <!-- Dialog Pond Entry -->
    <q-dialog v-model="dialogPondEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Pond</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="pondObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />
          <q-input
            outlined
            v-model.number="pondObj.acres"
            label="Capacity"
            type="number"
            min="0"
            step="0.01"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => !!value || 'Please enter capacity']"
          />
          <q-input
            outlined
            v-model.number="pondObj.avgdepth"
            label="Average Depth"
            type="number"
            min="0"
            step="0.1"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => !!value || 'Please enter capacity']"
          />
          <q-select
            outlined
            v-if="Locations.length > 0"
            v-model="pondObj.location_id"
            :options="LocationOptions"
            emit-value
            map-options
            label="Location"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== null || 'Please select a location']"
          />
          <q-select
            outlined
            v-if="PurchaserOptions.length > 0"
            v-model="pondObj.purchaser"
            :options="PurchaserOptions"
            emit-value
            map-options
            label="Purchaser"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== null || 'Please select a purchaser']"
          />
          <q-select
            outlined
            v-model="pondObj.fcr_id"
            :options="FcrOptions"
            emit-value
            map-options
            label="FCR"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== null || 'Please select a FCR']"
          />
          <q-select
            outlined
            v-model="pondObj.tfa_id"
            :options="TfaOptions"
            emit-value
            map-options
            label="TFA"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== null || 'Please select a TFA']"
          />

          <div class="row justify-end">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newPond = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    acres: 0,
    active: true,
    avgdepth: 0,
    aerware_pond_id: null,
    grain_id: null,
    fish_id: null,
    fcr_id: null,
    tfa_id: null,
    name: '',
    capacity: null,
    location_id: null,
    archived: false,
    purchaser: null,
    feedings: [],
    flavorHistory: [],
    gps: null,
    growth: {},
    harvests: [],
    location_name: null,
    oxygenRecords: [],
    treatHistory: [],
    storeInfo: {}
  };
};

export default {
  name: 'Settings-Ponds',
  data() {
    return {
      decoder,
      dialogPondEntry: false,
      displayArchived: false,
      pondObj: newPond(),
      store
    };
  },
  methods: {
    add() {
      this.pondObj = newPond();

      this.dialogPondEntry = true;
    },
    archivePond(pond, value) {
      const pondCopy = cloneObj(pond);
      pondCopy.archived = value;

      pondCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive Pond',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this pond?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archivePondConfirmed(pondCopy);
        });
    },
    archivePondConfirmed(pond) {
      store
        .dispatch('updatePond', {
          pond
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    decodePurchaser(id) {
      return (
        this.$store.state.farm.purchasers.find((element) => element.id === id)
          ?.name ?? ''
      );
    },
    decodeTFA(id) {
      return (
        this.$store.state.farm.tfa.find((element) => element.id === id)?.name ??
        ''
      );
    },
    decodeFCR(id) {
      return (
        this.$store.state.farm.fcr.find((element) => element.id === id)?.name ??
        ''
      );
    },
    decodeLocation(id) {
      return (
        this.$store.getters.selectedFarm.locations.find(
          (element) => element.id === id
        )?.name ?? ''
      );
    },
    deletePond(pond) {
      pond.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete Pond',
          message: 'Are you sure you want to delete this pond?',
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'Cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deletePondConfirmed(pond);
        });
    },
    deletePondConfirmed(pond) {
      store
        .dispatch('deletePond', {
          pond
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editPond(pond) {
      this.pondObj = cloneObj(pond);
      this.pondObj.name = decoder(this.pondObj.name);
      this.pondObj.acres = Math.round(this.pondObj.acres * 100) / 100;
      this.pondObj.avgdepth = Math.round(this.pondObj.avgdepth * 10) / 10;

      this.dialogPondEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.pondObj.id === 0) {
        dispatch = 'createPond';
      } else {
        dispatch = 'updatePond';
      }

      this.pondObj.name = upperCaseFirst(this.pondObj.name);

      this.pondObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          pond: this.pondObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogPondEntry = false;
    }
  },
  computed: {
    DisplayLocations() {
      return this.Locations.length > 0;
    },
    DisplayPurchasers() {
      return this.$store.state.farm.purchasers.length > 0;
    },
    PurchaserOptions() {
      const purchasers = cloneObj(this.$store.state.farm.purchasers);
      for (const purchaser of purchasers) {
        purchaser.label = decoder(purchaser.name);
        purchaser.value = purchaser.id;
      }

      return purchasers;
    },
    FcrOptions() {
      const fcrs = cloneObj(this.$store.state.farm.fcr);
      for (const fcr of fcrs) {
        fcr.label = decoder(fcr.name);
        fcr.value = fcr.id;
      }

      return fcrs;
    },
    TfaOptions() {
      const tfa = cloneObj(this.$store.state.farm.tfa);
      for (const t of tfa) {
        t.label = decoder(t.name);
        t.value = t.id;
      }

      return tfa;
    },
    LocationOptions() {
      const locations = cloneObj(this.Locations);
      for (const location of locations) {
        location.label = decoder(location.name);
        location.value = location.id;
      }

      return locations;
    },
    Locations() {
      return this.$store.getters.selectedFarm?.locations ?? [];
    },
    Ponds() {
      let ponds = cloneObj(this.$store.state.farm.ponds);

      if (!this.displayArchived) {
        ponds = ponds.filter((element) => !element.archived);
      }

      return ponds.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
    }
  }
};
</script>

<style scoped></style>
