<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/feedingdays.png"
          alt="Feeding Days"
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Month</div>
      <div class="col q-pl-sm">Days</div>
    </div>

    <q-separator color="black" />

    <div v-for="(feedDay, i) in FeedingDays" :key="i">
      <div class="row items-center q-py-xs">
        <div class="col q-pl-sm">{{ feedDay.month }}</div>
        <div class="col q-pl-sm">
          {{ feedDay.days }}
        </div>
      </div>
      <q-separator />
    </div>

    <div class="row items-center q-py-xs text-weight-medium">
      <div class="col q-pl-sm">Total</div>
      <div class="col q-pl-sm">{{ TotalDays }}</div>
    </div>
    <q-separator />

    <!-- Dialog Enter Feed Type -->
    <q-dialog
      v-model="dialogFeedDaysEntry"
      full-height
      persistent
      position="top"
    >
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Feed Days</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <div
          class="scroll"
          :style="`max-height: ${store.state.pageHeight - 100}px`"
        >
          <q-form @submit="onSubmit" class="q-pa-md">
            <q-input
              v-for="(item, index) in monthNames"
              :key="index"
              outlined
              dense
              v-model.number="feedDaysObj[indexName(item)]"
              :label="item"
              type="number"
              min="0"
              max="31"
              class="q-mb-md"
            />

            <div class="row justify-end">
              <q-btn label="Submit" type="submit" color="primary" />
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newFeedDays = () => {
  return {
    farm_id: store.getters.selectedFarm.id,
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-FeedDays',
  data() {
    return {
      dialogFeedDaysEntry: false,
      feedDaysObj: newFeedDays(),
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      store
    };
  },
  methods: {
    edit() {
      this.feedDaysObj = cloneObj(this.$store.state.farm.feedDays);
      this.dialogFeedDaysEntry = true;
    },
    onSubmit() {
      this.feedDaysObj.farm_id = store.getters.selectedFarm.id;
      this.feedDaysObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch('updateFeedDays', {
          feedDays: this.feedDaysObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogFeedDaysEntry = false;
    },
    indexName(name) {
      return name ? name.substr(0, 3).toLowerCase() : '';
    }
  },
  computed: {
    FeedingDays() {
      let feedingDaysObj = cloneObj(this.$store.state.farm.feedDays);
      const feedingDays = [];

      if (feedingDaysObj === null) {
        return [];
      }

      for (let i = 0; i < this.monthNames.length; i++) {
        feedingDays.push({
          month: this.monthNames[i],
          days: feedingDaysObj[this.indexName(this.monthNames[i])]
        });
      }

      return feedingDays;
    },
    TotalDays() {
      let total = 0;
      for (const feedDay of this.FeedingDays) {
        total += feedDay.days;
      }
      return total;
    }
  }
};
</script>

<style scoped></style>
