<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <div>
          <div style="height: 64px">
            <div style="font-size: 28px; padding-top: 3px">Feeders</div>
          </div>

          <img
            src="/images/feeder-single.png"
            alt="Feeders"
            width="100px"
            height="50px"
          />
        </div>
      </div>
      <div class="q-pt-md">
        <q-toggle label="Display Archived" v-model="displayArchived"></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Name</div>
      <div class="col q-pl-sm">Capacity</div>
      <div
        class="col-auto"
        :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
      ></div>
    </div>

    <q-separator color="black" />

    <div v-for="feeder in Feeders" :key="feeder.id">
      <div class="row items-center">
        <div class="col q-pl-sm">{{ feeder.name }}</div>
        <div class="col q-pl-sm">
          {{ feeder.capacity }}
        </div>
        <div class="col-auto q-pa-sm">
          <q-btn
            unelevated
            icon="edit"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            color="grey-3"
            text-color="primary"
            class="q-mr-sm"
            @click="editFeeder(feeder)"
          >
          </q-btn>
          <q-btn
            v-if="
              !feeder.archived &&
              (feeder.feedings.length > 0 || feeder.fillUps.length > 0)
            "
            unelevated
            color="grey-3"
            text-color="accent"
            icon="archive"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveFeeder(feeder, true)"
          />
          <q-btn
            v-if="feeder.archived"
            unelevated
            icon="unarchive"
            size="md"
            color="grey-3"
            text-color="secondary"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveFeeder(feeder, false)"
          />
          <q-btn
            v-if="
              !feeder.archived &&
              feeder.feedings.length === 0 &&
              feeder.fillUps.length === 0
            "
            unelevated
            color="grey-3"
            text-color="accent"
            icon="delete"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="deleteFeeder(feeder)"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <!-- Dialog Enter Feeder -->
    <q-dialog v-model="dialogFeederEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Feeders</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="feederObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />
          <q-input
            outlined
            v-model="feederObj.capacity"
            label="Capacity"
            type="number"
            min="0"
            step="1"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Capacity cannot be blank']"
          />

          <div class="row justify-end">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newFeeder = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    grain_id: null,
    name: '',
    capacity: 0,
    balance: 0,
    feedings: [],
    fillUps: [],
    archived: false,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-Feeders',
  data() {
    return {
      dialogFeederEntry: false,
      displayArchived: false,
      feederObj: newFeeder()
    };
  },
  methods: {
    add() {
      this.feederObj = newFeeder();

      this.dialogFeederEntry = true;
    },
    archiveFeeder(feeder, value) {
      const feederCopy = cloneObj(feeder);
      feederCopy.archived = value;

      feederCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive Feeder',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this feeder?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archiveFeederConfirmed(feederCopy);
        });
    },
    archiveFeederConfirmed(feeder) {
      store
        .dispatch('updateFeeder', {
          feeder
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    deleteFeeder(feeder) {
      feeder.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete Feeder',
          message: `Are you sure you want to delete this feeder?`,
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteFeederConfirmed(feeder);
        });
    },
    deleteFeederConfirmed(feeder) {
      store
        .dispatch('deleteFeeder', {
          feeder
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editFeeder(feeder) {
      this.feederObj = cloneObj(feeder);
      this.feederObj.name = decoder(this.feederObj.name);
      this.dialogFeederEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.feederObj.id === 0) {
        dispatch = 'createFeeder';
      } else {
        dispatch = 'updateFeeder';
      }

      this.feederObj.name = upperCaseFirst(this.feederObj.name);

      this.feederObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          feeder: this.feederObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogFeederEntry = false;
    }
  },
  computed: {
    Feeders() {
      let feeders = cloneObj(this.$store.state.farm.feeders);
      if (!this.displayArchived) {
        feeders = feeders.filter((element) => !element.archived);
      }

      return feeders.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>

<style scoped></style>
