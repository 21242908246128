var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"height":"190px"}},[_vm._m(0),_c('div',{staticClass:"q-pt-md"},[_c('q-toggle',{attrs:{"label":"Display Archived"},model:{value:(_vm.displayArchived),callback:function ($$v) {_vm.displayArchived=$$v},expression:"displayArchived"}})],1)]),_c('q-separator',{attrs:{"color":"black"}}),_c('div',{staticClass:"row items-center q-py-xs"},[_c('div',{staticClass:"col q-pl-sm"},[_vm._v("Name")]),_c('div',{staticClass:"col q-pl-sm"},[_vm._v("Capacity")]),_c('div',{staticClass:"col-auto",style:(`width: ${_vm.$q.screen.xs ? '88' : '112'}px`)})]),_c('q-separator',{attrs:{"color":"black"}}),_vm._l((_vm.Feeders),function(feeder){return _c('div',{key:feeder.id},[_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"col q-pl-sm"},[_vm._v(_vm._s(feeder.name))]),_c('div',{staticClass:"col q-pl-sm"},[_vm._v(" "+_vm._s(feeder.capacity)+" ")]),_c('div',{staticClass:"col-auto q-pa-sm"},[_c('q-btn',{staticClass:"q-mr-sm",attrs:{"unelevated":"","icon":"edit","size":"md","padding":_vm.$q.screen.xs ? '2px 4px' : '2px 10px',"color":"grey-3","text-color":"primary"},on:{"click":function($event){return _vm.editFeeder(feeder)}}}),(
            !feeder.archived &&
            (feeder.feedings.length > 0 || feeder.fillUps.length > 0)
          )?_c('q-btn',{attrs:{"unelevated":"","color":"grey-3","text-color":"accent","icon":"archive","size":"md","padding":_vm.$q.screen.xs ? '2px 4px' : '2px 10px'},on:{"click":function($event){return _vm.archiveFeeder(feeder, true)}}}):_vm._e(),(feeder.archived)?_c('q-btn',{attrs:{"unelevated":"","icon":"unarchive","size":"md","color":"grey-3","text-color":"secondary","padding":_vm.$q.screen.xs ? '2px 4px' : '2px 10px'},on:{"click":function($event){return _vm.archiveFeeder(feeder, false)}}}):_vm._e(),(
            !feeder.archived &&
            feeder.feedings.length === 0 &&
            feeder.fillUps.length === 0
          )?_c('q-btn',{attrs:{"unelevated":"","color":"grey-3","text-color":"accent","icon":"delete","size":"md","padding":_vm.$q.screen.xs ? '2px 4px' : '2px 10px'},on:{"click":function($event){return _vm.deleteFeeder(feeder)}}}):_vm._e()],1)]),_c('q-separator')],1)}),_c('q-dialog',{attrs:{"persistent":"","position":"top"},model:{value:(_vm.dialogFeederEntry),callback:function ($$v) {_vm.dialogFeederEntry=$$v},expression:"dialogFeederEntry"}},[_c('q-card',{staticStyle:{"width":"400px"}},[_c('div',{staticClass:"row items-center q-pa-md"},[_c('div',{staticClass:"col text-center text-h5"},[_vm._v("Feeders")]),_c('div',{staticClass:"col-auto"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","color":"primary"}})],1)]),_c('q-separator'),_c('q-form',{staticClass:"q-pa-md",on:{"submit":_vm.onSubmit}},[_c('q-input',{staticClass:"q-mb-md",attrs:{"outlined":"","label":"Name","maxlength":"32","hide-bottom-space":"","rules":[(value) => value !== '' || 'Name cannot be blank']},model:{value:(_vm.feederObj.name),callback:function ($$v) {_vm.$set(_vm.feederObj, "name", $$v)},expression:"feederObj.name"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"outlined":"","label":"Capacity","type":"number","min":"0","step":"1","hide-bottom-space":"","rules":[(value) => value !== '' || 'Capacity cannot be blank']},model:{value:(_vm.feederObj.capacity),callback:function ($$v) {_vm.$set(_vm.feederObj, "capacity", $$v)},expression:"feederObj.capacity"}}),_c('div',{staticClass:"row justify-end"},[_c('q-btn',{attrs:{"label":"Submit","type":"submit","color":"primary"}})],1)],1)],1)],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashIcon"},[_c('div',[_c('div',{staticStyle:{"height":"64px"}},[_c('div',{staticStyle:{"font-size":"28px","padding-top":"3px"}},[_vm._v("Feeders")])]),_c('img',{attrs:{"src":"/images/feeder-single.png","alt":"Feeders","width":"100px","height":"50px"}})])])
}]

export { render, staticRenderFns }