var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"rounded-borders",style:(`
      width: ${_vm.size}px;
      height: ${_vm.size}px;
      padding: ${_vm.padding};
      border: 3px solid #757575;
      background: linear-gradient(${_vm.gradient});
    `)},[_c('q-img',{style:(`height: ${_vm.size - 14}px; max-width: ${_vm.size - 14}px`),attrs:{"src":`images/${_vm.iconSrc}`,"spinner-color":"white","contain":""}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }