<template>
  <div>
    <div
      class="rounded-borders"
      :style="`
        width: ${size}px;
        height: ${size}px;
        padding: ${padding};
        border: 3px solid #757575;
        background: linear-gradient(${gradient});
      `"
    >
      <q-img
        :src="`images/${iconSrc}`"
        spinner-color="white"
        contain
        :style="`height: ${size - 14}px; max-width: ${size - 14}px`"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MasterIcon',
  props: {
    percent: {
      type: Number,
      required: true
    },
    iconSrc: {
      type: String,
      required: true
    },
    padding: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    }
  },
  computed: {
    gradient() {
      const percent = this.percent;
      const accent = '#245AD3';

      return `to top, ${accent} ${percent}%, #BDBDBD ${percent}%, #BDBDBD 100%`;
    }
  }
};
</script>

<style></style>
