<template>
  <q-dialog
    v-model="dialogAttachments"
    persistent
    :maximized="true"
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card>
      <div class="row items-center justify-between q-pa-sm">
        <div class="q-pl-xs">
          <q-btn
            unelevated
            label="Back"
            color="primary"
            icon="arrow_back"
            @click="$emit('closeAttachments')"
            v-close-popup
          />
        </div>

        <div>
          <q-btn
            flat
            color="primary"
            padding="6px 8px"
            icon="aspect_ratio"
            class="q-mr-xs"
          >
            <q-menu
              square
              anchor="bottom right"
              self="top right"
              :offset="[95, 9]"
            >
              <q-item clickable>
                <q-item-section>
                  <q-toggle
                    v-model="displayCompressed"
                    label="Compressed Images"
                    color="primary"
                    size="sm"
                    v-close-popup
                  />
                </q-item-section>
              </q-item>
            </q-menu>
          </q-btn>

          <q-btn
            flat
            :icon="modes.edit ? 'edit_off' : 'edit'"
            :class="!IsStaticFolder && 'q-mr-xs'"
            padding="6px 8px"
            color="primary"
            @click="modes.edit = !modes.edit"
          />

          <q-btn
            v-if="!IsStaticFolder"
            flat
            color="primary"
            padding="6px 8px"
            icon="add"
            @click="addMode"
          />
        </div>
      </div>
      <q-separator />

      <div
        style="height: calc(100vh - 60px); scrollbar-gutter: stable"
        class="scroll q-pt-lg q-px-sm"
      >
        <div class="row full-width justify-center">
          <div :style="$q.screen.xs ? 'width: 96vh' : 'width: 700px'">
            <div v-if="!modes.add" class="col row flex-center">
              <q-icon name="folder" size="40px" color="primary" />
              <div class="text-h6 ellipsis q-pl-md">
                <span v-if="this.parentType !== 'farmFolders'">
                  Attachments
                </span>
                <span v-else class="q-pr-sm">
                  {{ this.parentObj.folder_name }}
                </span>
              </div>
            </div>
            <div
              v-if="!modes.add"
              style="height: 6px; border-radius: 5px"
              class="bg-grey-3"
            ></div>

            <div v-if="modes.loading" class="q-py-xs">
              <q-linear-progress
                indeterminate
                rounded
                color="primary"
                size="md"
              />
            </div>

            <div v-if="modes.uploadProgress" class="q-py-xs">
              <q-linear-progress
                rounded
                :value="uploadProgress"
                color="primary"
                size="md"
              />
            </div>

            <div v-if="modes.add">
              <div>
                <q-checkbox
                  v-model="add.useImageName"
                  dense
                  label="Use Image Name"
                  class="text-primary q-mr-md"
                  color="primary"
                />
                <q-checkbox
                  v-if="IsAdmin"
                  v-model="add.adminRestricted"
                  dense
                  label="Viewable by Admins Only"
                  color="accent"
                  class="text-accent"
                />
              </div>

              <div class="q-mt-sm">
                <q-file
                  v-model="image"
                  outlined
                  color="primary"
                  label-color="primary"
                  label="Select Image"
                  accept=".jpg, .jpeg, .png, .svg, .pdf, image/*"
                  @rejected="onRejected"
                />
                <span v-if="add.selectAttachmentErr" class="text-red-14"
                  >Please Select Image!</span
                >
                <q-input
                  v-if="!add.useImageName"
                  outlined
                  v-model="add.name"
                  label="Image Name (optional)"
                  color="primary"
                  label-color="primary"
                  class="q-mt-sm"
                />
              </div>

              <div align="right" class="q-mt-sm">
                <q-btn
                  unelevated
                  label="Cancel"
                  color="grey-3"
                  text-color="black"
                  class="q-mr-sm"
                  @click="modes.add = false"
                />
                <q-btn
                  unelevated
                  label="Upload"
                  color="primary"
                  @click="uploadImage"
                />
              </div>
            </div>
          </div>

          <div
            v-if="!modes.add && !modes.loading"
            class="row full-width justify-center"
            style="padding-top: 40px"
          >
            <div v-if="!images.length && !pdfs.length" class="q-mt-lg">
              <div class="text-primary text-center" style="font-size: 27px">
                No Attachments
              </div>
            </div>

            <div
              v-for="pdf in pdfs"
              :key="pdf.img_url"
              class="full-width row justify-center"
              style="padding-bottom: 80px"
            >
              <div
                v-if="
                  pdf.admin_restricted === 0 ||
                  (IsAdmin && pdf.admin_restricted === 1)
                "
                class="row full-width"
                style="max-width: 700px"
              >
                <div class="row full-width">
                  <div class="row items-center full-width q-mb-sm">
                    <div class="row items-center text-primary">
                      <q-btn
                        unelevated
                        round
                        icon="chevron_left"
                        color="grey-3"
                        text-color="primary"
                        padding="0px"
                        size="md"
                        :disable="pdf.currentPage === 1"
                        @click="pdfBack(pdf)"
                      />
                      <div class="q-px-sm">
                        {{ pdf.currentPage }} / {{ pdf.pageCount }}
                      </div>
                      <q-btn
                        unelevated
                        round
                        icon="chevron_right"
                        color="grey-3"
                        text-color="primary"
                        padding="0px"
                        size="md"
                        :disable="pdf.currentPage === pdf.pageCount"
                        @click="pdfNext(pdf)"
                      />
                    </div>

                    <div
                      v-if="pdf.admin_restricted === 1"
                      class="text-caption text-accent q-px-xs"
                    >
                      Restricted to Admins
                    </div>

                    <q-space />

                    <div v-if="modes.edit">
                      <q-btn
                        unelevated
                        color="primary"
                        padding="2px 10px"
                        size="md"
                        @click="editAttachment(pdf)"
                      >
                        <div class="row flex-center">
                          <q-icon name="edit" size="18px" class="q-mr-sm" />
                          Edit
                        </div>
                      </q-btn>
                      <q-btn
                        v-if="!IsStaticFolder"
                        unelevated
                        color="accent"
                        padding="2px 10px"
                        size="md"
                        class="q-ml-sm"
                        @click="deleteAttachment(pdf)"
                      >
                        <div class="row flex-center">
                          <q-icon name="delete" size="18px" class="q-mr-sm" />
                          Delete
                        </div>
                      </q-btn>
                    </div>
                    <div v-if="!modes.edit">
                      <q-btn
                        outline
                        label="New Tab"
                        icon="open_in_new"
                        color="primary"
                        padding="2px 10px"
                        size="md"
                        @click="openInNewTab(pdf.img_url)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="pdf.img_name.length > 1 || IsStaticFolder"
                  class="row justify-between full-width rounded-borders text-h6 text-bold text-primary q-pl-sm"
                  style="border: solid #245ad3 1px"
                >
                  <div class="col" style="word-break: break-all">
                    {{ pdf.img_name }}
                  </div>
                  <div
                    class="col-auto row justify-end text-accent q-pr-sm"
                    style="width: 155px; min-height: 28px"
                  >
                    <span v-if="folderType === 'fishMoveFolder'">
                      {{ pdf.pondName }}
                    </span>
                    <span v-if="folderType === 'binFolder'">
                      {{ pdf.binName }}
                    </span>
                  </div>
                </div>

                <div
                  class="row items-center justify-end full-width q-my-xs"
                ></div>
                <pdf
                  :src="pdf.img_url"
                  :page="pdf.currentPage"
                  style="width: 100%; border: solid 1px #eeeeee"
                  class="rounded-borders"
                  @num-pages="pdf.pageCount = $event"
                />

                <div class="row full-width text-caption">
                  <div class="text-primary">
                    {{ parseDateTime(pdf.upload_date) }}
                  </div>
                  <q-space />
                  <div class="text-primary">
                    {{ userName(pdf.uploaded_by) }}
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="image in images"
              :key="image.img_key"
              class="full-width row justify-center"
              style="padding-bottom: 80px"
            >
              <div
                v-if="
                  image.admin_restricted === 0 ||
                  (IsAdmin && image.admin_restricted === 1)
                "
                class="row full-width"
                style="max-width: 700px"
              >
                <div class="row full-width">
                  <div class="row items-end full-width q-mb-sm">
                    <div
                      v-if="image.admin_restricted === 1"
                      class="text-caption text-accent q-pr-xs"
                    >
                      Restricted to Admins
                    </div>
                    <q-space />

                    <div v-if="modes.edit">
                      <q-btn
                        unelevated
                        color="primary"
                        padding="2px 10px"
                        size="md"
                        @click="editAttachment(image)"
                      >
                        <div class="row flex-center">
                          <q-icon name="edit" size="18px" class="q-mr-sm" />
                          Edit
                        </div>
                      </q-btn>
                      <q-btn
                        v-if="!IsStaticFolder"
                        unelevated
                        color="accent"
                        size="md"
                        class="q-ml-sm"
                        padding="2px 10px"
                        @click="deleteAttachment(image)"
                      >
                        <div class="row flex-center">
                          <q-icon name="delete" size="18px" class="q-mr-sm" />
                          Delete
                        </div>
                      </q-btn>
                    </div>

                    <div v-if="!modes.edit">
                      <q-btn
                        outline
                        label="New Tab"
                        icon="open_in_new"
                        color="primary"
                        padding="2px 10px"
                        size="md"
                        @click="openInNewTab(image.img_url)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="image.img_name.length > 1 || IsStaticFolder"
                  class="row justify-between full-width rounded-borders text-h6 text-bold text-primary q-pl-sm q-mb-sm"
                  style="border: solid #245ad3 1px"
                >
                  <div
                    class="col"
                    style="word-break: break-all; min-height: 28px"
                  >
                    {{ image.img_name }}
                  </div>
                  <div
                    class="col-auto row justify-end text-accent q-pr-sm"
                    style="width: 155px"
                  >
                    <span v-if="folderType === 'fishMoveFolder'">
                      {{ image.pondName }}
                    </span>
                    <span v-if="folderType === 'binFolder'">
                      {{ image.binName }}
                    </span>
                  </div>
                </div>

                <q-img
                  :src="
                    displayCompressed ? image.compressed_url : image.img_url
                  "
                  :native-context-menu="true"
                  spinner-color="primary"
                  style="border: solid 1px #eeeeee"
                  class="rounded-borders"
                />

                <div class="row full-width text-caption">
                  <div class="text-primary">
                    {{ parseDateTime(image.upload_date) }}
                  </div>
                  <q-space />
                  <div class="text-primary">
                    {{ userName(image.uploaded_by) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <q-dialog v-model="edit.display" persistent>
            <q-card class="q-pa-lg" style="width: 480px">
              <div class="row items-center">
                <div v-if="!IsStaticFolder" class="full-width">
                  <q-btn
                    unelevated
                    :label="
                      attachment.compressed_key
                        ? 'Move to top of images'
                        : 'Move to top of PDFs'
                    "
                    icon="arrow_upward"
                    color="primary"
                    class="full-width"
                    @click="moveToTop"
                  />
                  <div
                    v-if="attachment.compressed_key && pdfs.length > 0"
                    class="text-center text-caption text-primary"
                  >
                    (PDFs will still be on top)
                  </div>
                </div>

                <div
                  class="full-width q-pb-md"
                  :class="!IsStaticFolder && 'q-pt-md'"
                >
                  <div v-if="IsAdmin" class="q-pb-xs">
                    <q-checkbox
                      v-model="edit.adminRestricted"
                      dense
                      label="Viewable by Admins Only"
                      color="red-14"
                    />
                  </div>

                  <q-input
                    outlined
                    v-model="edit.attachmentName"
                    label="Edit Image Name"
                    class="q-mt-sm"
                    color="primary"
                    label-color="primary"
                  />
                </div>
              </div>

              <div class="row justify-end">
                <q-btn
                  unelevated
                  label="Cancel"
                  color="grey-3"
                  text-color="black"
                  class="q-mr-sm"
                  @click="edit.display = false"
                />
                <q-btn
                  unelevated
                  label="Update"
                  color="primary"
                  @click="submitUpdate"
                />
              </div>
            </q-card>
          </q-dialog>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from 'axios';
import pdf from 'vue-pdf';
import store from '@/store';
import { cloneObj, parseDateTime, userName } from '@/lib/helpers';

export default {
  name: 'ManageAttachement',
  components: {
    pdf
    //ConfirmDialog,
  },
  props: {
    fishmoveId: {
      type: Number
    },
    pondId: {
      type: Number
    },
    binGrainId: {
      type: Number
    },
    transactionId: {
      type: Number
    },
    dailyJournalId: {
      type: Number
    },
    folderId: {
      type: Number
    },
    folderType: {
      type: String,
      default: 'noFolder'
    },
    parentObj: {
      type: Object,
      required: true
    },
    parentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      add: {
        name: '',
        adminRestricted: false,
        selectAttachmentErr: false,
        useImageName: false
      },
      attachment: {},
      displayCompressed: false,
      dialogAttachments: true,
      edit: {
        display: false,
        adminRestricted: false,
        attachmentName: ''
      },
      modes: {
        add: false,
        edit: false,
        uploadProgress: false,
        loading: false
      },
      image: null,
      images: [],
      parseDateTime,
      pdfs: [],
      uploadProgress: 0,
      userName
    };
  },
  mounted() {
    this.setAttachments();
  },
  methods: {
    addMode() {
      this.image = null;
      this.add.name = '';
      this.add.adminRestricted = false;
      this.add.selectAttachmentErr = false;
      this.modes.edit = false;
      this.modes.add = true;
    },
    deleteAttachment(attachment) {
      this.$q
        .dialog({
          title: 'Delete Attachment',
          message: 'Are you sure you want to permanently delete attachment?',
          ok: {
            unelevated: true,
            icon: 'delete',
            color: 'accent',
            label: 'Delete'
          },
          cancel: {
            unelevated: true,
            color: 'grey-3',
            textColor: 'black',
            label: 'Cancel'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteAttachmentConfirmed(attachment);
        });
    },
    async deleteAttachmentConfirmed(attachment) {
      this.modes.loading = true;

      const updateObj = {
        ...attachment,
        storeInfo: {
          action: 'deleteAttachment',
          parentType: this.parentType,
          farmId: store.state.selectedFarm.farm_id,
          binGrainId: this.binGrainId || null,
          pondId: this.pondId || null,
          fishmoveId: this.fishmoveId || null,
          dailyJournalId: this.dailyJournalId || null,
          folderId: this.folderId || null
        }
      };

      const serverRes = await store.dispatch('publishAttachment', updateObj);

      if (serverRes) {
        this.$q.notify({
          color: 'negative',
          message: 'Attachment Deleted'
        });
      } else {
        this.$q.notify({
          color: 'negative',
          message: 'Error Processing Entry'
        });
      }

      this.modes.loading = false;
      this.modes.edit = false;

      if (this.parentType === 'binDelivery') {
        this.$emit('requeryDates');
      }
    },
    editAttachment(attachment) {
      this.attachment = attachment;
      this.edit.attachmentName = attachment.img_name;
      this.edit.adminRestricted =
        attachment.admin_restricted === 0 ? false : true;
      this.edit.display = true;
    },
    moveToTop() {
      this.attachment.sort_date = Math.floor(new Date().getTime() / 1000);

      this.submitUpdate();
    },
    onRejected(err) {
      console.error(err);
    },
    openInNewTab(url) {
      window.open(url);
    },
    pdfBack(pdf) {
      pdf.currentPage--;
    },
    pdfNext(pdf) {
      pdf.currentPage++;
    },
    async setAttachments() {
      const attachments = cloneObj(this.parentObj.attachments);
      const images = [];
      const pdfs = [];

      const prevPdfs = JSON.parse(JSON.stringify(this.pdfs));

      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        // if null then it is a pdf
        if (attachment.is_pdf === 1) {
          const prev = prevPdfs.find((x) => x.img_url === attachment.img_url);

          if (prev) {
            attachment.currentPage = prev.currentPage;
            attachment.pageCount = prev.pageCount;
          } else {
            attachment.currentPage = 1;
            attachment.pageCount = 1;
          }

          pdfs.push(attachment);
        } else {
          images.push(attachment);
        }
      }

      pdfs.sort((a, b) => b.sort_date - a.sort_date);
      this.pdfs = pdfs;

      images.sort((a, b) => b.sort_date - a.sort_date);
      this.images = images;
    },
    async submitUpdate() {
      this.edit.display = false;
      this.modes.loading = true;

      let attachmentName = this.edit.attachmentName;
      attachmentName = attachmentName.trim();
      attachmentName = attachmentName.replaceAll('.', '');
      attachmentName =
        attachmentName.charAt(0).toUpperCase() + attachmentName.slice(1);

      this.attachment.img_name = attachmentName;
      this.attachment.admin_restricted = this.edit.adminRestricted ? 1 : 0;

      const attachment = cloneObj(this.attachment);

      const updateObj = {
        ...attachment,
        storeInfo: {
          action: 'updateAttachment',
          parentType: this.parentType,
          farmId: store.state.selectedFarm.farm_id,
          pondId: this.pondId || null,
          binGrainId: this.parentObj.to_account || null,
          fishmoveId: this.fishmoveId || null,
          dailyJournalId: this.dailyJournalId || null,
          folderId: this.folderId || null
        }
      };

      const serverRes = await store.dispatch('publishAttachment', updateObj);

      if (serverRes) {
        this.$q.notify({
          color: 'primary',
          message: 'Attachment Updated'
        });
      } else {
        this.$q.notify({
          color: 'negative',
          message: 'Error Processing Entry'
        });
      }

      this.modes.loading = false;
      this.modes.edit = false;
    },
    async uploadImage() {
      if (this.image === null) {
        this.add.selectAttachmentErr = true;
        return;
      }

      this.modes.add = false;

      let attachmentName = this.add.useImageName
        ? this.image.name
        : this.add.name;
      attachmentName = attachmentName.trim();
      attachmentName = attachmentName.replaceAll('.', '');
      attachmentName =
        attachmentName.charAt(0).toUpperCase() + attachmentName.slice(1);

      const attachment = {
        farmId: this.FarmId,
        pondId: this.pondId || null,
        fishmoveId: this.fishmoveId || null,
        dailyJournalId: this.dailyJournalId || null,
        transactionId: this.transactionId || null,
        folderId: this.folderId || null,
        name: attachmentName,
        uploadDate: Math.floor(new Date().getTime() / 1000),
        uploadedBy: this.CurrentUser.user_id,
        adminRestricted: this.add.adminRestricted ? 1 : 0,
        storeInfo: {
          parentType: this.parentType,
          pondId: this.pondId || null,
          binGrainId: this.parentObj.to_account || null,
          dailyJournalId: this.dailyJournalId || null,
          farmId: this.FarmId
        }
      };

      const formData = new FormData();
      formData.append('file', this.image);

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = progressEvent.loaded / progressEvent.total;
        }
      };

      this.modes.uploadProgress = true;
      let upload;

      // formData.append('item', JSON.stringify(this.parentObj));
      formData.append('attachment', JSON.stringify(attachment));
      // formData.append('parentType', this.parentType);

      upload = await axios
        .post('/api/upload/attachments/', formData, config)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return { data: 'Error uploading file' };
        });

      this.modes.uploadProgress = false;

      if (upload.data === 'Upload Was Successful') {
        this.$q.notify({
          message: 'Attachment Uploaded',
          icon: 'photo_library',
          color: 'primary'
        });
      } else if (upload.data === 'Error uploading file') {
        this.$q.dialog({
          title: 'Error',
          message: 'Error uploading file ;)'
        });
      } else if (upload.data === 'No files were uploaded') {
        this.$q.dialog({
          title: 'Error',
          message: 'No files were uploaded'
        });
      }

      if (this.parentType === 'binDelivery') {
        this.$emit('requeryDates');
      }
    }
  },
  computed: {
    FarmId() {
      return this.$store.state.farm.id;
    },
    IsAdmin() {
      return this.$store.state.user.feedx_level === 'super-admin';
    },
    IsStaticFolder() {
      return this.folderType !== 'userFolder' && this.folderType !== 'noFolder';
    },
    CurrentUser() {
      return this.$store.state.user;
    }
  },
  watch: {
    parentObj: {
      deep: true,
      handler() {
        this.setAttachments();
      }
    }
  }
};
</script>

<style scoped></style>
