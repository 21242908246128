<template>
  <q-card
    class="row justify-center items-center"
    :class="$q.screen.gt.xs ? 'q-pa-lg' : 'q-pa-md'"
  >
    <div :style="$q.screen.gt.xs ? 'width: 420px;' : 'width: 288px'">
      <div>
        <q-date
          flat
          bordered
          :minimal="$q.screen.xs"
          text-color="white"
          v-model="dateRange"
          color="primary"
          range
          :landscape="$q.screen.gt.xs"
          @input="dateChange"
        />
      </div>
      <div v-if="selectDateNotice" class="text-accent text-center">
        Please Select Date Range
      </div>

      <q-card
        flat
        bordered
        class="row justify-center q-pa-sm q-mt-md"
        :style="$q.screen.gt.xs ? 'width: 420px;' : 'width: 288px'"
      >
        <div class="col-12 text-body1 text-bold text-center">
          Quick Select
          <q-separator class="q-mx-sm" />
        </div>
        <div class="row">
          <div class="row col-sm-6 col-xs-12">
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="prev7"
              label="Previous 7 Days"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="prev30"
              label="Previous 30 Days"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="prev90"
              label="Previous 90 Days"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="prev365"
              label="Previous 365 Days"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
          </div>
          <div class="row col-sm-6 col-xs-12">
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="currMo"
              label="Current Month"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="currYear"
              label="Current Year"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="LCM"
              label="Last Calendar Month"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
            <q-radio
              dense
              v-model="dateRangeSelected"
              val="LCY"
              label="Last Calendar Year"
              color="primary"
              class="full-width q-pl-sm"
              :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
              @input="setDateRanges"
            />
          </div>
        </div>
      </q-card>

      <div class="row full-width q-mt-md">
        <q-space />
        <q-btn
          unelevated
          label="Cancel"
          color="grey-4"
          text-color="black"
          class="q-mr-sm"
          v-close-popup
        />
        <q-btn
          unelevated
          label="Select Dates"
          color="primary"
          @click="selectDates()"
        />
      </div>
    </div>
  </q-card>
</template>

<script>
import { date } from 'quasar';

export default {
  name: 'DatePicker',
  props: {
    dateRangeSelection: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateRange: {
        from: '',
        to: ''
      },
      dateRangeSelected: this.dateRangeSelection,
      selectDateNotice: false
    };
  },
  mounted() {
    this.setDateRanges();
  },
  methods: {
    selectDates() {
      if (this.dateRangeSelected === 'all') {
        const dateRange = {
          to: '',
          from: '',
          dateRangeSelected: this.dateRangeSelected
        };

        this.$emit('datesSelected', dateRange);
        return;
      }

      if (!this.dateRange?.to || !this.dateRange?.from) {
        this.selectDateNotice = true;
        return;
      }

      const fromValid = this.isValidDate(this.dateRange.from);
      const toValid = this.isValidDate(this.dateRange.to);

      if (!(fromValid && toValid)) {
        this.selectDateNotice = true;
        return;
      }

      const dateRange = {
        to: this.dateRange.to,
        from: this.dateRange.from,
        dateRangeSelected: this.dateRangeSelected
      };

      this.$emit('datesSelected', dateRange);
    },
    setDateRanges() {
      if (this.dateRangeSelected === 'all') {
        this.dateRange = '';
      } else if (this.dateRangeSelected === 'prev90') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 90 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'prev7') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 6 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'prev30') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 29 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'prev365') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 364 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'currMo') {
        const thisMo = date.endOfDate(new Date(), 'month');
        const thisMoStart = date.startOfDate(thisMo, 'month');
        const to = date.formatDate(thisMo, 'YYYY/MM/DD');
        const from = date.formatDate(thisMoStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'currYear') {
        const thisYear = date.endOfDate(new Date(), 'year');
        const thisYearStart = date.startOfDate(thisYear, 'year');
        const to = date.formatDate(thisYear, 'YYYY/MM/DD');
        const from = date.formatDate(thisYearStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'LCM') {
        const lastMo = date.subtractFromDate(new Date(), { months: 1 });
        const lastMoStart = date.startOfDate(lastMo, 'month');
        const lastMoEnd = date.endOfDate(lastMo, 'month');
        const to = date.formatDate(lastMoEnd, 'YYYY/MM/DD');
        const from = date.formatDate(lastMoStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'LCY') {
        const lastYear = date.subtractFromDate(new Date(), { years: 1 });
        const lastYearStart = date.startOfDate(lastYear, 'year');
        const lastYearEnd = date.endOfDate(lastYear, 'year');
        const to = date.formatDate(lastYearEnd, 'YYYY/MM/DD');
        const from = date.formatDate(lastYearStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      }

      this.selectDateNotice = false;
    },
    dateChange() {
      this.dateRangeSelected = '';
    },
    isValidDate(d) {
      let invalidDate = false;
      const dateArr = d.split('/');

      if (dateArr.length < 3) {
        return false;
      }

      if (dateArr[0].length !== 4) {
        invalidDate = true;
      }
      if (dateArr[1].length !== 2) {
        invalidDate = true;
      }
      if (dateArr[2].length !== 2) {
        invalidDate = true;
      }

      const valDate = date.isValid(new Date(d));
      if (!valDate) {
        invalidDate = true;
      }

      return !invalidDate;
    }
  }
};
</script>

<style scoped></style>
