import date from 'quasar/src/utils/date.js';;

const formatDate = function (date, format) {
  // 2014-12-30 04:00:00
  let value = ('0' + (date.getMonth() + 1)).slice(-2) + '/';
  value += ('0' + date.getDate()).slice(-2) + '/';
  if (format === 'shortExcel') {
    return value + date.getFullYear();
  }
  value += (' ' + date.getFullYear()).slice(-2) + ' ';
  if (format === 'short') {
    return value;
  }
  value += ('0' + date.getHours()).slice(-2) + ':';
  value += ('0' + date.getMinutes()).slice(-2);
  return value;
};

const parseTimeStamp = (value, optFormat) => {
  return formatDate(new Date(value * 1000), optFormat);
};

const isDateValid = (val) => {
  if (!val) {
    return false;
  }

  const isNumeric = (x) => /\d/.test(x);
  const dateArr = val.split('/');
  const correctLen =
    dateArr.length === 3 &&
    dateArr[0].length === 2 &&
    dateArr[1].length === 2 &&
    dateArr[2].length === 4;

  let allNums = true;
  for (const dateStr of dateArr) {
    for (const digit of dateStr) {
      if (!isNumeric(parseInt(digit))) {
        allNums = false;
      }
    }
  }

  return date.isValid(val) && correctLen && allNums;
};

// current time of whatever date is selected
// keeps sort order correct
const setTimeOfDay = (dateStr) => {
  const timeStr = new Date().toLocaleTimeString();
  return parseInt(date.formatDate(new Date(dateStr + ' ' + timeStr), 'X'));
};

export { isDateValid, parseTimeStamp, setTimeOfDay };
