<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img src="/images/users.png" alt="Users" width="100%" height="100%" />
      </div>
      <div :class="$q.screen.xs ? 'q-pt-lg' : 'q-pt-md'">
        <q-toggle
          :dense="$q.screen.xs"
          label="Display Inactive"
          v-model="displayInactive"
        ></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div style="overflow: auto">
      <div class="row no-wrap items-center q-py-xs">
        <div class="col q-pl-sm" style="min-width: 120px">Display Name</div>
        <div class="col q-pl-sm" style="min-width: 120px">User Name</div>
        <div class="col q-pl-sm" style="min-width: 120px">Email</div>
        <div class="col q-pl-sm" style="min-width: 120px">Role</div>
        <div class="col-auto q-pl-sm" style="width: 60px">Access</div>
      </div>
      <q-separator color="black" style="min-width: 620px" />

      <div v-for="user in Users" :key="user.id">
        <div class="row no-wrap items-center q-py-xs">
          <div
            class="col q-pl-sm"
            style="min-width: 120px; word-break: break-all"
          >
            {{ decoder(user.display_name) }}
          </div>
          <div
            class="col q-pl-sm"
            style="min-width: 120px; word-break: break-all"
          >
            {{ decoder(user.user_name) }}
          </div>
          <div
            class="col q-pl-sm"
            style="min-width: 200px; word-break: break-all"
          >
            {{ decoder(user.user_email) }}
          </div>
          <div
            class="col q-pl-sm"
            style="min-width: 120px; word-break: break-all"
          >
            {{ roleDecoder[user.user_role] }}
          </div>
          <div class="col-auto q-pa-sm" style="width: 60px">
            <q-icon
              name="done"
              :color="user.acct_active ? 'primary' : 'grey-5'"
              size="24px"
            />
          </div>
        </div>
        <q-separator style="min-width: 620px" />
      </div>
    </div>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const roleDecoder = {
  'farm-visitor': 'Farm Visitor',
  'farm-worker': 'Farm Worker',
  'acct-manager': 'Account manager',
  'acct-owner': 'Account owner',
  installer: 'Installer',
  'super-admin': 'Super admin'
};

export default {
  name: 'Settings-HarvestSize',
  data() {
    return {
      decoder,
      dialogHarvestSizeEntry: false,
      displayInactive: false,
      harvestSize: 0.0,
      roleDecoder,
      store
    };
  },
  methods: {},
  computed: {
    Users() {
      let users = cloneObj(this.$store.state.farm.users);

      if (!this.displayInactive) {
        users = users.filter((element) => element.acct_active);
      }

      return users.sort(function (a, b) {
        return a.display_name.localeCompare(b.display_name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
    }
  }
};
</script>

<style scoped></style>
