<template>
  <div>
    <q-dialog v-model="checkMark" persistent>
      <q-avatar
        icon="check_circle_outline"
        text-color="primary"
        size="12rem"
        style="box-shadow: none !important"
      />
    </q-dialog>

    <q-dialog v-model="retryModal" persistent>
      <q-card style="min-width: 300px; max-width: 450px">
        <q-card-section class="row items-center text-subtitle1 text-bold">
          <div>
            <q-icon name="error" color="negative" size="sm" />
            <span class="q-ml-sm">Error: {{ message }}</span>
          </div>
        </q-card-section>
        <q-separator />
        <div class="row justify-end q-pa-md">
          <q-btn
            label="Cancel"
            color="grey-2"
            text-color="primary"
            v-close-popup
            class="q-mr-sm"
            @click="confirmCancel"
          />
          <q-btn
            label="Retry"
            color="primary"
            v-close-popup
            @click="confirmRetry"
          />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import FinishModal from './FinishResult';
import { Loading, QSpinnerBars } from 'quasar';

export default {
  name: 'FinishResult',
  data() {
    return {
      checkMark: false,
      retryModal: false,
      onRetry: null,
      onFail: null,
      message: ''
    };
  },
  methods: {
    confirmCancel() {
      this.retryModal = false;
      this.onFail();
    },
    confirmRetry() {
      this.retryModal = false;
      this.onRetry();
    },
    show() {
      Loading.show({
        spinner: QSpinnerBars,
        spinnerColor: 'primary',
        messageColor: 'primary',
        message: 'Saving changes'
      });
    },
    handleResultsAsync(results, resolve, reject) {
      this.onFail = function () {
        resolve('fail');
      };
      this.onRetry = function () {
        resolve('retry');
      };
      if (results.sent === false || results.reasonCode !== 0) {
        this.message = results.error;
        this.retryModal = true;
        Loading.hide();
      } else {
        if (!results.disableCheck) {
          this.checkMark = true;
          Loading.hide();
          setTimeout(
            () => {
              this.checkMark = false;
              Loading.hide();
              resolve('success');
            },
            500,
            this
          );
        }
      }
    },
    handleResults(results, onSuccess, onRetry, onFail) {
      this.onRetry = onRetry;
      this.onFail = onFail;
      if (results.sent === false) {
        this.message = results.error;
        this.retryModal = true;
        Loading.hide();
      } else {
        if (!results.disableCheck) {
          this.checkMark = true;
          Loading.hide();
          setTimeout(
            () => {
              this.checkMark = false;
              Loading.hide();
              onSuccess();
            },
            500,
            this
          );
        }
      }
    }
  },
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    FinishModal.EventBus.$on('show', (params) => {
      this.show(params);
    });
    FinishModal.EventBus.$on(
      'handleResults',
      (results, onSuccess, onRetry, onFail) => {
        this.handleResults(results, onSuccess, onRetry, onFail);
      }
    );
    FinishModal.EventBus.$on(
      'handleResultsAsync',
      (results, resolve, reject) => {
        this.handleResultsAsync(results, resolve, reject);
      }
    );
  }
};
</script>

<style scoped></style>
