<template>
  <div>
    <div v-if="IsAdmin || IsAccountOwner" class="q-pb-md">
      <q-btn-toggle
        v-model="toggleSpot"
        spread
        toggle-color="primary"
        :padding="$q.screen.xs ? '6px 4px' : '6px lg'"
        :options="[
          { label: 'Spot', value: 'spot' },
          { label: 'Contract', value: 'contract' }
        ]"
        @input="spotToggled"
      />
    </div>

    <q-form @submit="submitEntry">
      <div class="q-pb-sm">
        <q-input
          filled
          dense
          label="Date"
          v-model="transaction.date"
          lazy-rules
          :rules="[(val) => isDateValid(val) || 'Invalid Date']"
          hide-bottom-space
        >
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer" color="primary">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date v-model="transaction.date" mask="MM/DD/YYYY">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>

      <div v-if="toggleSpot === 'contract'" class="q-pb-sm">
        <q-select
          v-model="transaction.feed_contract_id"
          label="Select Contract"
          dense
          filled
          :options="ContractOptions"
          emit-value
          map-options
          lazy-rules
          hide-bottom-space
          :rules="[(val) => !!val || 'Please Select A Contract']"
        />
      </div>

      <div v-if="toggleSpot === 'spot'" class="q-pb-sm">
        <q-select
          v-model="transaction.from_account"
          label="Select Mill"
          dense
          filled
          :options="MillOptions"
          emit-value
          map-options
          lazy-rules
          hide-bottom-space
          :rules="[(val) => !!val || 'Please Select A Mill']"
        />
      </div>

      <div class="row items-center q-pb-sm">
        <div class="col">
          <q-input
            filled
            dense
            v-model.number="transaction.pounds"
            label="Lbs"
            step="any"
            min="0"
            mask="#####"
            type="number"
            @input="setTransactionTons()"
            lazy-rules
            hide-bottom-space
            :rules="[(val) => isNumValid(val) || '']"
          />
        </div>

        <div class="col-auto q-px-sm">or</div>

        <div class="col">
          <q-input
            filled
            dense
            v-model.number="transactionTons"
            label="Tons"
            step="any"
            min="0"
            mask="#####"
            type="number"
            @input="setTransactionLbs()"
            lazy-rules
            hide-bottom-space
            :rules="[(val) => isNumValid(val) || '']"
          />
        </div>
      </div>

      <div class="q-pb-sm">
        <q-select
          v-model="transaction.feed_type"
          label="Select Feed Type"
          dense
          filled
          :options="FeedTypes"
          emit-value
          map-options
          lazy-rules
          hide-bottom-space
          :rules="[(val) => !!val || 'Please Select A Feed Type']"
        />
      </div>

      <div v-if="Purchasers.length > 0" class="q-pb-sm">
        <q-select
          v-model="transaction.purchaser"
          label="Select Purchaser"
          dense
          filled
          :options="Purchasers"
          emit-value
          map-options
          lazy-rules
          hide-bottom-space
          :rules="[(val) => !!val || 'Please Select A Purchaser']"
        />
      </div>

      <div class="row items-center q-pb-sm">
        <div class="col">
          <q-input
            filled
            dense
            v-model.number="transaction.price_per_pound"
            label="Price Per Lb"
            step="any"
            min="0"
            mask="#####"
            type="number"
            prefix="$"
            @input="setTransactionPricePerTon()"
            lazy-rules
            hide-bottom-space
            :rules="[(val) => validatePrice(val) || '']"
          />
        </div>

        <div class="col-auto q-px-sm">or</div>

        <div class="col">
          <q-input
            filled
            dense
            v-model.number="transactionPricePerTon"
            label="Price Per Ton"
            step="any"
            min="0"
            mask="#####"
            type="number"
            prefix="$"
            @input="setTransactionPricePerLb()"
            lazy-rules
            hide-bottom-space
            :rules="[(val) => validatePrice(val) || '']"
          />
        </div>
      </div>

      <div class="q-pb-sm">
        <q-input
          filled
          dense
          autogrow
          v-model="transaction.memo"
          label="Note"
        />
      </div>

      <div class="row justify-end q-pt-xs">
        <q-btn
          unelevated
          label="Cancel"
          color="grey-4"
          text-color="black"
          size="md"
          class="q-mr-sm"
          v-close-popup
        />
        <q-btn
          unelevated
          v-if="action === 'edit'"
          label="Delete"
          color="grey-4"
          text-color="accent"
          size="md"
          class="q-mr-sm"
          @click="deleteEntryConfirmA"
        />
        <q-btn
          unelevated
          :label="action === 'edit' && !$q.screen.xs ? 'Submit Edit' : 'Submit'"
          color="primary"
          size="md"
          type="submit"
        />
      </div>
    </q-form>
  </div>
</template>

<script>
import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage
} from '@/lib/helpers';
import {
  createTransactionObj,
  getUID,
  guidMatchHelper
} from '@/store/transactionHelpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date } from 'quasar';
import AuthUtils from '@/lib/auth-utils';

export default {
  name: 'FillBin',
  props: {
    action: {
      type: String,
      required: true
    },
    bin: {
      type: Object,
      required: true
    },
    dateCopy: {
      type: Number,
      default: null
    },
    dateSearch: {
      type: Boolean,
      default: false
    },
    datesSelectedCopy: {
      type: Object,
      default: null
    },
    transactionObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      btnWidth: 36,
      customPadding: 14,
      dateRangeSelection: 'prev90',
      dialogDates: false,
      dateCopyStr: null,
      isDateValid,
      isNumValid,
      numberWithCommas,
      reconcileAdd: true,
      poundsCopy: null,
      store,
      toggle: 'deliveries',
      toggleSpot: 'spot',
      transaction: {},
      transactionTons: null,
      transactionPricePerTon: null,
      usableHeight: 1000
    };
  },
  mounted() {
    this.transaction = cloneObj(this.transactionObj);

    if (this.action === 'create') {
      this.transaction.pounds = null;
    }

    if (this.transaction.feed_contract_id) {
      this.toggleSpot = 'contract';
    }

    if (this.action === 'edit') {
      this.editEntry();
    }
  },
  methods: {
    deleteEntryConfirmA() {
      if (this.transaction.attachments.length > 0) {
        this.$q.dialog({
          title: 'Attachments Not Deleted',
          message: `This entry has attachments. Attachments must be
              deleted before the journal entry can be deleted.`,
          ok: {
            label: 'OK',
            color: 'primary'
          },
          focus: 'none',
          persistent: true
        });

        return;
      }

      this.deleteEntryConfirmB();
    },
    deleteEntryConfirmB() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete this entry?',
          ok: {
            unelevated: true,
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            unelevated: true,
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.deleteEntryConfirmed();
        });
    },
    deleteEntryConfirmed() {
      const transaction = cloneObj(this.transaction);
      transaction.storeInfo.action = 'deleteBinEntry';
      transaction.storeInfo.category = 'binEntries';
      transaction.storeInfo.binId = this.bin.id;
      transaction.storeInfo.binGrainId = this.bin.grain_id;

      transaction.storeInfo.binBal =
        this.dialogReconcile && !this.reconcileAdd
          ? parseFloat(this.bin.balance) + transaction.pounds
          : parseFloat(this.bin.balance) - transaction.pounds;

      guidMatchHelper(transaction, this.action);

      store.dispatch('publish', transaction);

      this.$q.notify({
        message: 'Bin Entry Deleted',
        color: 'accent',
        icon: 'delete'
      });

      if (this.dateSearch) {
        setTimeout(() => {
          this.$emit('datesSelected', this.datesSelectedCopy);
          this.$emit('close');
        }, 300);
        return;
      }

      this.$emit('close');
    },
    editEntry() {
      this.transactionTons =
        Math.round((this.transaction.pounds / 2000) * 100) / 100;

      this.transactionPricePerTon =
        !this.transaction.price_per_pound ||
        this.transaction.price_per_pound === ''
          ? null
          : Math.round(this.transaction.price_per_pound * 2000 * 100) / 100;

      this.dateCopyStr = this.transaction.date;
      this.poundsCopy = parseFloat(this.transaction.pounds);

      if (this.transaction.feed_contract_id) {
        this.toggleSpot = 'contract';
      } else {
        this.toggleSpot = 'spot';
      }
    },
    setTransactionLbs() {
      this.transaction.pounds = this.transactionTons * 2000;
    },
    setTransactionTons() {
      this.transactionTons =
        Math.round((this.transaction.pounds / 2000) * 100) / 100;
    },
    setTransactionPricePerLb() {
      this.transaction.price_per_pound =
        !this.transactionPricePerTon || this.transactionPricePerTon === ''
          ? null
          : Math.round((this.transactionPricePerTon / 2000) * 1000) / 1000;
    },
    setTransactionPricePerTon() {
      this.transactionPricePerTon =
        !this.transaction.price_per_pound ||
        this.transaction.price_per_pound === ''
          ? null
          : this.transaction.price_per_pound * 2000;
    },
    spotToggled() {
      // We need to reset these values when toggled
      this.transaction.contract_date = null;
      this.transaction.contract_mill_name = null;
      this.transaction.contract_notes = null;
      this.transaction.contract_price_per_ton = null;
      this.transaction.contract_tons = null;
      this.transaction.feed_contract_id = null;
      this.transaction.mill_name = null;
    },
    submitEntry() {
      const transaction = cloneObj(this.transaction);

      const dateStr = this.transaction.date;
      transaction.date = setTimeOfDay(dateStr);

      if (this.action === 'edit' && this.dateCopyStr === dateStr) {
        transaction.date = this.dateCopy;
      }

      if (this.action === 'create') {
        transaction.id = 0;
      }

      if (!this.dialogReconcile && this.toggleSpot === 'contract') {
        transaction.from_account = this.ContractOptions.find(
          (x) => x.value === transaction.feed_contract_id
        ).grainId;
      }

      transaction.to_account = this.bin.grain_id;

      if (this.dialogReconcile) {
        const grainId = this.$store.state.farm.settings.loss.grain_id;

        if (this.reconcileAdd) {
          transaction.from_account = grainId;
        }

        if (!this.reconcileAdd) {
          transaction.to_account = grainId;
          transaction.from_account = this.bin.grain_id;
        }

        transaction.feed_type = null;
        transaction.feed_type_name = null;
      }

      this.submitEntryHelper(transaction);
      store.dispatch('publish', transaction);

      this.$q.notify({
        message: `Bin Entry ${this.action === 'create' ? 'Added' : 'Updated'}`,
        icon: 'check',
        color: 'primary'
      });

      if (this.dateSearch) {
        setTimeout(() => {
          this.$emit('datesSelected', this.datesSelectedCopy);
          this.$emit('close');
        }, 300);
        return;
      }

      this.$emit('close');
    },
    submitEntryHelper(transaction) {
      // This helper adds information necessary for the submit process
      // but is not part of the transaction table
      // Some of this information injected here is normally provided by
      // left joins on the server, but we push this update to state
      // immediately so we can accomodate offline entries
      const bin = this.bin;

      transaction.storeInfo.action =
        this.action === 'create' ? 'createBinEntry' : 'updateBinEntry';
      transaction.storeInfo.binBal =
        parseFloat(bin.balance) + (transaction.pounds - this.poundsCopy);
      transaction.storeInfo.category = 'binEntries';
      transaction.storeInfo.binId = bin.id;
      transaction.storeInfo.binGrainId = bin.grain_id;

      guidMatchHelper(transaction, this.action);

      if (this.action === 'create') {
        const feedType = this.FeedTypes.find(
          (x) => x.value === transaction.feed_type
        );

        transaction.feed_type = feedType.value;
        transaction.feed_type_name = feedType.label;
      }

      if (this.action === 'create' && this.toggleSpot === 'contract') {
        const contract = this.ContractOptions.find(
          (x) => x.value === transaction.feed_contract_id
        );

        transaction.mill_name = contract.millName;
        transaction.contract_date = contract.date;
        transaction.contract_mill_name = contract.millName;
        transaction.contract_notes = contract.notes;
        transaction.contract_price_per_ton = contract.pricePerTon;
        transaction.contract_tons = contract.tons;
      }

      if (this.action === 'create' && this.toggleSpot === 'spot') {
        const mill = this.MillOptions.find(
          (x) => x.value === transaction.from_account
        );

        transaction.mill_name = mill.label;
        transaction.contract_date = null;
        transaction.contract_mill_name = null;
        transaction.contract_notes = null;
        transaction.contract_price_per_ton = null;
        transaction.contract_tons = null;
      }
    },
    validatePrice(val) {
      return (val && isNumValid(val)) || !val;
    }
  },
  computed: {
    BinOptions() {
      const bins = store.state.farm.bins.filter((x) => !x.archived);

      const options = [];
      for (const bin of bins) {
        // get the feed type of the bin - which is the feed type of the latest delivery
        let feedTypeId = null;
        let feedTypeName = '---';
        for (const entry of bin.deliveries) {
          if (entry.mill_name) {
            feedTypeId = entry.feed_type;
            feedTypeName = entry.feed_type_name;
            break;
          }
        }

        options.push({
          id: bin.id,
          balance: bin.balance,
          name: bin.name,
          label: bin.name + ' -- ' + feedTypeName,
          value: bin.grain_id,
          grain_id: bin.grain_id,
          feedTypeId,
          feedTypeName
        });
      }

      return options;
    },
    ContractOptions() {
      const options = [];
      for (const contract of store.state.farm.feedContracts) {
        const contractDate = date.formatDate(
          contract.date * 1000,
          'MM/DD/YYYY'
        );
        const tons = parseFloat(contract.tons).toFixed(2);
        const label = `
          ${contractDate} - ${contract.mill_name} - ${tons} tons @ $${contract.price_per_ton}
        `;

        options.push({
          label,
          value: contract.id,
          grainId: contract.grain_id,
          millName: contract.mill_name,
          tons: contract.tons,
          pricePerTon: contract.price_per_ton,
          notes: contract.notes,
          date: contract.date
        });
      }

      const hasOption = options.some(
        (x) => x.value === this.transaction.feed_contract_id
      );

      // If the transaction has a contract that is not in the options, add it
      if (
        this.action === 'edit' &&
        this.transaction.feed_contract_id &&
        !hasOption
      ) {
        const contractDate = date.formatDate(
          this.transaction.contract_date * 1000,
          'MM/DD/YYYY'
        );
        const tons = parseFloat(this.transaction.contract_tons).toFixed(2);
        const millName = this.transaction.contract_mill_name;
        const pricePerTon = this.transaction.contract_price_per_ton;
        const label = `
          ${contractDate} - ${millName} - ${tons} tons @ $${pricePerTon}
        `;

        options.push({
          label,
          value: this.transaction.feed_contract_id,
          grainId: this.transaction.from_account,
          millName,
          tons,
          pricePerTon,
          notes: this.transaction.contract_notes,
          date: this.transaction.contract_date
        });
      }

      return options;
    },
    FeedTypes() {
      const feedTypes = [];
      for (const feedType of store.state.farm.feedTypes) {
        if (!feedType.archived) {
          feedTypes.push({
            label: feedType.name,
            value: feedType.id
          });
        }
      }
      return feedTypes;
    },
    IsAccountOwner() {
      const userRole = AuthUtils.roleDecoder(this.$store.getters.userFarmRole);
      return userRole === 'Account owner';
    },
    IsAdmin() {
      return this.$store.state.user.feedx_level === 'super-admin';
    },
    MillOptions() {
      const options = [];
      for (const mill of store.state.farm.mills) {
        options.push({
          label: mill.name,
          value: mill.grain_id
        });
      }

      return options;
    },
    Purchasers() {
      const purchasers = [];
      for (const purchaser of store.state.farm.purchasers) {
        purchasers.push({
          label: purchaser.name,
          value: purchaser.id
        });
      }

      return purchasers;
    }
  }
};
</script>

<style></style>
