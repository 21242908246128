<template>
  <div>
    <q-resize-observer @resize="onResize" />

    <div :style="`height: ${useableHeight}px`" class="scroll">
      <div class="row justify-center q-px-md q-pt-lg">
        <div
          class="col-12 row items-center text-subtitle2 text-bold q-pt-md"
          style="max-width: 600px"
        >
          <div class="col-12 row items-center q-py-xs">
            <div class="col-7 row">
              <div class="col q-pl-sm">Pond</div>
              <div class="col-7 row no-wrap">
                <div class="col-6 q-pl-sm">Last</div>
                <div class="col-auto q-pl-sm">Current</div>
              </div>
            </div>
          </div>

          <q-separator class="full-width" color="black" />
        </div>
      </div>

      <div id="ponds-container" class="scroll q-px-md q-pb-xl">
        <div class="row justify-center">
          <div
            v-for="pond in Ponds"
            :key="pond.name"
            class="col-12 row justify-center"
          >
            <div style="width: 600px">
              <FlavorPondRow :pond="pond" />
              <q-separator />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlavorPondRow from '@/components/ponds/FlavorPondRow.vue';
import { smartCompare } from '@/lib/helpers';

export default {
  name: 'TreatEntries',
  components: {
    FlavorPondRow
  },
  data() {
    return {
      useableHeight: 500
    };
  },
  methods: {
    onResize() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.useableHeight = height - 50;

      this.resizeWatcher = !this.resizeWatcher;
    }
  },
  computed: {
    Ponds() {
      const singleLocation = this.SelectedLocation === 'SINGLE_LOCATION';

      return this.$store.state.farm.ponds
        .filter(
          (x) =>
            (x.location_name === this.SelectedLocation || singleLocation) &&
            !x.archived
        )
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    }
  }
};
</script>

<style></style>
