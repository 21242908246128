<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/feedtypes.png"
          alt="FeedTypes"
          width="100%"
          height="100%"
        />
      </div>
      <div class="q-pt-md">
        <q-toggle label="Display Archived" v-model="displayArchived"></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Name</div>
      <div class="col q-pl-sm">Order</div>
      <div
        class="col-auto"
        :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
      ></div>
    </div>

    <q-separator color="black" />

    <div v-for="type in FeedTypes" :key="type.id">
      <div class="row items-center">
        <div class="col q-pl-sm">{{ type.name }}</div>
        <div class="col q-pl-sm">
          {{ type.order }}
        </div>
        <div class="col-auto q-pa-sm">
          <q-btn
            unelevated
            icon="edit"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            color="grey-3"
            text-color="primary"
            class="q-mr-sm"
            @click="editFeedType(type)"
          >
          </q-btn>
          <q-btn
            v-if="!type.archived && type.transactions > 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="archive"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveFeedType(type, true)"
          />
          <q-btn
            v-if="type.archived"
            unelevated
            icon="unarchive"
            size="md"
            color="grey-3"
            text-color="secondary"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveFeedType(type, false)"
          />
          <q-btn
            v-if="!type.archived && type.transactions === 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="delete"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="deleteFeedType(type)"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <!-- Dialog Enter Feed Type -->
    <q-dialog v-model="dialogFeedTypeEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Feed Type</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="feedTypeObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />
          <q-input
            outlined
            v-model="feedTypeObj.order"
            label="Order"
            type="number"
            min="0"
            step="1"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Order cannot be blank']"
          />

          <div class="row justify-end">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newFeedType = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    name: '',
    order: 0,
    archived: false,
    transactions: 0,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-FeedTypes',
  data() {
    return {
      dialogFeedTypeEntry: false,
      displayArchived: false,
      feedTypeObj: newFeedType()
    };
  },
  methods: {
    add() {
      this.feedTypeObj = newFeedType();

      this.dialogFeedTypeEntry = true;
    },
    archiveFeedType(feedType, value) {
      const feedTypeCopy = cloneObj(feedType);
      feedTypeCopy.archived = value;

      feedTypeCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive Feed Type',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this feed type?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archiveFeedTypeConfirmed(feedTypeCopy);
        });
    },
    archiveFeedTypeConfirmed(feedType) {
      store
        .dispatch('updateFeedType', {
          feedType
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    deleteFeedType(feedType) {
      feedType.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete Feed Type',
          message: `Are you sure you want to delete this feed type?`,
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteFeedTypeConfirmed(feedType);
        });
    },
    deleteFeedTypeConfirmed(feedType) {
      store
        .dispatch('deleteFeedType', {
          feedType
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editFeedType(feedType) {
      this.feedTypeObj = cloneObj(feedType);
      this.feedTypeObj.name = decoder(this.feedTypeObj.name);
      this.dialogFeedTypeEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.feedTypeObj.id === 0) {
        dispatch = 'createFeedType';
      } else {
        dispatch = 'updateFeedType';
      }

      this.feedTypeObj.name = upperCaseFirst(this.feedTypeObj.name);

      this.feedTypeObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          feedType: this.feedTypeObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogFeedTypeEntry = false;
    }
  },
  computed: {
    FeedTypes() {
      let feedTypes = cloneObj(this.$store.state.farm.feedTypes);
      if (!this.displayArchived) {
        feedTypes = feedTypes.filter((element) => !element.archived);
      }

      return feedTypes.sort(function (a, b) {
        return a.order - b.order;
      });
    }
  }
};
</script>

<style scoped></style>
