/* eslint-disable quotes */
const FarmRoles = {
  /* Highest Level-4 */
  ACCT_OWNER: 'acct-owner',
  /* High Level-3 */
  ACCT_MANAGER: 'acct-manager',
  /* Low Level-2 */
  FARM_VISITOR: 'farm-visitor',
  /* Lowest Level-1 */
  FARM_WORKER: 'farm-worker',
  /* SQL helper */
  FIELD_LIST: `'acct-owner','acct-manager','farm-visitor','farm-worker'`,
  /* Reverse lookup table, least to greatest */
  MAP: ['none', 'farm-worker', 'farm-visitor', 'acct-manager', 'acct-owner']
};

module.exports = FarmRoles;
