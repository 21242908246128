<template>
  <div>
    <div id="adjustable" class="bg-grey-1 shadow-2">
      <q-resize-observer @resize="onResize" />

      <div class="row justify-between items-center no-wrap">
        <div class="q-pa-sm" :style="$q.screen.gt.xs && 'width: 174px'">
          <q-btn
            icon="arrow_back"
            :label="$q.screen.gt.xs ? 'Overview' : ''"
            color="primary"
            size="md"
            @click="$emit('back')"
          />
        </div>

        <div class="q-px-md">
          <div
            class="q-px-md text-center"
            :class="$q.screen.xs ? 'text-subtitle1' : 'text-h6'"
          >
            {{ pond.name }}
          </div>

          <div class="row justify-center q-pb-sm">
            <q-btn
              v-if="goToPond.previous || goToPond.next"
              color="primary"
              icon="chevron_left"
              padding="1px 22px"
              size="md"
              class="q-mr-xs"
              :disabled="!goToPond.previous"
              @click="$emit('goToPrevious')"
            />

            <q-btn
              v-if="goToPond.previous || goToPond.next"
              color="primary"
              icon="chevron_right"
              padding="1px 22px"
              size="md"
              class="q-ml-xs"
              :disabled="!goToPond.next"
              @click="$emit('goToNext')"
            />
          </div>
        </div>

        <div class="q-pa-sm">
          <q-btn
            :label="$q.screen.gt.xs ? 'New Flavor' : ''"
            color="primary"
            icon="add"
            size="md"
            @click="createFlavorPond"
          />
        </div>
      </div>

      <q-separator />
      <div class="row text-bold q-py-xs" :class="$q.screen.gt.xs && 'q-px-sm'">
        <div class="col-auto" style="width: 70px; padding-left: 20px">Date</div>
        <div class="col col-sm-3 text-center">Processor</div>
        <div class="col col-sm-3 text-center">Flavor</div>
        <div class="col-auto text-center" style="width: 60px">Severity</div>
        <div class="gt-xs col text-center">Note</div>
        <div class="gt-xs" style="width: 52px"></div>
        <div class="lt-sm" style="width: 28px"></div>
      </div>
    </div>

    <div
      v-if="History.length < 1"
      class="text-center text-h5 text-grey-6 q-px-md q-py-lg"
    >
      No Entries For This Pond
    </div>

    <q-virtual-scroll
      v-if="History.length"
      :style="`height: ${usableHeight}px`"
      :items="History"
      separator
    >
      <template v-slot="{ item }">
        <div
          class="row items-center q-py-sm"
          :class="$q.screen.gt.xs && 'q-px-sm'"
        >
          <div class="col-auto q-pl-sm" style="width: 70px">
            {{ parseTimeStamp(item.date, 'short') }}
          </div>

          <div class="col col-sm-3 text-center" style="word-wrap: break-word">
            {{ decoder(item.processor) ?? '---' }}
          </div>

          <div class="col col-sm-3 text-center" style="word-wrap: break-word">
            {{ decoder(item.flavor_name) ?? '---' }}
          </div>

          <div
            class="col-auto text-center"
            style="word-wrap: break-word; width: 60px"
          >
            {{ item.severity }}
          </div>

          <div class="gt-xs col text-center">
            {{ item.memo }}
          </div>

          <div class="gt-xs q-pr-sm">
            <q-btn
              unelevated
              icon="edit"
              color="grey-3"
              text-color="accent"
              padding="2px 10px"
              @click="editFlavor(item)"
            />
          </div>

          <div class="lt-sm q-pr-sm">
            <q-btn
              outline
              icon="more_vert"
              color="black"
              padding="1px 0px"
              @click="displayHistoryItem(item)"
            >
            </q-btn>
          </div>
        </div>
        <q-separator />
      </template>
    </q-virtual-scroll>

    <!-- Line Entry Dialog -->
    <q-dialog position="right" full-hight v-model="dialogHistory">
      <q-card style="width: min(400px, 90vw)">
        <div class="row items-center q-pa-sm">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ parseTimeStamp(historyObj?.date || '', 'short') }}
            </div>
          </div>
        </div>
        <q-separator style="padding-top 1px" />

        <div>
          <div class="row justify-between q-pa-sm">
            <div>Processor</div>
            <div>
              {{ decoder(historyObj.processor) ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-between q-pa-sm">
            <div>Flavor</div>
            <div>
              {{ decoder(historyObj.flavor_name) ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-between q-pa-sm">
            <div>Severity</div>
            <div>
              {{ historyObj.severity ?? '' }}
            </div>
          </div>
          <q-separator />

          <div class="row items-center q-pa-sm">
            <div class="text-weight-medium q-pr-sm" style="padding-bottom: 2px">
              Note:
            </div>
            <div>
              {{ decoder(historyObj.memo) }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-end q-py-sm q-pr-sm">
            <q-btn
              unelevated
              label="Close"
              color="grey-4"
              text-color="black"
              class="q-mr-sm"
              v-close-popup
            />

            <div v-if="historyObj.transfer_type !== 'Transfer In'">
              <q-btn
                unelevated
                label="Edit"
                color="grey-4"
                text-color="accent"
                @click="editFlavor(historyObj)"
              />
            </div>
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- Flavor Entry Dialog -->
    <q-dialog position="top" v-model="dialogFlavor">
      <q-card class="q-pa-md" style="width: min(400px, 90vw)">
        <q-form @submit="submitFlavor">
          <div class="q-pb-sm">
            <q-input
              filled
              dense
              label="Date"
              v-model="flavor.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="flavor.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="q-pb-sm">
            <q-select
              v-model="flavor.proc_id"
              label="Select Processor"
              dense
              filled
              :options="ProcessorOptions"
              emit-value
              map-options
              :disable="action === 'edit'"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Processor']"
            />
          </div>

          <div class="q-pb-sm">
            <q-select
              v-model="flavor.flavor"
              label="Select Flavor"
              dense
              filled
              :options="FlavorOptions"
              emit-value
              map-options
              :disable="action === 'edit'"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Flavor']"
            />
          </div>

          <!-- <div class="q-pb-sm"> -->
          <!--   <q-input -->
          <!--     filled -->
          <!--     dense -->
          <!--     v-model.number="flavor.severity" -->
          <!--     label="Severity" -->
          <!--     step="any" -->
          <!--     min="0" -->
          <!--     mask="#####" -->
          <!--     type="number" -->
          <!--     lazy-rules -->
          <!--     hide-bottom-space -->
          <!--     :rules="[(val) => isNumValid(val) || val === 0 || '']" -->
          <!--   /> -->
          <!-- </div> -->
          <!--  -->
          <div class="q-pb-sm">
            <q-input filled dense autogrow v-model="flavor.memo" label="Note" />
          </div>

          <div class="q-pt-xs">
            <div style="padding: 4px 12px 0 12px">
              <q-slider
                dense
                v-model="flavor.severity"
                :min="0"
                :max="5"
                markers
                :step="1"
                snap
                color="accent"
                track-size="10px"
                thumb-size="30px"
              />
            </div>
            <div class="row justify-center text-subtitle1 q-pb-xs">
              Severity - {{ flavor.severity }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-end q-pt-md">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              unelevated
              v-if="action === 'update'"
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteFlavor"
            />
            <q-btn
              unelevated
              :label="
                action === 'edit' && !$q.screen.xs ? 'Submit Edit' : 'Submit'
              "
              color="primary"
              size="md"
              type="submit"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- Notes Dialog -->
    <q-dialog position="right" full-hight v-model="dialogNote">
      <q-card style="width: 300px">
        <div class="row items-center q-pa-sm">
          <div class="col">
            <div class="text-center text-subtitle1">
              {{ parseTimeStamp(noteDate || '', 'short') }}
            </div>
          </div>
          <q-btn
            unelevated
            icon="close"
            color="grey-3"
            text-color="black"
            size="md"
            padding="2px 10px"
            v-close-popup
          />
        </div>
        <q-separator style="padding-top 1px" />

        <div class="q-pa-sm">
          <span class="text-subtitle2 q-pr-sm"> Note: </span>
          {{ decoder(note) }}
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { parseTimeStamp } from '@/lib/date-utils.js';
import { getUID, guidMatchHelper } from '@/store/transactionHelpers';
import store from '@/store';
import {
  decoder,
  numberWithCommas,
  isNumValid,
  cloneObj,
  getPercentage,
  getScrollbarWidth
} from '@/lib/helpers';
import { isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { date, uid } from 'quasar';

function defaultFlavor() {
  return {
    id: null,
    user_id: store.state.user.id,
    proc_id: null,
    flavor: null,
    severity: 0,
    date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    memo: '',
    guid: null,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id
    }
  };
}

export default {
  name: 'PondEntries',
  props: {
    pond: {
      type: Object,
      required: true
    },
    goToPond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: 'create',
      dateCopy: null,
      dateCopyStr: null,
      decoder,
      dialogHistory: false,
      dialogNote: false,
      dialogFlavor: false,
      historyObj: {},
      isDateValid,
      isNumValid,
      note: '',
      noteDate: '',
      parseTimeStamp,
      flavor: defaultFlavor(),
      usableHeight: 0
    };
  },
  // mounted() {},
  methods: {
    createFlavorPond() {
      this.action = 'create';
      this.flavor = defaultFlavor();
      this.dialogFlavor = true;
    },
    deleteFlavor() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: 'Are you sure you want to delete this flavor item?',
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitFlavor();
        });
    },
    displayHistoryItem(item) {
      this.historyObj = item;
      this.dialogHistory = true;
    },
    editFlavor(flavor) {
      this.action = 'update';

      this.flavor = {
        ...defaultFlavor(),
        ...flavor
      };

      this.dateCopy = this.flavor.date;
      this.flavor.date = date.formatDate(
        new Date(flavor.date * 1000),
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.flavor.date;

      this.dialogFlavor = true;
      this.dialogHistory = false;
    },
    onResize() {
      const adjustableHeight =
        document.getElementById('adjustable').clientHeight;

      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 81 - adjustableHeight;
    },
    async submitFlavor() {
      const flavor = cloneObj(this.flavor);

      const dateStr = this.flavor.date;
      flavor.date = setTimeOfDay(dateStr);

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        flavor.date = this.dateCopy;
      }

      if (this.action === 'create') {
        flavor.id = 0;
      }

      const flavorObj = this.FlavorOptions.find(
        (x) => x.value === flavor.flavor
      );

      const procObj = this.ProcessorOptions.find(
        (x) => x.value === flavor.proc_id
      );

      flavor.guid = getUID();
      flavor.user_id = store.state.user.user_id;
      flavor.flavor_name = flavorObj.name;
      flavor.processor = procObj.name;
      flavor.storeInfo.action = this.action + 'FlavorPond';
      flavor.storeInfo.category = 'flavorPond';
      flavor.storeInfo.pondId = this.pond.id;

      guidMatchHelper(flavor, this.action);

      store.dispatch('publish', flavor);

      this.dialogFlavor = false;

      this.$q.notify({
        color: this.action === 'delete' ? 'negative' : 'primary',
        message:
          this.action === 'delete' ? 'Flavor Item Deleted' : 'Flavor Saved'
      });
    }
  },
  computed: {
    History() {
      return this.pond.flavorHistory;
    },
    FlavorOptions() {
      const flavors = [];
      for (const flavor of store.state.farm.flavors) {
        flavors.push({
          value: flavor.id,
          name: flavor.name,
          label: flavor.name
        });
      }

      // Old feedx data has flavor IDs that
      // are from other farms
      if (this.dialogFlavor) {
        const hasId = flavors.some((x) => x.value === this.flavor.flavor);

        if (!hasId) {
          flavors.push({
            value: this.flavor.flavor,
            name: this.flavor.flavor_name,
            label: this.flavor.flavor_name
          });
        }
      }

      return flavors;
    },
    ProcessorOptions() {
      const processors = [];
      for (const processor of store.state.farm.processors) {
        processors.push({
          value: processor.id,
          name: processor.name,
          label: processor.name
        });
      }

      // Old feedx data has flavor IDs that
      // are from other farms
      if (this.dialogFlavor) {
        const hasId = processors.some((x) => x.value === this.flavor.proc_id);

        if (!hasId) {
          processors.push({
            value: this.flavor.proc_id,
            name: this.flavor.processor,
            label: this.flavor.processor
          });
        }
      }

      return processors;
    }
  }
};
</script>

<style></style>
