<template>
  <div class="row full-width justify-center">
    <div :style="$q.screen.xs ? 'width: 96vh' : 'width: 700px'">
      <div class="row full-width justify-center" style="padding-top: 80px">
        <div class="row full-width q-pb-sm">
          <div
            v-if="attachment.is_pdf === 1"
            class="row items-center text-primary"
          >
            <q-btn
              unelevated
              round
              icon="chevron_left"
              padding="0px"
              color="grey-3"
              text-color="primary"
              size="md"
              :disable="pdf.currentPage === 1"
              @click="pdfBack(pdf)"
            />
            <div class="q-px-sm">
              {{ pdf.currentPage }} / {{ pdf.pageCount }}
            </div>
            <q-btn
              unelevated
              round
              color="grey-3"
              text-color="primary"
              icon="chevron_right"
              padding="0px"
              size="md"
              :disable="pdf.currentPage === pdf.pageCount"
              @click="pdfNext(pdf)"
            />
          </div>
          <q-space />

          <q-btn
            outline
            label="Open Folder"
            icon="folder_open"
            color="primary"
            padding="2px 10px"
            size="md"
            v-close-popup
            @click="$emit('openFolder')"
          />
        </div>

        <div
          v-if="attachment.img_name.length > 1 || isStatic"
          class="row justify-between full-width rounded-borders text-h6 text-bold text-primary q-pl-sm q-mb-sm"
          style="border: solid #245ad3 1px"
        >
          <div class="col" style="word-break: break-all">
            {{ attachment.img_name }}
          </div>
          <div
            v-if="isStatic"
            class="col-auto row justify-end text-accent q-pr-sm"
            style="width: 155px"
          >
            {{ attachment.pondName }}
          </div>
        </div>
        <PDF
          v-if="attachment.is_pdf !== 0"
          :src="attachment.img_url"
          :page="pdf.currentPage"
          style="width: 100%; border: solid 1px #eeeeee"
          class="rounded-borders"
          @num-pages="pdf.pageCount = $event"
        />

        <q-img
          v-if="attachment.is_pdf === 0"
          :src="attachment.img_url"
          :native-context-menu="true"
          spinner-color="primary"
          style="border: solid 1px #eeeeee"
          class="rounded-borders"
        />

        <div class="row full-width text-caption">
          <div class="text-primary">
            {{ parseDateTime(attachment.upload_date) }}
          </div>
          <q-space />
          <div class="text-primary">
            {{ userName(attachment.uploaded_by) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PDF from 'vue-pdf';
import store from '@/store';
import { cloneObj, parseDateTime, userName } from '@/lib/helpers';

export default {
  name: 'AttachmentViewer',
  components: {
    PDF
  },
  props: {
    attachment: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      parseDateTime,
      pdf: {
        currentPage: 1,
        pageCount: 1
      },
      userName
    };
  },
  // mounted() {},
  methods: {
    pdfBack(pdf) {
      pdf.currentPage--;
    },
    pdfNext(pdf) {
      pdf.currentPage++;
    }
  },
  computed: {
    IsAdmin() {
      return this.$store.state.user.feedx_level === 'super-admin';
    }
  }
};
</script>

<style scoped></style>
