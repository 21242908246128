<template>
  <div>
    <div class="row items-center justify-end no-wrap q-py-sm q-px-sm">
      <div class="row items-center">
        <q-input
          v-model="searchStr"
          outlined
          stack-label
          dense
          label="Search"
          class="input-box q-mr-sm"
        />
        <q-btn
          unelevated
          icon="add"
          size="md"
          color="primary"
          class="lt-sm"
          @click="addEquipmentEntry"
        />
        <q-btn
          unelevated
          icon="add"
          label="New Equipment"
          size="md"
          color="primary"
          class="gt-xs"
          @click="addEquipmentEntry"
        />
      </div>
    </div>
    <q-separator />

    <div
      :style="`max-height: ${store.state.pageHeight - 140}px`"
      class="scroll q-px-md q-pt-md"
    >
      <div v-if="EquipmentList.length === 0" class="text-center text-subtitle1">
        No equipment to display.
      </div>

      <div
        v-for="(equipment, index) in EquipmentList"
        :key="equipment.id"
        class="row justify-center"
      >
        <q-card flat bordered class="q-mb-md q-pa-md" style="width: 600px">
          <div class="row justify-between">
            <div class="text-h6 text-primary q-pr-md">
              {{ equipment.name || '---' }}
            </div>
            <div class="row items-center">
              <div>
                {{
                  new Date(equipment.install_date * 1000).toLocaleDateString()
                }}
              </div>
            </div>
          </div>
          <div v-if="!equipment.in_service" class="text-accent text-bold">
            Out of Service
          </div>

          <div class="q-pt-sm q-pb-sm">
            <span v-if="expandedArr[index]">
              {{ equipment.note }}
            </span>
            <span v-if="!expandedArr[index]">
              {{ equipment.truncatedNote }}
            </span>

            <span v-if="equipment.note.length > 200" class="q-pl-xs">
              ...
              <span
                class="text-primary cursor-pointer q-pl-xs"
                style="text-decoration: underline"
                @click="expandCollapse(index)"
              >
                <span v-if="!expandedArr[index]"> More </span>
                <span v-if="expandedArr[index]"> Less </span>
              </span>
            </span>
          </div>

          <div class="row justify-between items-end">
            <div class="col">
              <div v-if="equipment.pond_id">
                <q-chip
                  class="q-mr-sm q-ml-none q-mb-none q-mt-sm"
                  color="grey-3"
                >
                  {{ PondNames[equipment.pond_id] }}
                </q-chip>
              </div>
            </div>

            <div class="col-auto row">
              <q-btn
                unelevated
                icon="edit"
                color="grey-3"
                text-color="primary"
                :padding="$q.screen.xs ? '3px 4px' : '3px 10px'"
                @click="editEquipmentEntry(equipment)"
              />
            </div>
          </div>
        </q-card>
      </div>
    </div>

    <!-- Dialog for Equipment Entry -->
    <q-dialog persistent position="top" v-model="dialogEquipmentEntry">
      <q-card style="width: 450px">
        <div class="row justify-center q-py-sm">
          <div class="text-h6 text-primary text-center">Equipment Entry</div>
        </div>
        <q-separator color="primary" />

        <div class="q-pa-md">
          <q-input
            v-model="equipmentEntry.name"
            label="Equipment Name"
            class="q-mb-md"
            outlined
            dense
          />
          <q-input
            v-model="equipmentEntry.note"
            label="Note"
            class="q-mb-md"
            outlined
            dense
            type="textarea"
          />
          <q-input
            outlined
            dense
            label="Install Date"
            class="q-mb-md"
            v-model="equipmentEntry.install_date"
            lazy-rules
            :rules="[(val) => isDateValid(val) || 'Invalid Date']"
            hide-bottom-space
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer" color="primary">
                <q-popup-proxy
                  ref="qDateProxy"
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="equipmentEntry.install_date"
                    mask="MM/DD/YYYY"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>

          <q-select
            v-model="equipmentEntry.pond_id"
            label="Select Pond"
            outlined
            :options="PondList"
          />
        </div>
        <q-separator />

        <div class="row justify-center q-py-sm">
          <q-toggle
            dense
            v-model="inService"
            label="In Service"
            color="primary"
          />
        </div>
        <q-separator />

        <div class="row justify-end q-pa-md">
          <q-btn
            unelevated
            label="Cancel"
            color="grey-4"
            text-color="black"
            size="md"
            class="q-mr-sm"
            v-close-popup
          />
          <q-btn
            v-if="action === 'update'"
            unelevated
            label="Delete"
            color="grey-4"
            text-color="accent"
            class="q-mr-sm"
            @click="deleteEquipmentEntryConfirm()"
          />
          <q-btn label="Submit" color="primary" @click="submitEquipmentEntry" />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { smartCompare } from '@/lib/helpers';
import { date } from 'quasar';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { cloneObj, upperCaseFirst } from '@/lib/helpers';
import { getUID, guidMatchHelper } from '@/store/transactionHelpers';
import store from '@/store';

const defaultEquipmentEntry = () => {
  return {
    id: null,
    farm_id: store.state.farm.id,
    pond_id: null,
    name: '',
    note: '',
    install_date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    in_service: 1,
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id,
      parentType: 'equipmentList',
      equipmentId: null
    }
  };
};

export default {
  name: 'EquipmentList',
  data() {
    return {
      action: 'create',
      allPondsFilter: true,
      equipmentEntry: defaultEquipmentEntry(),
      expandedArr: [],
      dateCopy: null,
      dateCopyStr: '',
      dialogEquipmentEntry: false,
      filteredPonds: [],
      inService: true,
      isDateValid,
      noPondSelected: true,
      parseTimeStamp,
      pondIds: [],
      pondList: [],
      pondNames: {},
      searchStr: '',
      store
    };
  },
  // mounted() {},
  methods: {
    addEquipmentEntry() {
      this.action = 'create';
      this.equipmentEntry = defaultEquipmentEntry();
      this.equipmentEntry.pond_id = this.PondList[0];
      this.inService = true;

      this.dialogEquipmentEntry = true;
    },
    deleteEquipmentEntryConfirm() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: `Are you sure you want to delete this equipment entry?`,
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';
          this.submitEquipmentEntry();
        });
    },
    editEquipmentEntry(equipmentEntry) {
      this.action = 'update';
      this.equipmentEntry = {
        ...defaultEquipmentEntry(),
        ...cloneObj(equipmentEntry)
      };

      this.equipmentEntry.storeInfo.equipmentId = equipmentEntry.id;

      this.equipmentEntry.pond_id = this.PondList.find(
        (x) => x.value === equipmentEntry.pond_id
      );

      this.dateCopy = this.equipmentEntry.install_date;
      this.equipmentEntry.install_date = date.formatDate(
        new Date(equipmentEntry.install_date * 1000),
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.equipmentEntry.date;

      this.inService = this.equipmentEntry.in_service === 1;

      this.dialogEquipmentEntry = true;
    },
    expandCollapse(index) {
      this.$set(this.expandedArr, index, !this.expandedArr[index]);
    },
    submitEquipmentEntry() {
      const equipmentEntry = cloneObj(this.equipmentEntry);

      const dateStr = this.equipmentEntry.install_date;
      equipmentEntry.install_date = setTimeOfDay(dateStr);

      equipmentEntry.in_service = this.inService ? 1 : 0;

      equipmentEntry.pond_id = equipmentEntry.pond_id.value;

      if (this.action === 'create') {
        equipmentEntry.id = 0;
      }

      equipmentEntry.guid = getUID();
      equipmentEntry.user_id = store.state.user.id;
      equipmentEntry.storeInfo.action = this.action + 'EquipmentEntry';
      equipmentEntry.storeInfo.category = 'equipmentList';

      guidMatchHelper(equipmentEntry, this.action);

      store.dispatch('publish', equipmentEntry);

      this.dialogEquipmentEntry = false;
    }
  },
  computed: {
    EquipmentList() {
      const searchStr = this.searchStr.toLowerCase();

      const equipmentList = [];

      for (const equipment of store.state.equipmentList) {
        const equipObj = cloneObj(equipment);

        equipObj.truncatedNote = equipment.note.substring(0, 200);

        equipmentList.push(equipObj);
      }

      return equipmentList
        .filter(
          (x) =>
            x.name.toLowerCase().includes(searchStr) ||
            x.note.toLowerCase().includes(searchStr)
        )
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    Ponds() {
      const singleLocation = this.SelectedLocation === 'SINGLE_LOCATION';

      return this.$store.state.farm.ponds
        .filter(
          (x) =>
            (x.location_name === this.SelectedLocation || singleLocation) &&
            !x.archived
        )
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    PondList() {
      const pondList = [];

      pondList.push({
        label: 'No Pond Selected',
        value: null
      });

      for (const pond of this.Ponds) {
        pondList.push({
          label: upperCaseFirst(pond.name),
          value: pond.id
        });
      }

      return pondList;
    },
    PondNames() {
      const pondNames = {};

      for (const pond of this.Ponds) {
        pondNames[pond.id] = pond.name;
      }

      pondNames[null] = 'No Pond Selected';

      return pondNames;
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    }
  }
};
</script>

<style scoped>
:deep(.input-box .q-field__control),
:deep(.input-box .q-field__marginal) {
  height: 36px;
  font-size: 16px;
}
</style>
