/* eslint-disable quotes */
/**
 * @enum
 */
const UserRoles = {
  SUPER_ADMIN: 'super-admin',
  INSTALLER: 'installer',
  CLIENT: 'client',
  /* SQL helper */
  FIELD_LIST: `'super-admin','installer','client'`,
  /* Reverse lookup, least to greatest */
  MAP: ['none', 'client', 'installer', 'super-admin']
};

module.exports = UserRoles;
