<template>
  <q-layout
    view="lHh lpr lFf"
    container
    :style="`height: ${usableHeight}px`"
    :class="$q.screen.gt.sm && 'custom-bg'"
  >
    <q-resize-observer @resize="onResize" />

    <q-page-container v-if="currentView === 'overview'">
      <div
        :class="$q.screen.xs ? 'q-px-sm' : 'q-px-lg'"
        :style="`height: ${usableHeight}px`"
      >
        <div class="row justify-center">
          <div style="width: 600px">
            <div class="q-px-sm q-pb-md q-py-lg">
              <div class="text-h5 q-pl-sm">Overview</div>
              <q-separator />
            </div>

            <div class="row">
              <div
                v-for="purchaser in Purchasers"
                :key="purchaser.name"
                class="col-12 q-pb-md"
              >
                <div class="q-px-sm">
                  <div
                    class="bg-grey-2 rounded-borders full-width text-subtitle1 q-px-md q-py-sm"
                  >
                    <div
                      v-if="purchaser.purchaserName !== 'NO_PURCHASER'"
                      class="q-pb-sm text-h6 text-center"
                    >
                      {{ decoder(purchaser.purchaserName) }}
                      <q-separator />
                    </div>

                    <div class="q-pb-sm">
                      <div class="row justify-between">
                        <div>Head Count</div>
                        <div>{{ purchaser.totalHead.toLocaleString() }}</div>
                      </div>
                      <q-separator />
                    </div>

                    <div class="q-pb-xs">
                      <div class="row justify-between">
                        <div>Estimated Weight</div>
                        <div>
                          {{ purchaser.totalWeight.toLocaleString() }}
                        </div>
                      </div>
                      <q-separator />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="q-px-sm q-pb-md q-pb-lg q-pt-md">
              <div class="text-h5 q-py-sm q-pl-sm">Ponds</div>
              <q-separator />
            </div>

            <div
              v-for="pond in Ponds"
              :id="`pond-${pond.id}`"
              :key="pond.id"
              class="col-12 q-px-sm q-pb-lg"
            >
              <PondOverview
                :pond="pond"
                @goToFishInOut="goToFishInOut"
                @goToTreatments="goToTreatments"
                @goToOxygen="goToOxygen"
                @goToFlavor="goToFlavor"
              />
            </div>
          </div>
        </div>
      </div>
    </q-page-container>

    <q-page-container id="details-page" v-if="currentView === 'pondDetail'">
      <PondDetail
        :pond="selectedPond"
        :goToPond="GoToPond"
        @back="back"
        @goToNext="goToNext"
        @goToPrevious="goToPrevious"
      />
    </q-page-container>

    <q-page-container
      id="treatment-page"
      v-if="currentView === 'treatmentDetail'"
    >
      <TreatmentDetail
        :pond="selectedPond"
        :goToPond="GoToPond"
        @back="back"
        @goToNext="goToNext"
        @goToPrevious="goToPrevious"
      />
    </q-page-container>

    <q-page-container id="oxygen-page" v-if="currentView === 'oxygenDetail'">
      <OxygenDetail
        :pond="selectedPond"
        :goToPond="GoToPond"
        @back="back"
        @goToNext="goToNext"
        @goToPrevious="goToPrevious"
      />
    </q-page-container>

    <q-page-container id="flavor-page" v-if="currentView === 'flavorDetail'">
      <FlavorDetail
        :pond="selectedPond"
        :goToPond="GoToPond"
        @back="back"
        @goToNext="goToNext"
        @goToPrevious="goToPrevious"
      />
    </q-page-container>
  </q-layout>
</template>

<script>
import PondOverview from '@/components/ponds/PondOverview.vue';
import PondDetail from '@/components/ponds/PondDetail.vue';
import TreatmentDetail from '@/components/ponds/TreatmentDetail.vue';
import OxygenDetail from '@/components/ponds/OxygenDetail.vue';
import FlavorDetail from '@/components/ponds/FlavorDetail.vue';

import { decoder, cloneObj, smartCompare, formatDollars } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils.js';
import { date, uid } from 'quasar';
import store from '@/store';

export default {
  components: {
    OxygenDetail,
    PondOverview,
    PondDetail,
    TreatmentDetail,
    FlavorDetail
  },
  name: 'Settings-Bins',
  data() {
    return {
      decoder,
      formatDollars,
      parseTimeStamp,
      currentView: 'overview',
      selectedBin: {},
      selectedFeeder: {},
      selectedPond: {},
      selectedPondId: null,
      usableHeight: 1000
    };
  },
  methods: {
    back() {
      this.currentView = 'overview';

      this.$nextTick(() => {
        document
          .getElementById('pond-' + this.selectedPond.id)
          .scrollIntoView();
      });
    },
    goToFlavor(pond) {
      this.selectedPondId = pond.id;
      this.selectedPond = pond;
      this.currentView = 'flavorDetail';
      this.$nextTick(() => {
        document.getElementById('flavor-page').scrollIntoView();
      });
    },
    goToOxygen(pond) {
      this.selectedPondId = pond.id;
      this.selectedPond = pond;
      this.currentView = 'oxygenDetail';
      this.$nextTick(() => {
        document.getElementById('oxygen-page').scrollIntoView();
      });
    },
    goToTreatments(pond) {
      this.selectedPondId = pond.id;
      this.selectedPond = pond;
      this.currentView = 'treatmentDetail';
      this.$nextTick(() => {
        document.getElementById('treatment-page').scrollIntoView();
      });
    },
    goToFishInOut(pond) {
      this.selectedPondId = pond.id;
      this.selectedPond = cloneObj(pond);
      this.currentView = 'pondDetail';
      this.$nextTick(() => {
        document.getElementById('details-page').scrollIntoView();
      });
    },
    goToNext() {
      const index = this.Ponds.findIndex(
        (pond) => pond.id === this.selectedPond.id
      );

      const pond = cloneObj(this.Ponds[index + 1]);

      if (this.currentView === 'pondDetail') {
        this.goToFishInOut(pond);
      }

      if (this.currentView === 'treatmentDetail') {
        this.goToTreatments(pond);
      }

      if (this.currentView === 'oxygenDetail') {
        this.goToOxygen(pond);
      }

      if (this.currentView === 'flavorDetail') {
        this.goToFlavor(pond);
      }
    },
    goToPrevious() {
      const index = this.Ponds.findIndex(
        (pond) => pond.id === this.selectedPond.id
      );

      const pond = cloneObj(this.Ponds[index - 1]);

      if (this.currentView === 'pondDetail') {
        this.goToFishInOut(pond);
      }

      if (this.currentView === 'treatmentDetail') {
        this.goToTreatments(pond);
      }

      if (this.currentView === 'oxygenDetail') {
        this.goToOxygen(pond);
      }

      if (this.currentView === 'flavorDetail') {
        this.goToFlavor(pond);
      }
    },
    onResize() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 50;
    }
  },
  computed: {
    IsAdmin() {
      return this.$store.state.user.feedx_level === 'super-admin';
    },
    GoToPond() {
      const index =
        this.Ponds.findIndex((pond) => pond.id === this.selectedPond.id) ?? 0;

      const next = index < this.Ponds.length - 1;
      const previous = index > 0;

      return { next, previous };
    },
    Purchasers() {
      const purchasers = new Map();
      for (const purchaser of store.state.farm.purchasers) {
        purchasers.set(purchaser.id, {
          purchaserName: purchaser.name,
          totalHead: 0,
          totalWeight: 0,
          numPonds: 0
        });
      }

      purchasers.set('NO_PURCHASER', {
        purchaserName: 'NO_PURCHASER',
        totalHead: 0,
        totalWeight: 0,
        numPonds: 0
      });

      for (const pond of this.Ponds) {
        let purchaser = null;

        if (pond.purchaser) {
          purchaser = purchasers.get(pond.purchaser);
        } else {
          purchaser = purchasers.get('NO_PURCHASER');
        }

        purchaser.numPonds += 1;

        for (const fishGroup of pond.growth.FishGroups) {
          if (fishGroup.status === 'inactive') {
            continue;
          }

          purchaser.totalHead += fishGroup.head;
          purchaser.totalWeight += fishGroup.weight;
        }
      }

      if (purchasers.get('NO_PURCHASER').numPonds === 0) {
        purchasers.delete('NO_PURCHASER');
      }

      return Array.from(purchasers.values()).sort((a, b) =>
        a.purchaserName.localeCompare(b.purchaserName)
      );
    },
    Ponds() {
      const singleLocation = this.SelectedLocation === 'SINGLE_LOCATION';

      return this.$store.state.farm.ponds
        .filter(
          (x) =>
            (x.location_name === this.SelectedLocation || singleLocation) &&
            !x.archived
        )
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    }
  },
  watch: {
    '$store.state.farm.ponds': {
      handler() {
        this.selectedPond =
          cloneObj(
            this.$store.state.farm.ponds.find(
              (x) => x.id === this.selectedPondId
            )
          ) ?? {};
      },
      deep: true
    }
  }
};
</script>

<style></style>
