<template>
  <div class="col-12 row">
    <q-card flat class="col bg-card q-pa-sm q-mb-md">
      <div class="row q-pa-xs">
        <div class="col-xs-12 col-sm-auto">
          <q-btn
            color="primary"
            text-color="btn-txt"
            :class="$q.screen.xs && 'full-width'"
            style="width: 180px"
            padding="5px 2px"
            @click="$emit('goToPondEntries', pond)"
          >
            <div class="text-subtitle1">
              {{ pond.name }}
            </div>
          </q-btn>
        </div>
        <div class="col-xs-12 col-sm row">
          <div class="col row flex-center text-subtitle1">
            {{ numberWithCommas(pond.growth.targetFeedAmount) }}
          </div>

          <div class="col row flex-center text-subtitle1">
            {{ numberWithCommas(pond.growth.CurrentUnharvestedFeed) }}
          </div>

          <div class="col row flex-center text-subtitle1">
            {{
              numberWithCommas(
                (pond.growth.CurrentUnharvestedFeed / pond.acres).toFixed(0)
              )
            }}
          </div>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
import store from '@/store';
import { decoder, numberWithCommas } from '@/lib/helpers';
import { date } from 'quasar';

export default {
  name: 'Pond-Feed',
  props: {
    pond: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      numberWithCommas
    };
  },
  methods: {},
  computed: {}
};
</script>

<style></style>
