<template>
  <div>
    <div class="q-px-sm q-pb-lg" :class="$q.screen.gt.sm && 'q-pt-lg'">
      <div class="row items-end justify-between q-pb-sm">
        <div class="text-h5 q-pl-sm">Feed Contracts</div>
        <div>
          <q-btn
            icon="add"
            color="primary"
            size="md"
            class="q-mr-sm"
            padding="5px md"
            @click="addContract"
          />
          <q-btn
            label="View"
            color="primary"
            size="md"
            padding="5px md"
            :loading="DataQueryLoading"
            @click="viewContracts"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <div v-if="!FeedContracts.length" class="q-px-sm">
      <div class="gt-sm text-grey-6 text-subtitle1 text-center">
        <div>No Contracts Currently Open</div>
      </div>
    </div>

    <div
      v-if="FeedContracts.length"
      class="gt-sm q-px-sm"
      :class="$q.screen.lt.md && 'q-pb-lg'"
    >
      <q-card class="q-pt-sm q-pb-md q-px-md q-mb-md">
        <div class="full-width" style="width: 250px">
          <div class="text-center text-subtitle1">
            {{ parseTimeStamp(LatestContract.date, 'short') }}
          </div>

          <div class="row justify-between q-px-xs">
            <div>Tons:</div>
            <div>
              {{ parseFloat(LatestContract.tons).toFixed(2) }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-between q-px-xs">
            <div>Price Per Ton:</div>
            <div>
              {{ formatDollars(parseFloat(LatestContract.price_per_ton)) }}
            </div>
          </div>
          <q-separator />

          <div class="row q-px-xs">
            <div class="col-auto q-pr-md">Mill:</div>
            <div class="col row justify-end">
              {{ LatestContract.mill_name }}
            </div>
          </div>
          <q-separator />

          <div class="row justify-end text-caption text-grey-7 q-pt-xs">
            ( most recent contract )
          </div>
        </div>
      </q-card>
    </div>

    <!-- Contract Dialog -->
    <q-dialog persistent position="top" v-model="dialogEditContract">
      <q-card style="width: 450px" class="q-pa-md">
        <q-form @submit="submitContract">
          <div class="q-pb-sm">
            <q-input
              filled
              dense
              label="Contract Date"
              v-model="contract.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="contract.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div v-if="action === 'close'" class="q-pb-sm">
            <q-input
              filled
              dense
              label="Contract Closing Date"
              v-model="contract.date_closed"
              color="accent"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="accent">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="contract.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>

          <div class="q-pb-sm">
            <q-select
              v-model="contract.grain_id"
              label="Select Mill"
              dense
              filled
              :options="MillOptions"
              emit-value
              map-options
              lazy-rules
              hide-bottom-space
              :rules="[(val) => !!val || 'Please Select A Mill']"
            />
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              v-model.number="contract.tons"
              label="Tons"
              step="any"
              min="0"
              mask="#####"
              type="number"
              lazy-rules
              hide-bottom-space
              :rules="[
                (val) => isNumValid(val) || 'Please Enter Valid Tonnage'
              ]"
            />
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              v-model.number="contract.price_per_ton"
              label="Price Per Ton"
              step="any"
              min="0"
              mask="#####"
              type="number"
              prefix="$"
              lazy-rules
              hide-bottom-space
              :rules="[(val) => isNumValid(val) || 'Please Enter Valid Price']"
            />
          </div>

          <div class="q-pb-sm">
            <q-input
              filled
              dense
              autogrow
              v-model="contract.notes"
              label="Note"
            />
          </div>

          <div class="row justify-end q-pt-xs">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              unelevated
              v-if="action === 'edit'"
              label="Delete"
              color="grey-4"
              text-color="accent"
              size="md"
              class="q-mr-sm"
              @click="deleteContract"
            />
            <q-btn
              unelevated
              :label="
                action === 'close'
                  ? 'Close Contract'
                  : action === 'create'
                  ? 'Create Contract'
                  : 'Update'
              "
              color="primary"
              size="md"
              type="submit"
            />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- View Contracts Dialog -->
    <q-dialog
      persistent
      full-height
      position="top"
      v-model="dialogViewContracts"
    >
      <q-card style="width: 450px">
        <div class="row items-center q-py-sm q-px-md">
          <q-space />
          <div class="text-h5">Recent Contracts</div>
          <q-space />
          <q-btn icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator />

        <div
          v-if="!DataQueryLoading && !FeedContractsRecent.length"
          class="text-center text-grey-6 text-h5 q-pt-md"
        >
          No Recent Contracts
        </div>

        <div v-if="DataQueryLoading">
          <q-linear-progress query color="primary" />

          <div class="text-center text-grey-6 text-h5 q-pt-md">
            Loading Contracts...
          </div>
        </div>

        <div
          class="scroll q-px-md q-py-md"
          style="max-height: calc(100vh - 120px)"
        >
          <q-card
            v-for="contract in FeedContractsRecent"
            :key="contract.id"
            class="q-pt-sm q-pb-md q-px-md q-mb-md"
          >
            <div class="full-width" style="width: 250px">
              <div class="row justify-between text-subtitle1">
                <div>
                  {{ parseTimeStamp(contract.date, 'short') }}
                </div>
                <div :class="!contract.date_closed && 'text-primary text-bold'">
                  {{ contract.date_closed ? 'Closed' : 'Currently Open' }}
                </div>
              </div>
              <q-separator />

              <div class="row justify-between q-px-xs">
                <div>Tons:</div>
                <div>
                  {{ parseFloat(contract.tons).toFixed(2) }}
                </div>
              </div>
              <q-separator />

              <div class="row justify-between q-px-xs">
                <div>Remaining:</div>
                <div>
                  {{ parseFloat(contract.remaining).toFixed(2) }}
                </div>
              </div>
              <q-separator />

              <div class="row justify-between q-px-xs">
                <div>Price Per Ton:</div>
                <div>
                  {{ formatDollars(parseFloat(contract.price_per_ton)) }}
                </div>
              </div>
              <q-separator />

              <div class="row q-px-xs">
                <div class="col-auto q-pr-md">Mill:</div>
                <div class="col row justify-end">
                  {{ contract.mill_name }}
                </div>
              </div>
              <q-separator />

              <div class="row q-px-xs">
                <div class="col-auto q-pr-md">Note:</div>
                <div class="col row justify-end">
                  <div class="ellipsis">
                    {{ contract.notes }}
                  </div>
                </div>
              </div>
              <q-separator />

              <div v-if="!contract.date_closed" class="row justify-end q-pt-sm">
                <q-btn
                  unelevated
                  label="Close Contract"
                  color="grey-4"
                  text-color="black"
                  size="md"
                  padding="2px md"
                  class="q-mr-sm"
                  @click="closeContract(contract)"
                />
                <q-btn
                  unelevated
                  label="Edit"
                  color="grey-4"
                  text-color="accent"
                  size="md"
                  padding="2px md"
                  @click="editContract(contract)"
                />
              </div>

              <div v-if="contract.date_closed" class="row justify-end q-pt-sm">
                <q-btn
                  unelevated
                  label="Re-Open"
                  color="grey-4"
                  text-color="accent"
                  size="md"
                  padding="2px md"
                  @click="openContract(contract)"
                />
              </div>
            </div>
          </q-card>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import store from '@/store';
import { cloneObj, formatDollars, isNumValid } from '@/lib/helpers';
import { guidMatchHelper } from '@/store/transactionHelpers';
import { parseTimeStamp, isDateValid } from '@/lib/date-utils.js';
import { date } from 'quasar';

export default {
  name: 'Contracts-Feed',
  data() {
    return {
      dialogEditContract: false,
      dialogViewContracts: false,
      contract: {},
      action: 'create',
      formatDollars,
      isDateValid,
      isNumValid,
      parseTimeStamp,
      store
    };
  },
  methods: {
    addContract() {
      this.contract = this.createContractObj();
      this.action = 'create';
      this.dialogEditContract = true;
    },
    closeContract(contract) {
      this.contract = cloneObj(contract);
      this.contract.date = date.formatDate(contract.date * 1000, 'MM/DD/YYYY');
      this.contract.date_closed = date.formatDate(new Date(), 'MM/DD/YYYY');

      this.action = 'close';
      this.dialogEditContract = true;
    },
    createContractObj() {
      return {
        id: 0,
        grain_id: null,
        tons: null,
        price_per_ton: null,
        mill_name: '',
        date: date.formatDate(new Date(), 'MM/DD/YYYY'),
        date_closed: null,
        farm_id: store.state.farm.id,
        notes: ''
      };
    },
    decoder(str) {
      return decoder(str);
    },
    deleteContract() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: 'Are you sure you want to delete contract?',
          ok: {
            unelevated: true,
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            unelevated: true,
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          this.deleteContractConfirmed();
        });
    },
    deleteContractConfirmed() {
      for (const bin of this.$store.state.farm.bins) {
        for (const delivery of bin.deliveries) {
          if (delivery.feed_contract_id === this.contract.id) {
            this.$q.dialog({
              title: 'Error',
              message:
                'This contract is has already been used on a bin delivery and cannot be deleted.'
            });
            return;
          }
        }
      }

      this.action = 'delete';
      this.submitContract();
    },
    disableSubmitCheck() {
      return true;
    },
    editContract(contract) {
      this.contract = cloneObj(contract);
      this.contract.date = date.formatDate(contract.date * 1000, 'MM/DD/YYYY');

      this.action = 'edit';
      this.dialogEditContract = true;
    },
    openContract(contract) {
      this.$q
        .dialog({
          title: 'Re-open Contract?',
          ok: {
            unelevated: true,
            label: 'Re-Open',
            color: 'accent'
          },
          cancel: {
            unelevated: true,
            label: 'Cancel',
            color: 'grey-4',
            textColor: 'black'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.contract = cloneObj(contract);
          this.contract.date *= 1000;
          this.contract.date_closed = null;
          this.action = 'open';
          this.submitContract();
        });
    },
    submitContract() {
      const contract = this.contract;

      contract.date = date.formatDate(contract.date, 'X');

      contract.date_closed =
        this.action === 'close'
          ? date.formatDate(contract.date_closed, 'X')
          : null;

      contract.mill_name =
        this.MillOptions.find((x) => x.value === contract.grain_id)?.label ??
        '---';

      contract.storeInfo = {
        farmId: this.$store.state.farm.id,
        category: 'feedContracts',
        action:
          this.action === 'create'
            ? 'createContract'
            : this.action === 'delete'
            ? 'deleteContract'
            : 'updateContract'
      };

      guidMatchHelper(contract, this.action);

      this.dialogEditContract = false;
      store.dispatch('publish', contract);

      this.$q.notify({
        color: this.action === 'delete' ? 'negative' : 'primary',
        message:
          this.action === 'delete' ? 'Contract Deleted' : 'Contract Saved'
      });
    },
    viewContracts() {
      const contractInfo = {
        storeInfo: {
          farmId: this.$store.state.farm.id,
          action: 'getRecentContracts',
          queryProp: 'feedContractsRecent'
        },
        id: 0
      };

      store.dispatch('queryData', contractInfo);
      this.dialogViewContracts = true;
    }
  },
  computed: {
    FeedContracts() {
      const feedContracts = cloneObj(store.state.farm.feedContracts);
      for (const contract of feedContracts) {
        contract.tons = parseFloat(contract.tons);
        contract.price_per_ton = parseFloat(contract.price_per_ton).toFixed(2);
      }

      feedContracts.sort((a, b) => a.date - b.date);

      return feedContracts;
    },
    DataQueryLoading() {
      return store.state.dataQueryLoading;
    },
    FeedContractsRecent() {
      const feedContracts = cloneObj(
        store.state.dataQueries?.feedContractsRecent ?? []
      );
      for (const contract of feedContracts) {
        contract.tons = parseFloat(contract.tons);
        contract.price_per_ton = parseFloat(contract.price_per_ton).toFixed(2);
      }

      feedContracts.sort((a, b) => b.date - a.date);

      return feedContracts;
    },
    LatestContract() {
      return this.FeedContracts.slice().pop();
    },
    MillOptions() {
      const options = [];
      for (const mill of store.state.farm.mills) {
        options.push({
          label: mill.name,
          value: mill.grain_id
        });
      }

      return options;
    }
  }
};
</script>

<style></style>
