<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/locations.png"
          alt="Locations"
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Location</div>
    </div>
    <q-separator color="black" style="min-width: 620px" />

    <div v-for="location in Locations" :key="location.id">
      <div class="row no-wrap items-center q-py-xs">
        <div
          class="col q-pl-sm"
          style="min-width: 120px; word-break: break-all"
        >
          {{ decoder(location.name) }}
        </div>
      </div>
      <q-separator style="min-width: 620px" />
    </div>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';

export default {
  name: 'Settings-HarvestSize',
  data() {
    return {
      decoder,
      dialogHarvestSizeEntry: false,
      displayInactive: false,
      harvestSize: 0.0,
      store
    };
  },
  methods: {},
  computed: {
    Locations() {
      let locations = cloneObj(
        this.$store.getters.selectedFarm?.locations ?? []
      );

      return locations.sort(function (a, b) {
        return a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
    }
  }
};
</script>

<style scoped></style>
