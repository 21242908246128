<template>
  <div class="col-12 row">
    <q-card flat class="row col bg-card q-px-md q-pt-md q-pb-sm q-mb-md">
      <div class="column justify-center">
        <MasterIcon
          :percent="FeederLevelPercent"
          :iconSrc="'feeder-single.png'"
          :padding="'5px 5px 4px 5px'"
          :size="56"
        />

        <div class="text-caption text-center">
          {{ FeederLevelPercent.toFixed(0) }}%
        </div>
      </div>

      <div class="col row justify-center q-pl-md">
        <div class="col-xs-12 col-sm-6 row justify-between">
          <div class="col-12">
            <div class="text-center text-h6">
              {{ decoder(feeder.name) }}
            </div>
          </div>

          <div class="col-12 row flex-center text-subtitle1">
            <div>{{ numberWithCommas(feeder.balance) }} lbs</div>
          </div>
        </div>
      </div>

      <div v-if="displayBtns" class="row items-center q-pb-sm">
        <div>
          <div>
            <q-btn
              outline
              color="primary"
              class="full-width"
              padding="4px 8px"
              @click="$emit('fillFeeder', feeder)"
            >
              <span style="font-size: 12px"> Fill </span>
            </q-btn>
          </div>
          <div class="q-pt-sm">
            <q-btn
              outline
              unelevated
              color="primary"
              class="full-width"
              padding="4px 5px"
              @click="$emit('goToFeederEntries', feeder)"
            >
              <span style="font-size: 12px"> Entries </span>
            </q-btn>
          </div>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
import MasterIcon from '@/components/icons/MasterIcon.vue';

import store from '@/store';
import { numberWithCommas, getPercentage, decoder } from '@/lib/helpers';
import { date } from 'quasar';

export default {
  name: 'FeederOverview',
  components: {
    MasterIcon
  },
  props: {
    feeder: {
      type: Object,
      required: true
    },
    displayBtns: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      decoder,
      numberWithCommas
    };
  },
  computed: {
    FeederLevelPercent() {
      return getPercentage(this.feeder.balance, this.feeder.capacity);
    },
    LastFill() {
      return this.feeder?.fillUps?.[0] ?? { date: '', amount: 0 };
    },
    LastFillDate() {
      return date.formatDate(this.LastFill.date * 1000, 'ddd, MMM D');
    }
  }
};
</script>

<style></style>
