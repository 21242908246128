import connector from '@/services/mqtt/connector';
import UrlPattern from 'url-pattern';
import { inflate } from 'pako/lib/inflate.js';

export default function createNetworkPlugin() {
  return (store) => {
    const handlers = [];

    const defaultHandler = (pattern, set, all, del) => {
      handlers.push({
        matcher: new UrlPattern(pattern),
        fn: (packet, params, store) => {
          let payload;
          if (packet.properties?.userProperties?.gz ?? false) {
            payload = inflate(packet.payload, { to: 'string' });
          } else {
            payload = packet.payload.toString();
          }

          if (payload === '' && del) {
            store.commit({
              type: del,
              itemId: parseInt(params.itemId),
              params
            });
            return;
          }

          const item = JSON.parse(payload);
          if (params.itemId === 'all' && all) {
            store.commit({
              type: all,
              items: item,
              params
            });
            return;
          }

          if (item && set) {
            store.commit({
              type: set,
              item,
              itemId: parseInt(params.itemId),
              params
            });
          }
        }
      });
    };

    defaultHandler(
      'farm/:farmId/attachments/',
      'SET_ATTACHMENT_ITEM',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/flavorPond/:itemId/',
      'SET_STORE_ITEM',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/oxygenPond/:itemId/',
      'SET_STORE_ITEM',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/treatPond/:itemId/',
      'SET_STORE_ITEM',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/queryData/:itemId/',
      'SET_QUERIED_DATA',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/binEntries/:itemId/',
      'SET_STORE_ITEM',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/feedContracts/:itemId/',
      'SET_STORE_ITEM',
      'SET_CONTRACT_ALL',
      'SET_STORE_ITEM'
    );

    defaultHandler(
      'farm/:farmId/feederEntries/:itemId/',
      'SET_STORE_ITEM',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/pondEntries/:itemId/',
      'SET_STORE_ITEM',
      undefined,
      undefined
    );

    defaultHandler(
      'farm/:farmId/fishMove/:itemId/',
      'SET_FISH_MOVE',
      undefined,
      undefined
    );

    defaultHandler(
      'user/:userId/adminFarms/',
      'SET_ADMIN_FARMS',
      undefined,
      undefined
    );

    defaultHandler(
      'user/:userId/farms/:itemId/',
      'SET_FARM_LIST',
      'SET_FARM_LIST_ALL',
      'DEL_FARM_LIST'
    );

    defaultHandler(
      'flavors/:itemId/',
      'SET_FLAVOR',
      'SET_FLAVORS_ALL',
      'DEL_FLAVOR'
    );

    defaultHandler(
      'treatments/:itemId/',
      'SET_TREATMENT',
      'SET_TREATMENT_ALL',
      'DEL_TREATMENT'
    );

    defaultHandler(
      'farm/:farmId/bins/:itemId/',
      'SET_BIN',
      'SET_BINS_ALL',
      'DEL_BIN'
    );

    defaultHandler(
      'farm/:farmId/ponds/:itemId/',
      'SET_POND',
      'SET_PONDS_ALL',
      'DEL_POND'
    );

    defaultHandler(
      'farm/:farmId/feeders/:itemId/',
      'SET_FEEDER',
      'SET_FEEDERS_ALL',
      'DEL_FEEDER'
    );

    defaultHandler(
      'farm/:farmId/mills/:itemId/',
      'SET_MILL',
      'SET_MILLS_ALL',
      'DEL_MILL'
    );

    defaultHandler(
      'farm/:farmId/feedTypes/:itemId/',
      'SET_FARM_FEEDTYPE',
      'SET_FARM_FEEDTYPES_ALL',
      'DEL_FARM_FEEDTYPE'
    );

    defaultHandler(
      'feedTypes/:itemId/',
      'SET_FEEDTYPE',
      'SET_FEEDTYPES_ALL',
      'DEL_FEEDTYPE'
    );

    defaultHandler(
      'farm/:farmId/hatcheries/:itemId/',
      'SET_HATCHERY',
      'SET_HATCHERIES_ALL',
      'DEL_HATCHERY'
    );

    defaultHandler(
      'farm/:farmId/processors/:itemId/',
      'SET_PROCESSOR',
      'SET_PROCESSORS_ALL',
      'DEL_PROCESSOR'
    );

    defaultHandler(
      'farm/:farmId/purchasers/:itemId/',
      'SET_PURCHASER',
      'SET_PURCHASERS_ALL',
      'DEL_PURCHASER'
    );

    defaultHandler(
      'farm/:farmId/tfa/:itemId/',
      'SET_TFA',
      'SET_TFA_ALL',
      'DEL_TFA'
    );

    defaultHandler(
      'farm/:farmId/fcr/:itemId/',
      'SET_FCR',
      'SET_FCR_ALL',
      'DEL_FCR'
    );

    defaultHandler(
      'farm/:farmId/flavors/:itemId/',
      'SET_FARM_FLAVOR',
      'SET_FARM_FLAVOR_ALL',
      'DEL_FARM_FLAVOR'
    );

    defaultHandler(
      'farm/:farmId/treatments/:itemId/',
      'SET_FARM_TREATMENT',
      'SET_FARM_TREATMENT_ALL',
      'DEL_FARM_TREATMENT'
    );

    defaultHandler('farm/:farmId/feed_days/', 'SET_FEED_DAYS');

    defaultHandler(
      'farm/:farmId/dailyJournals/:itemId/',
      'SET_DAILY_JOURNALS',
      'SET_DAILY_JOURNALS_ALL',
      'DEL_DAILY_JOURNAL'
    );

    defaultHandler(
      'farm/:farmId/equipmentList/:itemId/',
      'SET_EQUIPMENT_LIST',
      'SET_EQUIPMENT_LIST_ALL',
      'DEL_EQUIPMENT_LIST'
    );

    defaultHandler('farm/:farmId/settings/', 'SET_FARM_SETTINGS');

    defaultHandler('farm/:farmId/subscription/', 'SET_FARM_EXPIRATION');

    defaultHandler('user/:userId/feedx_level/', 'SET_USER_ROLE');

    defaultHandler(
      'farm/:farmId/users/:itemId/',
      'SET_FARM_USER',
      'SET_FARM_USERS_ALL',
      'DEL_FARM_USER'
    );

    connector.on('connect', function (connected) {
      store.commit({
        type: 'SET_ONLINE',
        OnLine: connected
      });
    });

    connector.on('login', function (userData, apiVersion) {
      store.commit('SET_USER_DATA', userData);
      store.commit('SET_API_VERSION', apiVersion);
    });

    connector.on('publish', function (packet) {
      try {
        let handled = false;
        let params;
        for (const handler of handlers) {
          if ((params = handler.matcher.match(packet.topic))) {
            handler.fn(packet, params, store);
            handled = true;
            break;
          }
        }
        if (!handled) {
          packet.payload = packet.payload.toString();
          console.log('Unhandled packet', packet.topic, packet);
        }
      } catch (error) {
        console.log(packet.topic, packet.payload.toString(), packet);
        console.error(error);
      }
    });
  };
}
