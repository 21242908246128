<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/treatments.png"
          alt="treatments"
          width="100%"
          height="100%"
        />
      </div>
      <div class="q-pt-md">
        <q-toggle label="Display Archived" v-model="displayArchived"></q-toggle>
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Name</div>
      <div class="col q-pl-sm">Units</div>
      <div class="col q-pl-sm">Order</div>
      <div
        class="col-auto"
        :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
      ></div>
    </div>

    <q-separator color="black" />

    <div v-for="treatment in Treatments" :key="treatment.id">
      <div class="row items-center">
        <div class="col q-pl-sm">{{ treatment.name }}</div>
        <div class="col q-pl-sm">
          {{ treatment.size }}
        </div>
        <div class="col q-pl-sm">
          {{ treatment.order }}
        </div>
        <div class="col-auto q-pa-sm">
          <q-btn
            unelevated
            icon="edit"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            color="grey-3"
            text-color="primary"
            class="q-mr-sm"
            @click="editTreatment(treatment)"
          >
          </q-btn>
          <q-btn
            v-if="!treatment.archived && treatment.transactions > 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="archive"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveTreatment(treatment, true)"
          />
          <q-btn
            v-if="treatment.archived"
            unelevated
            icon="unarchive"
            size="md"
            color="grey-3"
            text-color="secondary"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="archiveTreatment(treatment, false)"
          />
          <q-btn
            v-if="!treatment.archived && treatment.transactions === 0"
            unelevated
            color="grey-3"
            text-color="accent"
            icon="delete"
            size="md"
            :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
            @click="deleteTreatment(treatment)"
          />
        </div>
      </div>
      <q-separator />
    </div>

    <!-- Dialog Treatment Entry -->
    <q-dialog v-model="dialogTreatmentEntry" persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Treatment</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <q-form @submit="onSubmit" class="q-pa-md">
          <q-input
            outlined
            v-model="treatmentObj.name"
            label="Name"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Name cannot be blank']"
          />
          <q-input
            outlined
            v-model="treatmentObj.size"
            label="Unit"
            maxlength="32"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== '' || 'Unit cannot be blank']"
          />
          <q-input
            outlined
            v-model.number="treatmentObj.order"
            label="Order"
            type="number"
            min="0"
            class="q-mb-md"
            hide-bottom-space
            :rules="[(value) => value !== null || 'Order cannot be blank']"
          />

          <div class="row justify-end">
            <q-btn label="Submit" type="submit" color="primary" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import {
  decoder,
  numberWithCommas,
  cloneObj,
  upperCaseFirst
} from '@/lib/helpers';
import store from '@/store';
const newTreatment = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    name: '',
    order: 0,
    size: '',
    transactions: 0,
    archived: false,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-Treatments',
  data() {
    return {
      numberWithCommas,
      dialogTreatmentEntry: false,
      displayArchived: false,
      treatmentObj: newTreatment(),
      store
    };
  },
  methods: {
    add() {
      this.treatmentObj = newTreatment();

      this.dialogTreatmentEntry = true;
    },
    archiveTreatment(treatment, value) {
      const treatmentCopy = cloneObj(treatment);
      treatmentCopy.archived = value;

      treatmentCopy.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Archive Treatment',
          message: `Are you sure you want to ${
            value ? 'archive' : 'unarchive'
          } this treatment?`,
          ok: {
            color: 'accent',
            label: value ? 'Archive' : 'Unarchive',
            icon: value ? 'archive' : 'unarchive'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.archiveTreatmentConfirmed(treatmentCopy);
        });
    },
    archiveTreatmentConfirmed(treatment) {
      store
        .dispatch('updateTreatment', {
          treatment
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    deleteTreatment(treatment) {
      treatment.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete Treatment',
          message: 'Are you sure you want to delete this treatment?',
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'Cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteTreatmentConfirmed(treatment);
        });
    },
    deleteTreatmentConfirmed(treatment) {
      store
        .dispatch('deleteTreatment', {
          treatment
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editTreatment(treatment) {
      this.treatmentObj = cloneObj(treatment);
      this.treatmentObj.name = decoder(this.treatmentObj.name);
      this.dialogTreatmentEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.treatmentObj.id === 0) {
        dispatch = 'createTreatment';
      } else {
        dispatch = 'updateTreatment';
      }

      this.treatmentObj.name = upperCaseFirst(this.treatmentObj.name);

      this.treatmentObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          treatment: this.treatmentObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogTreatmentEntry = false;
    }
  },
  computed: {
    Treatments() {
      let treatments = cloneObj(this.$store.state.farm.treatments);

      if (!this.displayArchived) {
        treatments = treatments.filter((element) => !element.archived);
      }

      return treatments.sort((a, b) => a.order - b.order);
    }
  }
};
</script>

<style scoped></style>
