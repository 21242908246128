<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/targetfeed.png"
          alt="TFA"
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <q-separator color="black" />

    <div style="overflow: auto">
      <div class="row no-wrap items-center q-py-xs">
        <div class="col q-pl-sm" style="min-width: 60px">Name</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.06 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.10 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.30 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.60 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.75 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">1.00 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">2.00 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">3.00 lb</div>
        <div
          class="col-auto"
          :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
        ></div>
      </div>

      <q-separator color="black" />

      <div v-for="tfa in Tfas" :key="tfa.id">
        <div class="row no-wrap items-center">
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ decoder(tfa.name) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s0_06.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s0_10.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s0_30.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s0_60.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s0_75.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s1_00.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s2_00.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ tfa.s3_00.toFixed(2) }}
          </div>
          <div
            class="col-auto q-pa-sm"
            :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
          >
            <q-btn
              unelevated
              icon="edit"
              size="md"
              :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
              color="grey-3"
              text-color="primary"
              class="q-mr-sm"
              @click="editTfa(tfa)"
            >
            </q-btn>
            <q-btn
              unelevated
              color="grey-3"
              text-color="accent"
              icon="delete"
              size="md"
              :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
              @click="deleteTfa(tfa)"
            />
          </div>
        </div>
        <q-separator />
      </div>
    </div>

    <!-- Dialog Enter TFA -->
    <q-dialog v-model="dialogTfaEntry" full-height persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Target Feed Amounts</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <div
          class="scroll"
          :style="`max-height: ${store.state.pageHeight - 100}px`"
        >
          <q-form @submit="onSubmit" class="q-pa-md">
            <q-input
              outlined
              v-model="tfaObj.name"
              label="Name"
              maxlength="32"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Name cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s0_06"
              label="0.06 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s0_10"
              label="0.10 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s0_30"
              label="0.30 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s0_60"
              label="0.60 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s0_75"
              label="0.75 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s1_00"
              label="1.00 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s2_00"
              label="2.00 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="tfaObj.s3_00"
              label="3.00 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <div class="row justify-end">
              <q-btn label="Submit" type="submit" color="primary" />
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newTfa = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    name: '',
    s0_06: 4.5,
    s0_10: 3.75,
    s0_30: 2.75,
    s0_60: 2.0,
    s0_75: 1.75,
    s1_00: 1.45,
    s2_00: 1.15,
    s3_00: 1.05,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-Tfas',
  data() {
    return {
      decoder,
      dialogTfaEntry: false,
      tfaObj: newTfa(),
      store
    };
  },
  methods: {
    add() {
      this.tfaObj = newTfa();

      this.dialogTfaEntry = true;
    },
    deleteTfa(tfa) {
      tfa.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete target feed amount?',
          message: `Are you sure you want to delete this target feed amount?`,
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteTfaConfirmed(tfa);
        });
    },
    deleteTfaConfirmed(tfa) {
      store
        .dispatch('deleteTFA', {
          tfa
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editTfa(tfa) {
      this.tfaObj = cloneObj(tfa);
      this.tfaObj.name = decoder(this.tfaObj.name);
      this.dialogTfaEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.tfaObj.id === 0) {
        dispatch = 'createTFA';
      } else {
        dispatch = 'updateTFA';
      }

      this.tfaObj.name = upperCaseFirst(this.tfaObj.name);

      this.tfaObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          tfa: this.tfaObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogTfaEntry = false;
    }
  },
  computed: {
    Tfas() {
      let tfas = cloneObj(this.$store.state.farm.tfa);

      return tfas.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>

<style scoped></style>
