<template>
  <q-layout
    view="lHh lpr lFf"
    container
    :style="`height: ${usableHeight}px`"
    :class="$q.screen.gt.sm && 'custom-bg'"
  >
    <q-resize-observer @resize="onResize" />

    <q-page-container v-show="currentView === 'overview'">
      <div
        class="bg-page"
        :class="$q.screen.xs ? 'q-px-sm' : 'q-px-lg'"
        :style="`height: ${usableHeight}px`"
      >
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <div class="q-px-sm q-py-lg">
              <div class="row items-center q-pb-sm" style="padding-top: 2px">
                <div class="text-h5 q-pl-sm">Overview</div>
              </div>
              <q-separator />
            </div>

            <div class="row justify-center q-pb-lg q-px-sm">
              <q-card class="full-width" style="padding: 22px 16px">
                <div class="q-pb-sm">
                  <div class="row justify-between">
                    <div>Remaining Feed</div>
                    <div>{{ RemainingFeed.toLocaleString() }}</div>
                  </div>
                  <q-separator />
                </div>

                <div class="q-py-xs">
                  <div class="row justify-between">
                    <div>Last 10 Feed Day Avg</div>
                    <div>{{ LastTenFeedAvg.toLocaleString() }}</div>
                  </div>
                  <q-separator />
                </div>

                <div class="q-py-sm">
                  <div class="row justify-between">
                    <div>Feed Days</div>
                    <div>
                      {{ FeedDays.toFixed(1) }}
                    </div>
                  </div>
                  <q-separator />
                </div>
              </q-card>
            </div>
          </div>

          <div class="col-xs-12 col-md-6">
            <FeedContracts />
          </div>
        </div>

        <div class="q-px-sm q-pb-md">
          <div class="text-h5 q-pl-sm">Bins</div>
          <q-separator />
        </div>

        <div class="row q-pb-md">
          <div
            v-for="bin in Bins"
            :key="bin.id"
            class="col-xs-12 col-lg-6 row q-px-sm"
          >
            <BinOverview
              :bin="bin"
              :displayBtns="true"
              @goToBinEntries="goToBinEntries"
            />
          </div>
        </div>

        <div class="q-px-sm q-pb-md">
          <div class="text-h5 q-pl-sm">Feeders</div>
          <q-separator />
        </div>

        <div class="row q-pb-lg">
          <div
            v-for="feeder in Feeders"
            :key="feeder.id"
            class="col-xs-12 col-lg-6 row q-px-sm"
          >
            <FeederOverview
              :feeder="feeder"
              :displayBtns="true"
              @goToFeederEntries="goToFeederEntries"
            />
          </div>
        </div>

        <div v-if="$q.screen.xs" class="col-xs-12 col-sm-auto text-h5 q-pl-md">
          Ponds
        </div>

        <div v-if="$q.screen.xs" class="q-px-sm">
          <q-separator />
        </div>

        <div class="row q-px-md q-mx-xs">
          <div
            v-if="!$q.screen.xs"
            class="col-xs-12 col-sm-auto text-h5"
            style="width: 180px"
          >
            Ponds
          </div>

          <div class="col-xs-12 col-sm row">
            <div
              class="col row flex-center"
              :class="!$q.screen.xs && 'text-subtitle1'"
            >
              <div class="text-center">Target Feed</div>
            </div>

            <div
              class="col row flex-center q-px-sm"
              :class="!$q.screen.xs && 'text-subtitle1'"
            >
              <div class="text-center">Unharvested Feed</div>
            </div>

            <div
              class="col row flex-center"
              :class="!$q.screen.xs && 'text-subtitle1'"
            >
              <div class="text-center">Lbs Per Acre</div>
            </div>
          </div>
        </div>

        <div class="q-px-sm q-pb-sm">
          <q-separator />
        </div>

        <div
          class="row q-pb-md"
          :class="Feeders.length === 1 && 'justify-center'"
        >
          <div v-for="pond in Ponds" :key="pond.id" class="col-12 row q-px-sm">
            <PondOverview :pond="pond" @goToPondEntries="goToPondEntries" />
          </div>
        </div>

        <div class="q-py-xl"></div>
      </div>
    </q-page-container>

    <q-page-container v-if="currentView === 'binEntries'">
      <BinDetail
        :bin="selectedBin"
        :goToBin="GoToBin"
        @back="back"
        @goToNext="goToBinNext"
        @goToPrevious="goToBinPrevious"
      />
    </q-page-container>

    <q-page-container v-if="currentView === 'feederEntries'">
      <FeederDetail :feeder="selectedFeeder" @back="back" />
    </q-page-container>

    <q-page-container v-if="currentView === 'pondEntries'">
      <PondDetail
        :pond="selectedPond"
        :goToPond="GoToPond"
        @back="back"
        @goToNext="goToPondNext"
        @goToPrevious="goToPondPrevious"
      />
    </q-page-container>
  </q-layout>
</template>

<script>
import BinOverview from '@/components/feed/BinOverview.vue';
import BinDetail from '@/components/feed/BinDetail.vue';
import FeederOverview from '@/components/feed/FeederOverview.vue';
import FeederDetail from '@/components/feed/FeederDetail.vue';
import PondOverview from '@/components/feed/PondOverview.vue';
import PondDetail from '@/components/feed/PondDetail.vue';
import FeedContracts from '@/components/feed/Contracts.vue';

import { decoder, cloneObj, smartCompare, formatDollars } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils.js';
import { date, uid } from 'quasar';
import store from '@/store';

export default {
  components: {
    BinOverview,
    BinDetail,
    FeederOverview,
    FeederDetail,
    PondOverview,
    PondDetail,
    FeedContracts
  },
  name: 'Settings-Bins',
  data() {
    return {
      formatDollars,
      parseTimeStamp,
      currentView: 'overview',
      selectedBin: {},
      selectedFeeder: {},
      selectedPond: {},
      usableHeight: 1000
    };
  },
  methods: {
    back() {
      this.currentView = 'overview';
    },
    goToBinEntries(bin) {
      this.selectedBin = cloneObj(bin);
      this.currentView = 'binEntries';
    },
    goToFeederEntries(feeder) {
      this.selectedFeeder = cloneObj(feeder);
      this.currentView = 'feederEntries';
    },
    goToPondEntries(pond) {
      this.selectedPond = cloneObj(pond);
      this.currentView = 'pondEntries';
    },
    goToBinNext() {
      const index = this.Bins.findIndex((x) => x.id === this.selectedBin.id);

      const bin = cloneObj(this.Bins[index + 1]);
      this.goToBinEntries(bin);
    },
    goToBinPrevious() {
      const index = this.Bins.findIndex((x) => x.id === this.selectedBin.id);

      const bin = cloneObj(this.Bins[index - 1]);
      this.goToBinEntries(bin);
    },
    goToPondNext() {
      const index = this.Ponds.findIndex((x) => x.id === this.selectedPond.id);

      const pond = cloneObj(this.Ponds[index + 1]);
      this.goToPondEntries(pond);
    },
    goToPondPrevious() {
      const index = this.Ponds.findIndex((x) => x.id === this.selectedPond.id);

      const pond = cloneObj(this.Ponds[index - 1]);
      this.goToPondEntries(pond);
    },
    onResize() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 50;
    }
  },
  computed: {
    Bins() {
      const bins = cloneObj(this.$store.state.farm.bins)
        .filter((x) => !x.archived)
        .sort((a, b) => smartCompare(a.name, b.name))
        .sort((a, b) => a.sort_order - b.sort_order)
        .map((bin) => {
          return bin;
        });

      return bins;
    },
    FeedDays() {
      return this.LastTenFeedAvg ? this.RemainingFeed / this.LastTenFeedAvg : 0;
    },
    GoToBin() {
      const index =
        this.Bins.findIndex((bin) => bin.id === this.selectedBin.id) ?? 0;

      const next = index < this.Bins.length - 1;
      const previous = index > 0;

      return { next, previous };
    },
    GoToPond() {
      const index =
        this.Ponds.findIndex((pond) => pond.id === this.selectedPond.id) ?? 0;

      const next = index < this.Ponds.length - 1;
      const previous = index > 0;

      return { next, previous };
    },
    LastTenFeedAvg() {
      let totalFeed = 0;
      const days = new Set();
      const daysMap = new Map();
      for (const pond of store.state.farm.ponds) {
        for (const entry of pond.feedings) {
          const feedDayTS = +new Date(
            date.startOfDate(new Date(entry.date * 1000), 'day')
          );

          if (daysMap.has(feedDayTS)) {
            daysMap.set(feedDayTS, daysMap.get(feedDayTS) + entry.pounds);
          } else {
            daysMap.set(feedDayTS, entry.pounds);
          }
        }
      }

      const daysSortedArr = Array.from(daysMap.keys())
        .sort()
        .reverse()
        .slice(0, 10);

      for (const day of daysSortedArr) {
        totalFeed += daysMap.get(day);
      }

      return Math.round(totalFeed / 10);
    },
    Ponds() {
      return cloneObj(this.$store.state.farm.ponds)
        .filter((x) => !x.archived)
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    RemainingFeed() {
      let feed = 0;
      for (const bin of this.$store.state.farm.bins) {
        const bal = parseFloat(bin.balance);
        feed += bal > 0 ? bal : 0;
      }

      for (const feeder of this.$store.state.farm.feeders) {
        const bal = parseFloat(feeder.balance);
        feed += bal > 0 ? bal : 0;
      }

      return feed;
    },
    Feeders() {
      return cloneObj(this.$store.state.farm.feeders)
        .filter((x) => !x.archived)
        .sort((a, b) => smartCompare(a.name, b.name));
    }
  },
  watch: {
    Bins() {
      this.selectedBin =
        this.$store.state.farm.bins.find((x) => x.id === this.selectedBin.id) ||
        {};
    },
    Feeders() {
      this.selectedFeeder =
        this.$store.state.farm.feeders.find(
          (x) => x.id === this.selectedFeeder.id
        ) || {};
    },
    Ponds() {
      this.selectedPond =
        this.$store.state.farm.ponds.find(
          (x) => x.id === this.selectedPond.id
        ) || {};
    }
  }
};
</script>

<style></style>
