<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/harvestsize.png"
          alt="Harvest Size"
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <q-separator color="black" />

    <div class="row items-center q-py-xs">
      <div class="col q-pl-sm">Harvest Size</div>
      <div class="col q-pl-sm">{{ HarvestSize }}</div>
    </div>
    <q-separator color="black" />

    <!-- Dialog Enter Harvest Size -->
    <q-dialog
      v-model="dialogHarvestSizeEntry"
      full-height
      persistent
      position="top"
    >
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Harvest Size</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <div
          class="scroll"
          :style="`max-height: ${store.state.pageHeight - 100}px`"
        >
          <q-form @submit="onSubmit" class="q-pa-md">
            <q-input
              outlined
              v-model="harvestSize"
              label="Harvest Size"
              class="q-mb-md"
              mask="#.##"
              fill-mask="0"
              hide-bottom-space
              :rules="[
                (val) => (val && parseFloat(val) >= 0.0) || 'Minimum value of 0'
              ]"
            />

            <div class="row justify-end">
              <q-btn label="Submit" type="submit" color="primary" />
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
// const newHarvestSize = () => {
//   return {
//     farm_id: store.getters.selectedFarm.id,
//     harvestSize: 0.0,
//     storeInfo: {}
//   };
// };
//
export default {
  name: 'Settings-HarvestSize',
  data() {
    return {
      dialogHarvestSizeEntry: false,
      harvestSize: 0.0,
      store
    };
  },
  methods: {
    edit() {
      this.harvestSize = this.HarvestSize;
      this.dialogHarvestSizeEntry = true;
    },
    onSubmit() {
      const settingsObj = cloneObj(this.$store.state.farm.settings);

      settingsObj.farm_id = store.getters.selectedFarm.id;
      settingsObj.harvest_size = this.harvestSize;
      settingsObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store.dispatch('updateFarmSettings', {
        farmSettings: settingsObj
      });

      this.dialogHarvestSizeEntry = false;
    },
    indexName(name) {
      return name ? name.substr(0, 3).toLowerCase() : '';
    }
  },
  computed: {
    HarvestSize() {
      return this.$store.state.farm.settings
        ? this.$store.state.farm.settings.harvest_size
        : 0;
    }
  }
};
</script>

<style scoped></style>
