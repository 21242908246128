<template>
  <div>
    <canvas :id="chartId" style="max-height: 150px"> </canvas>
  </div>
</template>

<script>
import {
  Chart,
  // ArcElement,
  // BarElement,
  // BarController,
  LineController,
  // PieController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

Chart.register(
  // ArcElement,
  // BarElement,
  // BarController,
  LineController,
  // PieController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

export default {
  name: 'Chart-Component',
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.setChart();
  },
  beforeDestroy() {
    this.chart.destroy();
  },
  methods: {
    setChart() {
      const ctx = document.getElementById(this.chartId);
      this.chart = new Chart(ctx, {
        type: 'line',
        data: this.chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // legend: {
            //   display: false
            // }
          }
        }
      });
    }
  },
  watch: {
    chartData() {
      this.chart.destroy();

      const ctx = document.getElementById(this.chartId);
      const newCanvas = document.createElement('canvas');
      newCanvas.id = this.chartId;
      ctx.replaceWith(newCanvas);

      this.setChart();
    }
  }
};
</script>

<style scoped></style>
