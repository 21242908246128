<template>
  <div>
    <div class="row items-center justify-end no-wrap q-py-sm q-px-sm">
      <div class="row items-center">
        <q-input
          outlined
          dense
          v-model="searchStr"
          label="Search"
          class="input-box q-mr-sm"
          stack-label
          :input-style="$q.screen.xs ? { width: '100px' } : { width: '140px' }"
        />
        <q-btn
          unelevated
          icon="agriculture"
          color="primary"
          class="q-mr-sm"
          @click="dialogEquipment = true"
        />
        <q-btn
          unelevated
          icon="filter_alt"
          size="md"
          color="primary"
          class="q-mr-sm"
          @click="dialogFilter = true"
        />
        <q-btn
          unelevated
          icon="add"
          size="md"
          color="primary"
          class="lt-sm"
          @click="addJournalEntry"
        />
        <q-btn
          unelevated
          icon="add"
          label="New Entry"
          size="md"
          color="primary"
          class="gt-xs"
          @click="addJournalEntry"
        />
      </div>
    </div>
    <q-separator />
    <div
      :style="`height: ${store.state.pageHeight - 103}px`"
      class="scroll q-pa-md"
    >
      <div v-if="dailyJournals.length === 0" class="text-center text-subtitle1">
        No journal entries to display.
      </div>

      <div
        v-for="(journal, index) in dailyJournals"
        :key="journal.id"
        class="row justify-center"
      >
        <q-card flat bordered class="q-mb-md q-pa-md" style="width: 600px">
          <div class="row justify-between">
            <div class="text-h6 text-primary q-pr-md">
              {{ journal.subject || '---' }}
            </div>
            <div class="row items-center">
              <q-icon
                v-if="journal.calendar_display"
                name="event"
                color="black"
                class="q-mr-xs"
              />

              <div>
                {{ new Date(journal.date * 1000).toLocaleDateString() }}
              </div>
            </div>
          </div>

          <div class="text-weight-medium text-accent">
            {{ getEquipmentName(journal.equipment_id) }}
          </div>

          <div class="q-pt-sm q-pb-sm">
            <span v-if="journal.keyword.length > 0">
              {{ journal.keyword }} -
            </span>

            <span v-if="expandedArr[index]">
              {{ journal.journal_entry }}
            </span>
            <span v-if="!expandedArr[index]">
              {{ journal.truncatedJournalEntry }}
            </span>

            <span v-if="journal.journal_entry.length > 200" class="q-pl-xs">
              ...
              <span
                class="text-primary cursor-pointer q-pl-xs"
                style="text-decoration: underline"
                @click="expandCollapse(index)"
              >
                <span v-if="!expandedArr[index]"> More </span>
                <span v-if="expandedArr[index]"> Less </span>
              </span>
            </span>
          </div>

          <div class="row justify-between items-end">
            <div class="col">
              <div v-if="journal.pond_ids[0] !== 'no_pond_selected'">
                <q-chip
                  v-for="pondId in journal.pond_ids"
                  :key="journal.id + pondId"
                  class="q-mr-sm q-ml-none q-mb-none q-mt-sm"
                  color="grey-3"
                >
                  {{ pondNames[pondId] }}
                </q-chip>
              </div>
            </div>

            <div
              class="col-auto row"
              :style="$q.screen.xs ? 'width: 73px' : 'width: 97px'"
            >
              <div class="q-pr-sm">
                <q-btn
                  unelevated
                  icon="image"
                  color="grey-3"
                  :text-color="
                    journal.attachments.length > 0 ? 'accent' : 'primary'
                  "
                  :padding="$q.screen.xs ? '3px 4px' : '3px 10px'"
                  @click="viewAttachments(journal)"
                />
              </div>
              <div>
                <q-btn
                  unelevated
                  icon="edit"
                  color="grey-3"
                  text-color="primary"
                  :padding="$q.screen.xs ? '3px 4px' : '3px 10px'"
                  @click="editJournalEntry(journal)"
                />
              </div>
            </div>
          </div>
        </q-card>
      </div>
      <div
        v-if="DailyJournals.length > numJournalEntries"
        class="row justify-center q-pt-md q-pb-lg"
      >
        <q-btn
          rounded
          outline
          icon="keyboard_arrow_down"
          label="View More"
          color="primary"
          style="width: 200px"
          @click="(numJournalEntries += 30), setDailyJournals()"
        />
      </div>
    </div>

    <!-- Dialog for Journal Entry -->
    <q-dialog persistent position="top" v-model="dialogJournalEntry">
      <q-card style="width: 450px">
        <div class="row justify-center q-py-sm">
          <div class="text-h6 text-primary text-center">Journal Entry</div>
        </div>
        <q-separator color="primary" />

        <q-form @submit="submitJournalEntry">
          <div class="q-pa-md">
            <q-select
              v-model="subjectSelected"
              outlined
              dense
              label="Select Subject"
              class="q-mb-md"
              :options="subjectOptions"
            />
            <q-select
              v-if="subjectSelected === 'Equipment Maintenance'"
              v-model="equipmentSelected"
              outlined
              dense
              label="Select Equipment"
              lazy-rules
              :rules="[(val) => val !== null || 'Equipment is required']"
              hide-bottom-space
              class="q-mb-md"
              :options="EquipmentList"
            />
            <q-input
              v-if="subjectSelected === 'Custom Subject'"
              v-model="customSubject"
              label="Custom Subject"
              class="q-mb-md"
              outlined
              dense
            />
            <q-input
              v-model="journalEntry.keyword"
              label="Keyword"
              class="q-mb-md"
              outlined
              dense
            />
            <q-input
              v-model="journalEntry.journal_entry"
              label="Journal Entry"
              class="q-mb-md"
              outlined
              dense
              type="textarea"
            />
            <q-input
              outlined
              dense
              label="Date"
              class="q-mb-md"
              v-model="journalEntry.date"
              lazy-rules
              :rules="[(val) => isDateValid(val) || 'Invalid Date']"
              hide-bottom-space
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer" color="primary">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date v-model="journalEntry.date" mask="MM/DD/YYYY">
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>

            <q-select
              v-model="journalEntry.pond_ids"
              label="Select Pond/s"
              outlined
              :options="pondIds"
              multiple
              @input="setPondSelection"
            >
              <template v-slot:selected>
                <div
                  v-if="
                    journalEntry.pond_ids[0] &&
                    (journalEntry.pond_ids[0] === 'all_ponds' ||
                      journalEntry.pond_ids[0] === 'no_pond_selected')
                  "
                >
                  <div v-if="journalEntry.pond_ids[0] === 'no_pond_selected'">
                    <div>No Pond Selected</div>
                  </div>
                  <div v-if="journalEntry.pond_ids[0] === 'all_ponds'">
                    <div>All Ponds</div>
                  </div>
                </div>
                <div v-else>
                  <q-chip
                    v-for="opt in journalEntry.pond_ids"
                    :key="opt"
                    removable
                    @remove="removePond(opt)"
                  >
                    {{ pondNames[opt] }}
                  </q-chip>
                </div>
              </template>
              <template v-slot:option="scope">
                <q-item
                  v-bind="scope.itemProps"
                  v-on="scope.itemEvents"
                  class="justify-between items-center"
                >
                  <div>
                    {{ pondNames[scope.opt] }}
                  </div>
                  <div>
                    <q-icon
                      v-if="journalEntry.pond_ids.includes(scope.opt)"
                      name="check"
                      size="20px"
                      color="primary"
                    />
                  </div>
                </q-item>
                <q-separator />
              </template>
            </q-select>
          </div>
          <q-separator />

          <div class="row justify-center q-py-sm">
            <q-toggle
              dense
              v-model="journalEntry.calendar_display"
              label="Display in Calendar"
              color="primary"
            />
          </div>
          <q-separator />

          <div class="row justify-end q-pa-md">
            <q-btn
              unelevated
              label="Cancel"
              color="grey-4"
              text-color="black"
              size="md"
              class="q-mr-sm"
              v-close-popup
            />
            <q-btn
              v-if="action === 'update'"
              unelevated
              label="Delete"
              color="grey-4"
              text-color="accent"
              class="q-mr-sm"
              @click="deleteJournalEntryConfirmA()"
            />
            <q-btn label="Submit" color="primary" type="submit" />
          </div>
        </q-form>
      </q-card>
    </q-dialog>

    <!-- filter dialog -->
    <q-dialog v-model="dialogFilter" full-height position="top">
      <q-card style="width: 450px">
        <div class="text-h6 text-center q-py-sm">Filter Journals</div>
        <q-separator color="primary" />

        <div
          class="scroll"
          :style="`max-height: ${store.state.pageHeight - 160}px`"
        >
          <div class="text-center text-subtitle1 text-primary q-py-sm">
            Subject Filter
          </div>
          <q-separator color="primary" />

          <div class="q-px-md">
            <q-btn
              unelevated
              icon="filter_list"
              color="accent"
              size="sm"
              class="q-mr-sm"
              padding="4px 8px"
              @click="quickFilter('subject', 'All Subjects')"
            />

            <q-checkbox
              v-model="allSubjectsFilter"
              color="primary"
              label="All Subjects"
              @input="setAllSubjectsFilter()"
            />
          </div>
          <q-separator />

          <div>
            <div
              v-for="(subject, index) in subjectOptions"
              :key="subject + index + 'subjectFilter'"
              class="q-px-md"
            >
              <q-btn
                unelevated
                icon="filter_list"
                color="accent"
                size="sm"
                class="q-mr-sm"
                padding="4px 8px"
                @click="quickFilter('subject', subject)"
              />

              <q-checkbox
                v-model="subjectOptionsFilter[index]"
                color="primary"
                :label="subject"
              />
            </div>
          </div>
          <q-separator color="primary" />

          <div class="text-center text-subtitle1 text-primary q-py-sm">
            Pond Filter
          </div>
          <q-separator color="primary" />

          <div class="q-px-md">
            <q-btn
              unelevated
              icon="filter_list"
              color="accent"
              size="sm"
              class="q-mr-sm"
              padding="4px 8px"
              @click="quickFilter('pond', 'No Pond Selected')"
            />

            <q-checkbox
              v-model="noPondSelected"
              label="No Pond Selected"
              color="primary"
              @input="setPondFilterList()"
            />
          </div>
          <q-separator />

          <div class="q-px-md">
            <q-btn
              unelevated
              icon="filter_list"
              color="accent"
              size="sm"
              class="q-mr-sm"
              padding="4px 8px"
              @click="quickFilter('pond', 'All Ponds')"
            />

            <q-checkbox
              v-model="allPondsFilter"
              label="All Ponds"
              color="primary"
              @input="setPondFilterList()"
            />
          </div>
          <q-separator />
          <div class="q-px-md q-pb-sm">
            <div
              v-for="pond in filteredPonds"
              :key="pond.id + 'pondFilter'"
              clickable
            >
              <q-btn
                unelevated
                icon="filter_list"
                color="accent"
                size="sm"
                class="q-mr-sm"
                padding="4px 8px"
                @click="quickFilter('pond', pond.id)"
              />

              <q-checkbox
                v-model="pond.checked"
                color="primary"
                :label="pond.name"
              />
            </div>
          </div>
          <q-separator color="primary" />

          <div class="text-center text-subtitle1 text-primary q-py-sm">
            Equipment Filter
          </div>
          <q-separator color="primary" />

          <div class="q-px-md">
            <q-btn
              unelevated
              icon="filter_list"
              color="accent"
              size="sm"
              class="q-mr-sm"
              padding="4px 8px"
              @click="quickFilter('equipment', 'All Equipment')"
            />

            <q-checkbox
              v-model="allEquipmentFilter"
              label="All Equipment"
              color="primary"
              @input="setEquipmentFilterList()"
            />
          </div>
          <q-separator />

          <div class="q-px-md q-pb-sm">
            <div
              v-for="equipment in equipmentFilter"
              :key="equipment.id + 'equipmentFilter'"
              clickable
            >
              <q-btn
                unelevated
                icon="filter_list"
                color="accent"
                size="sm"
                class="q-mr-sm"
                padding="4px 8px"
                @click="quickFilter('equipment', equipment.id)"
              />

              <q-checkbox
                v-model="equipment.checked"
                color="primary"
                :label="equipment.label"
              />
            </div>
          </div>
        </div>
        <q-separator color="primary" />

        <div class="row justify-end q-pa-md">
          <q-btn label="apply" color="primary" v-close-popup />
        </div>
      </q-card>
    </q-dialog>

    <!-- filter dialog -->
    <q-dialog v-model="dialogEquipment" full-height position="top">
      <q-card style="width: 450px">
        <div class="row q-pa-sm">
          <div style="width: 56px"></div>
          <div class="col text-h6 text-center text-primary">Equipment</div>
          <div>
            <q-btn
              unelevated
              color="primary"
              icon="close"
              size="md"
              v-close-popup
            />
          </div>
        </div>
        <q-separator />

        <Equipment />
      </q-card>
    </q-dialog>

    <!-- attachment display -->
    <Attachments
      v-if="displayAttachments"
      :parentObj="journalEntry"
      :dailyJournalId="journalEntry.id"
      :parentType="'dailyJournal'"
      @closeAttachments="displayAttachments = false"
    />
  </div>
</template>

<script>
import Attachments from '@/components/general/Attachments.vue';
import Equipment from '@/components/dailyJournal/Equipment.vue';

import { smartCompare } from '@/lib/helpers';
import { date } from 'quasar';
import { parseTimeStamp, isDateValid, setTimeOfDay } from '@/lib/date-utils.js';
import { cloneObj, upperCaseFirst } from '@/lib/helpers';
import { getUID, guidMatchHelper } from '@/store/transactionHelpers';
import store from '@/store';

const defaultJournalEntry = () => {
  return {
    id: null,
    farm_id: store.state.farm.id,
    pond_ids: ['no_pond_selected'],
    subject: '',
    journal_entry: '',
    keyword: '',
    date: date.formatDate(new Date(), 'MM/DD/YYYY'),
    calendar_display: false,
    equipment_id: null,
    guid: null,
    attachments: [],
    storeInfo: {
      farmId: store.state.selectedFarm.farm_id,
      parentType: 'dailyJournal',
      dailyJournalId: null
    }
  };
};

export default {
  name: 'DailyJournal',
  components: {
    Attachments,
    Equipment
  },
  data() {
    return {
      action: 'create',
      allEquipmentFilter: true,
      allPondsFilter: true,
      allSubjectsFilter: true,
      customSubject: '',
      dailyJournals: [],
      dateCopy: null,
      dateCopyStr: '',
      dialogEquipment: false,
      dialogFilter: false,
      dialogJournalEntry: false,
      displayAttachments: false,
      equipmentFilter: [],
      equipmentSelected: null,
      expandedArr: [],
      filteredPonds: [],
      isDateValid,
      journalEntry: defaultJournalEntry(),
      noPondSelected: true,
      numJournalEntries: 30,
      parseTimeStamp,
      pondIds: [],
      pondList: [],
      pondNames: {},
      searchStr: '',
      store,
      subjectOptions: [
        'Farm Notes - General',
        'Equipment Maintenance',
        'Water Analysis',
        'Fish Health',
        'Re-seine / Cleanout',
        'Custom Subject'
      ],
      subjectOptionsFilter: [true, true, true, true, true, true],
      subjectSelected: 'Farm Notes - General'
    };
  },
  mounted() {
    this.setPondList();
    this.setEquipmentFilterList();
  },
  methods: {
    addJournalEntry() {
      this.action = 'create';
      this.journalEntry = defaultJournalEntry();
      this.subjectSelected = 'Farm Notes - General';
      this.customSubject = '';

      this.dialogJournalEntry = true;
    },
    deleteJournalEntryConfirmA() {
      if (this.journalEntry.attachments.length > 0) {
        this.$q.dialog({
          title: 'Attachments Not Deleted',
          message: `This journal entry has attachments. Attachments must be
              deleted before the journal entry can be deleted.`,
          ok: {
            label: 'OK',
            color: 'primary'
          },
          focus: 'none',
          persistent: true
        });

        return;
      }

      this.deleteJournalEntryConfirmB();
    },
    deleteJournalEntryConfirmB() {
      this.$q
        .dialog({
          title: 'Confirm Delete',
          message: `Are you sure you want to delete this journal entry
            from ${this.journalEntry.date}?`,
          ok: {
            icon: 'delete',
            label: 'Delete',
            color: 'accent'
          },
          cancel: {
            label: 'Cancel',
            color: 'grey-3',
            textColor: 'primary'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.action = 'delete';

          this.submitJournalEntry();
        });
    },
    editJournalEntry(journalEntry) {
      this.action = 'update';

      this.journalEntry = {
        ...defaultJournalEntry(),
        ...cloneObj(journalEntry)
      };

      this.journalEntry.calendar_display = journalEntry.calendar_display === 1;

      this.journalEntry.storeInfo.dailyJournalId = journalEntry.id;

      this.subjectSelected = this.subjectOptions.includes(journalEntry.subject)
        ? journalEntry.subject
        : 'Custom Subject';

      this.customSubject = journalEntry.subject;

      this.equipmentSelected =
        this.EquipmentList.find((x) => x.value === journalEntry.equipment_id) ||
        null;

      this.dateCopy = this.journalEntry.date;
      this.journalEntry.date = date.formatDate(
        new Date(journalEntry.date * 1000),
        'MM/DD/YYYY'
      );
      this.dateCopyStr = this.journalEntry.date;

      this.dialogJournalEntry = true;
    },
    expandCollapse(index) {
      this.$set(this.expandedArr, index, !this.expandedArr[index]);
    },
    getEquipmentName(equipmentId) {
      if (!equipmentId) {
        return '';
      }

      const equipment = this.EquipmentList.find((x) => x.value === equipmentId);

      return equipment ? equipment.label : '';
    },
    quickFilter(type, filter) {
      if (type === 'subject') {
        if (filter === 'All Subjects') {
          this.allSubjectsFilter = true;
          this.subjectOptionsFilter = this.subjectOptionsFilter.map(() => true);
        }

        if (filter !== 'All Subjects') {
          this.allSubjectsFilter = false;
          this.subjectOptionsFilter = this.subjectOptionsFilter.map(
            () => false
          );

          const index = this.subjectOptions.indexOf(filter);
          this.subjectOptionsFilter[index] = true;
        }

        this.dialogFilter = false;
      }

      if (type === 'pond') {
        if (filter === 'No Pond Selected') {
          this.noPondSelected = true;
          this.allPondsFilter = false;
          this.setPondFilterList();
        } else if (filter === 'All Ponds') {
          this.noPondSelected = true;
          this.allPondsFilter = true;
          this.setPondFilterList();
        } else {
          this.allPondsFilter = false;
          this.noPondSelected = false;
          this.setPondFilterList();

          const index = this.filteredPonds.findIndex((x) => x.id === filter);
          this.filteredPonds[index].checked = true;
        }

        this.dialogFilter = false;
      }

      if (type === 'equipment') {
        if (filter === 'All Equipment') {
          this.allEquipmentFilter = true;
          this.equipmentFilter = this.equipmentFilter.map((x) => {
            x.checked = true;
            return x;
          });
        } else {
          this.allEquipmentFilter = false;
          this.equipmentFilter = this.equipmentFilter.map((x) => {
            x.checked = false;
            return x;
          });

          const index = this.equipmentFilter.findIndex((x) => x.id === filter);
          this.equipmentFilter[index].checked = true;
        }

        this.dialogFilter = false;
      }
    },
    removePond(pondId) {
      this.journalEntry.pond_ids = this.journalEntry.pond_ids.filter(
        (x) => x !== pondId
      );

      this.setPondSelection();
    },
    setAllSubjectsFilter() {
      this.subjectOptionsFilter = this.subjectOptionsFilter.map(
        (x) => this.allSubjectsFilter
      );
    },
    setDailyJournals() {
      this.dailyJournals = cloneObj(
        this.DailyJournals.slice(0, this.numJournalEntries)
      );

      for (let i = 0; i < this.dailyJournals.length; i++) {
        const journal = this.dailyJournals[i];

        this.expandedArr.push(false);
        journal.truncatedJournalEntry = journal.journal_entry.slice(0, 200);
      }
    },
    setEquipmentFilterList() {
      this.equipmentFilter = [];

      for (const equipment of this.EquipmentList) {
        this.equipmentFilter.push({
          label: equipment.label,
          id: equipment.value,
          checked: this.allEquipmentFilter
        });
      }
    },
    setPondFilterList() {
      this.filteredPonds = [];

      for (const pond of this.Ponds) {
        const pondObj = cloneObj(pond);
        pondObj.checked = this.allPondsFilter;
        this.filteredPonds.push(pondObj);
      }
    },
    setPondList() {
      this.pondList = [];
      this.pondIds = [];
      this.pondNames = {};

      this.pondList.push(
        { label: 'No Pond Selected', value: 'no_pond_selected' },
        { label: 'All Ponds', value: 'all_ponds' }
      );
      this.pondIds.push('no_pond_selected');
      this.pondIds.push('all_ponds');
      this.pondNames['no_pond_selected'] = 'No Pond Selected';
      this.pondNames['all_ponds'] = 'All Ponds';

      for (const pond of this.Ponds) {
        const pondObj = cloneObj(pond);
        pondObj.value = pond.id;
        this.pondList.push(pondObj);
        this.pondIds.push(pond.id);
        this.pondNames[pond.id] = pond.name;
      }

      this.setPondFilterList();
    },
    setPondSelection() {
      if (this.journalEntry.pond_ids.length === 0) {
        this.journalEntry.pond_ids = ['no_pond_selected'];
        return;
      }

      const selected =
        this.journalEntry.pond_ids[this.journalEntry.pond_ids.length - 1];

      if (selected === 'no_pond_selected') {
        this.journalEntry.pond_ids = ['no_pond_selected'];
      } else if (selected === 'all_ponds') {
        this.journalEntry.pond_ids = ['all_ponds'];
      } else {
        this.journalEntry.pond_ids = this.journalEntry.pond_ids.filter(
          (x) => x !== 'no_pond_selected'
        );
        this.journalEntry.pond_ids = this.journalEntry.pond_ids.filter(
          (x) => x !== 'all_ponds'
        );
      }
    },
    submitJournalEntry() {
      const journalEntry = cloneObj(this.journalEntry);

      journalEntry.subject =
        this.subjectSelected === 'Custom Subject'
          ? upperCaseFirst(this.customSubject)
          : this.subjectSelected;

      const dateStr = this.journalEntry.date;
      journalEntry.date = setTimeOfDay(dateStr);

      journalEntry.keyword = journalEntry.keyword.toUpperCase();

      journalEntry.calendar_display = journalEntry.calendar_display ? 1 : 0;

      journalEntry.equipment_id =
        this.subjectSelected === 'Equipment Maintenance'
          ? this.equipmentSelected.value
          : null;

      if (
        (this.action === 'update' || this.action === 'delete') &&
        this.dateCopyStr === dateStr
      ) {
        journalEntry.date = this.dateCopy;
      }

      if (this.action === 'create') {
        journalEntry.id = 0;
      }

      journalEntry.guid = getUID();
      journalEntry.user_id = store.state.user.user_id;
      journalEntry.storeInfo.action = this.action + 'JournalEntry';
      journalEntry.storeInfo.category = 'dailyJournals';

      guidMatchHelper(journalEntry, this.action);

      store.dispatch('publish', journalEntry);

      this.dialogJournalEntry = false;
    },
    viewAttachments(journalEntry) {
      this.journalEntry = {
        ...defaultJournalEntry(),
        ...cloneObj(journalEntry)
      };

      this.journalEntry.storeInfo.dailyJournalId = journalEntry.id;

      this.displayAttachments = true;
    }
  },
  computed: {
    DailyJournals() {
      const journals = cloneObj(this.$store.state.dailyJournals);

      return journals
        .filter((x) => {
          if (this.allPondsFilter && x.pond_ids.includes('all_ponds')) {
            return true;
          }

          if (this.noPondSelected && x.pond_ids.includes('no_pond_selected')) {
            return true;
          }

          return x.pond_ids.some((y) => {
            return this.filteredPonds.some((z) => z.id === y && z.checked);
          });
        })
        .filter((x) => {
          const customSubjectIndex =
            this.subjectOptions.indexOf('Custom Subject');

          const subjectIndex = this.subjectOptions.indexOf(x.subject);

          if (
            this.subjectOptionsFilter[customSubjectIndex] &&
            subjectIndex === -1
          ) {
            const isCustomSubject = !this.subjectOptions.includes(x.subject);

            return isCustomSubject;
          }

          return this.subjectOptionsFilter[subjectIndex];
        })
        .filter((x) => {
          const subject = this.subjectOptions.find((y) => y === x.subject);

          if (this.allEquipmentFilter) {
            return true;
          }

          return this.equipmentFilter.some((y) => {
            return y.checked && y.id === x.equipment_id;
          });
        })
        .filter((x) => {
          if (this.searchStr === '') {
            return true;
          }

          const searchStr = this.searchStr.toLowerCase();

          return (
            x.journal_entry.toLowerCase().includes(searchStr) ||
            x.keyword.toLowerCase().includes(searchStr) ||
            x.subject.toLowerCase().includes(searchStr)
          );
        })
        .sort((a, b) => b.date - a.date);
    },
    EquipmentList() {
      const equipmentList = [];

      for (const equipment of store.state.equipmentList) {
        equipmentList.push({
          label: equipment.name,
          value: equipment.id
        });
      }

      return equipmentList;
    },
    Ponds() {
      const singleLocation = this.SelectedLocation === 'SINGLE_LOCATION';

      return this.$store.state.farm.ponds
        .filter(
          (x) =>
            (x.location_name === this.SelectedLocation || singleLocation) &&
            !x.archived
        )
        .sort((a, b) => smartCompare(a.name, b.name));
    },
    SelectedLocation() {
      return this.$store.state.selectedLocation;
    }
  },
  watch: {
    EquipmentList() {
      this.setEquipmentFilterList();
    },
    DailyJournals() {
      this.setDailyJournals();

      //replace this.journalEntry with the updated journal entry
      if (this.journalEntry.storeInfo.dailyJournalId) {
        const journalEntry = this.dailyJournals.find(
          (x) => x.id === this.journalEntry.storeInfo.dailyJournalId
        );

        if (journalEntry) {
          this.journalEntry = {
            ...defaultJournalEntry(),
            ...journalEntry
          };

          this.journalEntry.storeInfo.dailyJournalId = journalEntry.id;
        }
      }
    },
    Ponds() {
      this.setPondList();
    }
  }
};
</script>

<style scoped>
:deep(.input-box .q-field__control),
:deep(.input-box .q-field__marginal) {
  height: 36px;
  font-size: 16px;
}
</style>
