import store from '@/store';
import connector from '@/services/mqtt/connector';

if (!localStorage.getItem('offlineQueue')) {
  localStorage.setItem('offlineQueue', JSON.stringify([...new Map()]));
}

let republishing = false;
const republish = (offlineQueue) => {
  republishing = true;
  setTimeout(async () => {
    for (const [key, value] of offlineQueue) {
      const info = value.storeInfo;

      const results = await connector.publish(
        `farm/${info.farmId}/${info.category}/${value.id}/${info.action}/`,
        JSON.stringify(value)
      );

      if (results.sent) {
        deleteFromOfflineQueue(key);
      }

      if (results.sent === false || results.reasonCode !== 0) {
        console.error(results);
      }

      if (
        results.reasonCode !== 0 &&
        results.sent &&
        results.error !== 'Guid has already been processed'
      ) {
        console.error(results);

        store.dispatch('setErrorAlert', true);
      }
    }

    republishing = false;
    updateQueueAndRepublish();
  }, 3000);
};

const deleteFromOfflineQueue = (key) => {
  const offlineQueue = new Map(
    JSON.parse(localStorage.getItem('offlineQueue'))
  );

  offlineQueue.delete(key);

  localStorage.setItem('offlineQueue', JSON.stringify([...offlineQueue]));
};

const updateQueueAndRepublish = () => {
  const offlineQueue = new Map(
    JSON.parse(localStorage.getItem('offlineQueue'))
  );

  if (offlineQueue.size > 0) {
    if (!republishing) {
      republish(offlineQueue);
    }
  }
};
// Call on page load
updateQueueAndRepublish();

const addToOfflineQueue = (item) => {
  const offlineQueue = new Map(
    JSON.parse(localStorage.getItem('offlineQueue'))
  );

  offlineQueue.set(item.guid, item);

  localStorage.setItem('offlineQueue', JSON.stringify([...offlineQueue]));
};

export { addToOfflineQueue, deleteFromOfflineQueue, updateQueueAndRepublish };
