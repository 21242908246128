var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row items-center q-py-sm"},[_c('div',{staticClass:"col-auto q-pl-sm",staticStyle:{"width":"70px"}},[_vm._v(" "+_vm._s(_vm.parseTimeStamp(_vm.entry.date, 'short'))+" ")]),_c('div',{staticClass:"col q-px-sm"},[(_vm.toggle === 'feedings' && _vm.entry.pond_name)?_c('div',{staticClass:"text-center"},[_c('div',{class:_vm.$q.screen.lt.md && 'text-caption'},[_vm._v(" "+_vm._s(_vm.decoder(_vm.entry.pond_name))+" ")]),_c('div',{staticClass:"lt-md",class:_vm.$q.screen.lt.md && 'text-caption'},[_vm._v(" "+_vm._s(_vm.entry.feed_type_name)+" ")])]):_vm._e(),(_vm.toggle === 'fillUps' && _vm.entry.bin_name)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"row flex-center",class:_vm.$q.screen.lt.md && 'text-caption'},[_vm._v(" "+_vm._s(_vm.decoder(_vm.entry.bin_name))+" ")]),_c('div',{staticClass:"lt-md",class:_vm.$q.screen.lt.md && 'text-caption'},[_vm._v(" "+_vm._s(_vm.entry.feed_type_name)+" ")])]):_vm._e(),(
          _vm.toggle === 'fillUps' &&
          !_vm.entry.bin_name &&
          _vm.entry.to_account !== _vm.feeder.grain_id
        )?_c('div',{staticClass:"text-accent text-center"},[_vm._v(" Reconcile/Loss ")]):_vm._e(),(
          _vm.toggle === 'fillUps' &&
          !_vm.entry.bin_name &&
          _vm.entry.to_account === _vm.feeder.grain_id
        )?_c('div',{staticClass:"text-primary text-center"},[_vm._v(" Reconcile/Gain ")]):_vm._e()]),_c('div',{staticClass:"col-auto text-center q-px-xs",staticStyle:{"width":"72px"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas(_vm.entry.pounds))+" ")]),_c('div',{staticClass:"gt-sm col text-center q-px-sm"},[_vm._v(" "+_vm._s(_vm.entry.feed_type_name)+" ")]),_c('div',{staticClass:"gt-xs col text-center q-px-sm"},[_vm._v(" "+_vm._s(_vm.entry.memo)+" ")]),_c('div',{staticClass:"lt-sm col-auto q-pr-sm",staticStyle:{"width":"40px"}},[(_vm.entry.memo)?_c('q-btn',{attrs:{"unelevated":"","color":"grey-4","text-color":"black","size":"sm","padding":"6px"},on:{"click":function($event){return _vm.displayNote(_vm.entry.memo)}}},[_c('q-icon',{attrs:{"name":"sticky_note_2","size":"20px"}})],1):_vm._e()],1),(_vm.toggle === 'fillUps')?_c('div',{staticClass:"col-auto q-pr-sm"},[_c('q-btn',{attrs:{"unelevated":"","disable":!_vm.BinGrainIds.includes(_vm.entry.from_account),"color":"grey-4","text-color":"accent","size":"sm","padding":_vm.$q.screen.gt.sm ? '2px 8px' : '6px'},on:{"click":function($event){return _vm.$emit('edit', _vm.entry)}}},[_c('q-icon',{attrs:{"name":"edit","size":"20px"}})],1)],1):_vm._e()]),_c('q-separator'),_c('q-dialog',{attrs:{"persistent":"","position":"top"},model:{value:(_vm.dialogReconcile),callback:function ($$v) {_vm.dialogReconcile=$$v},expression:"dialogReconcile"}},[_c('q-card',{staticStyle:{"width":"450px"}},[_c('div',{staticClass:"row items-center q-pa-sm"},[_c('q-space'),_c('div',{staticClass:"text-subtitle1"},[_vm._v(" "+_vm._s(_vm.parseTimeStamp(_vm.entry.date, 'short'))+" ")]),_c('q-space'),_c('div',[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"clear","color":"primary"}})],1)],1),_c('q-separator'),_c('div',{staticClass:"q-pa-md"},[_c('span',{staticClass:"text-bold"},[_vm._v(" Note: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.entry.memo)+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }