<template>
  <div>
    <div class="row items-center q-py-sm">
      <div class="col-auto q-pl-sm" style="width: 70px">
        {{ parseTimeStamp(entry.date, 'short') }}
      </div>

      <div class="col q-px-sm">
        <div
          v-if="toggle === 'fillUps' && entry.feeder_name"
          class="text-center"
        >
          <div :class="$q.screen.lt.md && 'text-caption'">
            {{ entry.feeder_name }}
          </div>
          <div class="lt-md" :class="$q.screen.lt.md && 'text-caption'">
            {{ entry.feed_type_name }}
          </div>
        </div>

        <div
          v-if="toggle === 'deliveries' && entry.mill_name"
          class="text-center"
        >
          <div
            :class="$q.screen.lt.md && 'text-caption'"
            class="row flex-center"
            style="word-break: break-all"
          >
            {{ entry.mill_name }}
            <span v-if="entry.feed_contract_id" class="gt-xs q-pl-sm">
              <q-btn flat icon="info" color="primary" size="sm" padding="none">
                <q-tooltip>
                  <div>
                    <div class="text-h6">Contract Details</div>
                    <div class="text-caption text-center">
                      {{ parseTimeStamp(entry.contract_date, 'short') }}
                    </div>
                    <div class="text-caption text-center">
                      {{ entry.contract_mill_name }}
                    </div>
                    <div class="text-caption text-center">
                      {{ parseFloat(entry.contract_tons).toFixed(2) }} tons @
                      ${{ parseFloat(entry.contract_price_per_ton).toFixed(2) }}
                    </div>
                    <div class="text-caption text-center">
                      Notes:
                      {{ entry.contract_notes }}
                    </div>
                  </div>
                </q-tooltip>
              </q-btn>
            </span>
          </div>
          <div class="lt-md" :class="$q.screen.lt.md && 'text-caption'">
            {{ entry.feed_type_name }}
          </div>
        </div>

        <div
          v-if="
            toggle === 'deliveries' &&
            !entry.mill_name &&
            entry.to_account !== bin.grain_id
          "
          class="text-accent text-center"
          style="word-break: break-all"
        >
          Reconcile/Loss
        </div>
        <div
          v-if="
            toggle === 'deliveries' &&
            !entry.mill_name &&
            entry.to_account === bin.grain_id
          "
          class="text-primary text-center"
        >
          Reconcile/Gain
        </div>
      </div>

      <div class="col-auto text-center q-px-xs" style="width: 72px">
        {{ numberWithCommas(entry.pounds) }}
      </div>

      <div class="gt-sm col text-center q-px-sm">
        {{ entry.feed_type_name }}
      </div>

      <div class="gt-xs col text-center q-px-sm">
        {{ entry.memo }}
      </div>

      <div class="lt-sm col-auto q-pr-xs" style="width: 36px">
        <q-btn
          v-if="entry.memo"
          unelevated
          icon="sticky_note_2"
          color="grey-3"
          text-color="black"
          :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
          @click="dialogNotes = true"
        />
      </div>

      <div v-if="toggle === 'deliveries'" class="col-auto q-pr-xs">
        <q-btn
          unelevated
          :icon="entry.attachments.length ? 'image' : 'add'"
          color="grey-3"
          text-color="primary"
          :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
          @click="viewAttachments"
        />
      </div>

      <div v-if="toggle === 'deliveries'" class="col-auto q-pr-sm">
        <q-btn
          unelevated
          icon="edit"
          color="grey-3"
          text-color="accent"
          :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
          @click="$emit('edit', entry)"
        />
      </div>
    </div>
    <q-separator />

    <!-- Notes Dialog -->
    <q-dialog persistent position="top" v-model="dialogNotes">
      <q-card style="width: 450px">
        <div class="row items-center q-pa-sm">
          <q-space />
          <div class="text-subtitle1">
            {{ parseTimeStamp(entry.date, 'short') }}
          </div>
          <q-space />
          <div>
            <q-btn icon="clear" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />
        <div class="q-pa-md">
          <span class="text-bold"> Note: </span>
          <span>
            {{ entry.memo }}
          </span>
        </div>
      </q-card>
    </q-dialog>

    <!-- attachment display -->
    <Attachments
      v-if="displayAttachments"
      :parentObj="binEntry"
      :transactionId="entry.id"
      :binGrainId="bin.grain_id"
      :parentType="'binDelivery'"
      @requeryDates="$emit('requeryDates')"
      @closeAttachments="displayAttachments = false"
    />
  </div>
</template>

<script>
import Attachments from '@/components/general/Attachments.vue';

import { cloneObj, numberWithCommas } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils.js';

export default {
  name: 'BinEntries',
  components: {
    Attachments
  },
  props: {
    bin: {
      type: Object,
      required: true
    },
    entry: {
      type: Object,
      required: true
    },
    toggle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      binEntry: {},
      dialogNotes: false,
      displayAttachments: false,
      numberWithCommas,
      parseTimeStamp
    };
  },
  methods: {
    viewAttachments() {
      this.binEntry = cloneObj(this.entry);
      this.displayAttachments = true;
    }
  },
  watch: {
    entry: {
      deep: true,
      handler() {
        this.binEntry = cloneObj(this.entry);
      }
    }
  }
};
</script>

<style></style>
