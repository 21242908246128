<template>
  <div>
    <div style="height: 190px">
      <div class="dashIcon">
        <img
          src="/images/conversionrates.png"
          alt="FCR"
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <q-separator color="black" />

    <div style="overflow: auto">
      <div class="row no-wrap items-center q-py-xs">
        <div class="col q-pl-sm" style="min-width: 60px">Name</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.06 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.10 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.30 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.60 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">0.75 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">1.00 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">2.00 lb</div>
        <div class="col q-pl-sm" style="min-width: 60px">3.00 lb</div>
        <div class="col q-pl-sm" style="min-width: 100px">Average @ 2.0</div>
        <div
          class="col-auto"
          :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
        ></div>
      </div>

      <q-separator color="black" />

      <div v-for="fcr in Fcrs" :key="fcr.id">
        <div class="row no-wrap items-center">
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ decoder(fcr.name) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s0_06.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s0_10.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s0_30.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s0_60.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s0_75.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s1_00.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s2_00.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 60px">
            {{ fcr.s3_00.toFixed(2) }}
          </div>
          <div class="col q-pl-sm" style="min-width: 100px">
            {{ fcr.average }}
          </div>
          <div
            class="col-auto q-pa-sm"
            :style="`width: ${$q.screen.xs ? '88' : '112'}px`"
          >
            <q-btn
              unelevated
              icon="edit"
              size="md"
              :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
              color="grey-3"
              text-color="primary"
              class="q-mr-sm"
              @click="editFcr(fcr)"
            >
            </q-btn>
            <q-btn
              unelevated
              color="grey-3"
              text-color="accent"
              icon="delete"
              size="md"
              :padding="$q.screen.xs ? '2px 4px' : '2px 10px'"
              @click="deleteFcr(fcr)"
            />
          </div>
        </div>
        <q-separator />
      </div>
    </div>

    <!-- Dialog Enter fcr -->
    <q-dialog v-model="dialogFcrEntry" full-height persistent position="top">
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h5">Conversion Rates</div>
          <div class="col-auto">
            <q-btn icon="close" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <div
          class="scroll"
          :style="`max-height: ${store.state.pageHeight - 100}px`"
        >
          <q-form @submit="onSubmit" class="q-pa-md">
            <q-input
              outlined
              v-model="fcrObj.name"
              label="Name"
              maxlength="32"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Name cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s0_06"
              label="0.06 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s0_10"
              label="0.10 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s0_30"
              label="0.30 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s0_60"
              label="0.60 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s0_75"
              label="0.75 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s1_00"
              label="1.00 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s2_00"
              label="2.00 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <q-input
              outlined
              v-model.number="fcrObj.s3_00"
              label="3.00 lb"
              type="number"
              step="0.01"
              class="q-mb-md"
              hide-bottom-space
              :rules="[(value) => value !== '' || 'Value cannot be blank']"
            />

            <div class="row justify-end">
              <q-btn label="Submit" type="submit" color="primary" />
            </div>
          </q-form>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { decoder, cloneObj, upperCaseFirst } from '@/lib/helpers';
import store from '@/store';
const newfcr = () => {
  return {
    id: 0,
    farm_id: store.getters.selectedFarm.id,
    name: '',
    s0_06: 1.15,
    s0_10: 1.35,
    s0_30: 1.5,
    s0_60: 1.7,
    s0_75: 1.85,
    s1_00: 1.95,
    s2_00: 2.1,
    s3_00: 2.3,
    storeInfo: {}
  };
};

export default {
  name: 'Settings-fcrs',
  data() {
    return {
      decoder,
      dialogFcrEntry: false,
      fcrObj: newfcr(),
      store
    };
  },
  methods: {
    add() {
      this.fcrObj = newfcr();

      this.dialogFcrEntry = true;
    },
    deleteFcr(fcr) {
      fcr.storeInfo = {
        farmId: store.state.farm.id
      };

      this.$q
        .dialog({
          title: 'Delete conversion rate',
          message: `Are you sure you want to delete this conversion rate?`,
          ok: {
            color: 'accent',
            label: 'Delete',
            icon: 'delete'
          },
          cancel: {
            color: 'grey-2',
            textColor: 'primary',
            label: 'cancel',
            icon: 'close'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteFcrConfirmed(fcr);
        });
    },
    deleteFcrConfirmed(fcr) {
      store
        .dispatch('deleteFCR', {
          fcr
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    editFcr(fcr) {
      this.fcrObj = cloneObj(fcr);
      this.fcrObj.name = decoder(this.fcrObj.name);
      this.dialogFcrEntry = true;
    },
    onSubmit() {
      let dispatch;
      if (this.fcrObj.id === 0) {
        dispatch = 'createFCR';
      } else {
        dispatch = 'updateFCR';
      }

      this.fcrObj.name = upperCaseFirst(this.fcrObj.name);

      this.fcrObj.storeInfo = {
        farmId: store.state.farm.id
      };

      store
        .dispatch(dispatch, {
          fcr: this.fcrObj
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });

      this.dialogFcrEntry = false;
    }
  },
  computed: {
    Fcrs() {
      let fcrs = cloneObj(this.$store.state.farm.fcr);
      console.log(fcrs);

      // calc average
      for (const fcr of fcrs) {
        let average = 0.020619 * fcr.s0_10;
        average += parseFloat(0.103093 * fcr.s0_30);
        average += parseFloat(0.154639 * fcr.s0_60);
        average += parseFloat(0.07732 * fcr.s0_75);
        average += parseFloat(0.128866 * fcr.s1_00);
        average += parseFloat(0.515464 * fcr.s2_00);
        // round to 2 decimal places
        fcr.average = Math.round(average * 100) / 100;
      }

      return fcrs.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>

<style scoped></style>
