<template>
  <q-dialog v-model="showDialog" persistent>
    <q-card class="q-pa-md outer-card">
      <div
        class="row items-center q-ma-md q-pa-xs row items-center rounded-borders"
      >
        <q-icon :name="icon" size="md" class="q-ml-sm" />
        <div class="q-ml-sm">
          {{ title }}
        </div>
      </div>
      <q-card-section class="row items-center">
        <span class="q-px-xs q-pb-sm"> {{ message }} </span>
      </q-card-section>

      <q-card-actions align="right" class="q-mr-sm">
        <q-btn
          flat
          label="Cancel"
          icon="clear"
          color="primary"
          v-close-popup
          @click="confirmCancel"
        />
        <q-btn
          label="Delete"
          icon="delete"
          color="negative"
          v-close-popup
          @click="confirmDelete"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import DeleteConfirm from './DeleteConfirm';

export default {
  name: 'DeleteConfirm',
  data () {
    return {
      showDialog: false,
      onCancel: null,
      onDelete: null,
      icon: '',
      title: '',
      message: ''
    };
  },
  methods: {
    confirmCancel () {
      this.onCancel();
    },
    confirmDelete () {
      this.onDelete();
    },
    getResultsAsync (icon, title, message, resolve, reject) {
      this.onDelete = function () {
        resolve('delete');
      };
      this.onCancel = function () {
        resolve('cancel');
      };
      this.icon = icon;
      this.message = message;
      this.title = title;
      this.showDialog = true;
    }
  },
  beforeMount () {
    DeleteConfirm.EventBus.$on('getResultsAsync', (icon, title, message, resolve, reject) => {
      this.getResultsAsync(icon, title, message, resolve, reject);
    });
  }
};
</script>

<style scoped></style>
